import React from "react";
import { FormEventHandler, useRef } from "react";
import { randomString } from "../../lib/utils";

export default function Input({
  onInput,
  placeholder,
  useRainbowBorder,
  isDarkMode,
  isEmail,
  value
}: {
  onInput: (input: string) => void;
  placeholder: string;
  useRainbowBorder: boolean,
  isDarkMode: boolean,
  isEmail: boolean,
  value: string
}) {  
  const id = useRef(
    randomString()
  );
    
  return (
    <input
      value={value}
      id={id.current}
      type={isEmail ? "email" : "text"}
      style={isEmail ? {textTransform: "lowercase"} : {}}
      onChange={(e) => {
        onInput(e.target.value)
      }}
      placeholder={placeholder}
      className={"truncate w-full h-10 figma-shadow rounded-full resize-none min-w-0 transition-all duration-200 font-medium align-middle px-4 text-base md:text-lg"
        + (useRainbowBorder ? " nectar-gradient-border" : " border-[#1F1125] border-2")
        + (isDarkMode ? " text-black bg-[#F2F2F2]" : " text-white")
      }
    />
  )
}
