import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom"
import { ReactNode, MouseEventHandler, useState } from "react";
import { ANSWER_QUESTION, CURRENT_USER, SEE_DEMOGRAPHICS, SEE_EMAIL_MODAL, SET_DEMOGRAPHICS, SKIP_DEMOGRAPHICS, SUBSCRIBE } from "../../api/api";
import QuestionHeader from "../../components/Question/QuestionHeader";
import HamburgerMenu from "../../components/Global/HamburgerMenu"
import Nav from "../../components/Global/Nav"
import { Question } from "../../generated/graphql";
import { ClipLoader } from "react-spinners"
import { QuestionResponseSelector, MobileQuestionResponseSelector } from "../../components/Question/QuestionResponseSelector";
import TutorialButton from "../../components/Global/Tutorial/TutorialButton";
import TutorialModal from "../../components/Global/Tutorial/TutorialModal";
import classNames from "classnames";
import { Oval } from "react-loader-spinner";
import AssessmentEmailCapture from "../../components/Global/AssessmentEmailCapture";
import ReactGA from 'react-ga4';
import DemographicsForm, { DemographicsFormData } from "../../components/DemographicsForm";

ReactGA.initialize("G-0LZ3Y64TPB");

export default function AssessmentView() {
  const navigate = useNavigate()

  ReactGA.send({ hitType: "pageview", page: (window.location.pathname + window.location.search) });

  // State

  const [demographicsForm, setDemographicsForm] = useState<DemographicsFormData>({
    age: undefined,
    sexualOrientation: undefined,
    genderIdentity: undefined,
    relationshipStatus: undefined
  })
  const [hasCompletedDemographics, setHasCompletedDemographics] = useState(false)
  const [isShowingModal, setIsShowingModal] = useState(false)
  const [isShowingEmailCapture, setIsShowingEmailCapture] = useState(false)
  const [questionIdx, setQuestionIdx] = useState(0)
  const [isShowingMenu, setIsShowingMenu] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [inDelay, setInDelay] = useState(false)
  const [hasSetInitialIdx, setHasSetInitialIdx] = useState(false)
  const [shouldAutoAdvance, setShouldAutoAdvance] = useState(false)
  const [isShowingDemographics, setIsShowingDemographics] = useState(false)

  // API

  const [setDemographics, setDemographicsRes] = useMutation(SET_DEMOGRAPHICS);
  const [skipDemographics, skipDemographicsRes] = useMutation(SKIP_DEMOGRAPHICS);
  const [seeDemographics, seeDemographicsRes] = useMutation(SEE_DEMOGRAPHICS);
  const currentUserRes = useQuery(CURRENT_USER, { fetchPolicy: 'network-only' })
  const [answerQuestion, answerQuestionRes] = useMutation(ANSWER_QUESTION)
  const currentUser = answerQuestionRes.data?.currentUser?.user ?? currentUserRes.data?.currentUser?.user
  const [seeEmailModal, seeEmailModalRes] = useMutation(SEE_EMAIL_MODAL)
  const [subscribe, subscribeRes] = useMutation(SUBSCRIBE)
  const isAssessmentComplete = answerQuestionRes.data?.currentUser?.user?.assessmentResponse?.isComplete || currentUser?.assessmentResponse?.isComplete

  // API <-> State interaction

  const assignedEmailLoc = currentUser?.assignedEmailLoc ?? 'no-loc'
  const isSubscribed = currentUser?.subscribedAt ?? false
  const orderedQuestions: any[] | null = currentUserRes.data?.currentUser?.user?.assessmentResponse?.assessment?.orderedQuestions ?? []
  const unanswered = orderedQuestions.filter(x => x.response == null)
  if ((currentUser?.assignedDemographicLoc != 1 || currentUser?.skippedDemographics || currentUser?.demographics) && isAssessmentComplete) {
    navigate('/results')
  }

  const token = currentUserRes.data?.currentUser?.user?.token
  if (token) {
    localStorage.setItem('token', token)
  }

  if (
    currentUser &&
    !currentUser.skippedDemographics &&
    !currentUser.demographics
    && !isShowingDemographics
    && !hasCompletedDemographics
  ) {
    if (questionIdx == 0 && currentUser.assignedDemographicLoc == 0) {
      setIsShowingDemographics(true)
      ReactGA.event({
        category: 'Demographics Form - Shown, Assessment Start',
        action: 'Demographics Form - Shown, Assessment Start',
      })
    } else if (isAssessmentComplete && currentUser.assignedDemographicLoc == 1) {
      setIsShowingDemographics(true)
      ReactGA.event({
        category: 'Demographics Form - Shown, Assessment End',
        action: 'Demographics Form - Shown, Assessment End',
      })
      if (isShowingDemographics) {
        seeDemographics()
      }
    }
  }
  if (isShowingDemographics && hasCompletedDemographics) {
    setIsShowingDemographics(false)
    if (currentUser?.assignedDemographicLoc == 1) {
      navigate('/results')
    }
  }

  // Question 10 feature flagged email capture
  if (
    currentUser &&
    !currentUser.sawEmailModalAt &&
    questionIdx == 9 &&
    !isSubscribed &&
    (assignedEmailLoc == 'loc-1' || assignedEmailLoc == 'loc-4') &&
    !isShowingEmailCapture
  ) {
    setIsShowingEmailCapture(true)
    seeEmailModal({
      refetchQueries: [
        { query: CURRENT_USER }
      ]
    })
  }


  // Question 20 feature flagged email capture
  if (
    currentUser &&
    !currentUser.sawEmailModalAt &&
    questionIdx == 19 &&
    !isSubscribed &&
    assignedEmailLoc == 'loc-3' &&
    !isShowingEmailCapture
  ) {
    setIsShowingEmailCapture(true)
    seeEmailModal({
      refetchQueries: [
        { query: CURRENT_USER }
      ]
    })
  }

  if (!hasSetInitialIdx && currentUserRes.data != null) {
    let newIdx = 0
    if (unanswered.length > 0) {
      newIdx = unanswered[0]?.index ?? 0
    } else if (orderedQuestions.length > 0) {
      newIdx = orderedQuestions[orderedQuestions.length - 1]?.index
    }
    setQuestionIdx(newIdx)
    setSelectedOption(orderedQuestions[newIdx]?.response?.response)
    setHasSetInitialIdx(true)

    ReactGA.event({
      category: 'Question',
      action: 'On Question',
      label: String(newIdx)
    })
  } else if (!inDelay && shouldAutoAdvance && !answerQuestionRes.loading && currentUserRes.data != null && answerQuestionRes.called) {
    const newIdx = questionIdx + 1
    setQuestionIdx(newIdx)
    setSelectedOption(orderedQuestions[newIdx]?.response?.response)
    setShouldAutoAdvance(false)

    ReactGA.event({
      category: 'Question',
      action: 'On Question',
      label: String(newIdx)
    })
  }

  const currentQuestion = orderedQuestions.length > questionIdx ? orderedQuestions[questionIdx] : null

  // Render

  return (
    <div className="bg-[#F2F2F2]">
      <div className="z-20 fixed bg-[#F2F2F2]">
        <Nav inAssessment={true} isHamburgerOpen={isShowingMenu} onHamburgerClick={() => {
          setIsShowingMenu(!isShowingMenu)
        }} onNectarClick={() => {
          navigate('/')
        }} />
      </div>

      {isShowingDemographics &&
        <div className="mt-[70px] h-[calc(100vh_-_70px)] overflow-y-scroll min-h-[-webkit-fill-available] w-full pt-[60px] sm:pb-[24px] flex flex-col items-center gap-[48px] md:gap-[64px]">
          <div className="sm:flex-none flex flex-col w-full">
            <div className="flex flex-col justify-between items-center w-full h-full px-[12px] xssm:px-[18px] ssm:px-[24px] sm:px-[90px]">
              <QuestionHeader progress={questionIdx / orderedQuestions.length} showBack={false} onBack={() => {
              }} orderedQuestions={orderedQuestions} />
            </div>
          </div>
          <div className="w-full flex items-center justify-center">
            <DemographicsForm isLoading={false} form={demographicsForm} setForm={setDemographicsForm} onSubmit={() => {
              setDemographics({
                variables: {
                  input: {
                    ageRange: demographicsForm.age,
                    sexualOrientation: demographicsForm.sexualOrientation,
                    genderIdentity: demographicsForm.genderIdentity,
                    relationshipStatus: demographicsForm.relationshipStatus
                  }
                }
              })
              setHasCompletedDemographics(true)
            }} onSkip={() => {
              skipDemographics()
              setHasCompletedDemographics(true)
            }} />
          </div>
          <div className="h-[100px] md:h-0" />
        </div>
      }

      {!isShowingDemographics && isShowingEmailCapture &&
        <AssessmentEmailCapture loc={"Question " + (questionIdx + 1)} copy={"learn more about how you love"} wall={assignedEmailLoc != 'loc-1'} blur={assignedEmailLoc != 'loc-1'} onClick={() => {
          setIsShowingEmailCapture(false)
        }} onSubscribe={(email) => {
          setIsShowingEmailCapture(false)
          subscribe({
            variables: {
              email: email,
              fromHome: false
            }, refetchQueries: [{ query: CURRENT_USER }]
          })
          ReactGA.event({
            category: 'Email Subscription',
            action: 'Email Subscription',
            label: email
          })
        }} setIsShowing={setIsShowingEmailCapture} />
      }

      {!isShowingDemographics && isShowingModal &&
        <TutorialModal onClose={() => {
          setIsShowingModal(false)
        }} />
      }

      {isShowingMenu && <HamburgerMenu inAssessment={true} />}

      {!isShowingDemographics && !isShowingMenu && !currentQuestion &&
        <div className="h-[100vh] w-full flex flex-col justify-center items-center">
          <ClipLoader speedMultiplier={0.75} />
        </div>
      }

      {!isShowingDemographics && !isShowingMenu && currentQuestion &&
        <div className="mt-[70px] h-[calc(100vh_-_70px)] overflow-y-scroll min-h-[-webkit-fill-available] w-full pt-[60px] sm:pb-[24px] flex flex-row justify-center">
          <div className="sm:flex-none flex flex-col w-full">
            <div className="flex flex-col justify-between items-center w-full h-full px-[12px] xssm:px-[18px] ssm:px-[24px] sm:px-[90px]">
              <QuestionHeader progress={questionIdx / orderedQuestions.length} showBack={questionIdx > 0} onBack={() => {
                const newIdx = questionIdx - 1
                if (newIdx < 0) return
                setQuestionIdx(newIdx)
                setSelectedOption(orderedQuestions[newIdx]?.response?.response)

                ReactGA.event({
                  category: 'Question',
                  action: 'On Question',
                  label: String(newIdx)
                })
              }} orderedQuestions={orderedQuestions} />
              <div className="flex flex-col justify-center items-center -mt-[25px] max-w-[800px] min-h-[150px]">
                <div className="font-bold text-[22px] ssm:text-[25px] sm:text-[28px] md:text-[34px] text-[#1F1125] leading-[128%] text-center">
                  {currentQuestion.title}<span className={currentQuestion.index == 0 ? "text-center text-[11px] ssm:text-[14px] sm:text-[17px] md:text-[23px] inline" : "hidden"}><br />(all questions are applicable regardless of relationship status)</span>
                </div>
              </div>
              <QuestionResponseSelector canInteract={!inDelay && !answerQuestionRes.loading} selectedOption={selectedOption} onSelect={option => {
                setSelectedOption(option)
                setInDelay(true)
                ReactGA.event({
                  category: 'Question',
                  action: 'Answered Question',
                  label: String(currentQuestion.index + 1)
                })
                answerQuestion({
                  variables: {
                    questionId: currentQuestion.id,
                    response: option
                  },
                  refetchQueries: [
                    { query: CURRENT_USER }
                  ]
                })
                setTimeout(function () {
                  setShouldAutoAdvance(true)
                  setInDelay(false)
                }, 750)
              }} />
              <MobileQuestionResponseSelector canInteract={!inDelay && !answerQuestionRes.loading} selectedOption={selectedOption} onSelect={option => {
                setSelectedOption(option)
              }} />
              <TutorialButton onClick={() => {
                setIsShowingModal(true)
              }} />
            </div>
            <div className="block sm:hidden w-full flex-col pt-[11px] mb-[72px]">
              <div className="h-[2px] bg-[#1F1125] w-full"></div>
              <div className="px-[24px] py-[16px]">
                <button disabled={selectedOption == null} className={classNames("rounded-[30px] w-full h-[36px] bg-[#1F1125] text-[#F2F2F2]", {
                  "opacity-50": selectedOption == null,
                })} onClick={() => {
                  if (selectedOption && !answerQuestionRes.loading) {
                    ReactGA.event({
                      category: 'Question',
                      action: 'Answered Question',
                      label: String(currentQuestion.index + 1)
                    })
                    answerQuestion({
                      variables: {
                        questionId: currentQuestion.id,
                        response: selectedOption
                      },
                      refetchQueries: [
                        { query: CURRENT_USER }
                      ]
                    })
                    setShouldAutoAdvance(true)
                    setInDelay(false)
                  }
                }}>
                  {!answerQuestionRes.loading && <span>Next</span>}
                  {answerQuestionRes.loading && <div className="flex flex-col justify-center items-center">
                    <ClipLoader color="white" size={"20px"} speedMultiplier={0.75} />
                  </div>}
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
