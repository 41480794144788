import React, { ReactNode, useState } from "react"
import { loveprintNumberBucketDescription, loveprintNumberBucketTitle } from "../../../api/dataSource"
import LoveprintSectionMap from "./LoveprintSectionMap"

export default function LoveprintSection({
  result,
  index,
  isLoveprintNumber,
  header,
  body,
  mapIcon,
  mapTitle,
  mapCutScore,
  mapLabelLeft,
  mapLabelRight,
  mapCopyLeft,
  mapCopyRight,
  mapSubtotal
}: {
  result: string,
  index: number,
  isLoveprintNumber?: boolean,
  header: string,
  body: string,
  mapIcon: ReactNode,
  mapTitle: string,
  mapCutScore?: number,
  mapLabelLeft?: string,
  mapLabelRight?: string,
  mapCopyLeft?: string,
  mapCopyRight?: string,
  mapSubtotal?: number
}) {
  return (
    <div className="w-full flex flex-col gap-[13px] items-start">
      <span className="text-[#F23900] font-bold text-[30px]"><span className="italic text-[33px] text-[#1F1125]" style={{fontFamily: "Garamond Premier Pro"}}>print {index}</span> {header}</span>
      <span className="text-[#1F1125] text-[20px] leading-[140%] ">{body}</span>
      <LoveprintSectionMap result={isLoveprintNumber ? '' : result.charAt(index - 1)} isLoveprintNumber={isLoveprintNumber} mapIcon={mapIcon} mapTitle={mapTitle} mapCutScore={mapCutScore} mapLabelLeft={mapLabelLeft} mapLabelRight={mapLabelRight} mapCopyLeft={mapCopyLeft} mapCopyRight={mapCopyRight} mapSubtotal={mapSubtotal} />
    </div>
  )
}