import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { ClipLoader } from "react-spinners";
import { CURRENT_USER, SET_DEMOGRAPHICS, SKIP_DEMOGRAPHICS } from "../api/api";
import DemographicsForm, { DemographicsFormData } from "../components/DemographicsForm";
import Nav from "../components/Global/Nav"
import ReactGA from 'react-ga4';

ReactGA.initialize("G-0LZ3Y64TPB");

export default function DemographicsFormView() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const klaviyoId = searchParams.get('Email')

  ReactGA.send({ hitType: "pageview", page: (window.location.pathname + window.location.search) });

  ReactGA.event({
    category: 'Demographics Form - Shown, Dedicated Page',
    action: 'Demographics Form - Shown, Dedicated Page',
  })

  const { data, error, loading, called } = useQuery(CURRENT_USER, {
    fetchPolicy: 'network-only',
    variables: (klaviyoId?.length ?? 0) > 0 ? {
      input: {
        klaviyoId: klaviyoId
      }
    } : undefined
  });

  const token = data?.currentUser?.user?.token
  if (token) {
    localStorage.setItem('token', token)
  }

  const currentUser = data?.currentUser.user
  if (currentUser?.demographics) {
    navigate('/loveprint')
  }

  const [demographicsForm, setDemographicsForm] = useState<DemographicsFormData>({
    age: undefined,
    sexualOrientation: undefined,
    genderIdentity: undefined,
    relationshipStatus: undefined
  })
  const [setDemographics, setDemographicsRes] = useMutation(SET_DEMOGRAPHICS);
  const [skipDemographics, skipDemographicsRes] = useMutation(SKIP_DEMOGRAPHICS);
  const [isShowingMenu, setIsShowingMenu] = useState(false)

  // Render

  return (
    <div className="bg-[#F2F2F2]">
      <div className="z-20 fixed bg-[#F2F2F2]">
        <Nav inAssessment={true} isHamburgerOpen={isShowingMenu} onHamburgerClick={() => {
          setIsShowingMenu(!isShowingMenu)
        }} onNectarClick={() => {
          navigate('/')
        }} />
      </div>

      {!isShowingMenu && loading &&
        <div className="h-[100vh] w-full flex flex-col justify-center items-center">
          <ClipLoader speedMultiplier={0.75} />
        </div>
      }

      {!isShowingMenu && !loading &&
        <div className="mt-[70px] h-[calc(100vh_-_70px)] overflow-y-scroll min-h-[-webkit-fill-available] w-full pt-[12px] flex flex-col items-start md:items-center gap-[48px] md:gap-[64px]">
          <div className="sm:flex-none flex flex-col w-full">
            <div className="flex flex-col justify-between items-start md:items-center w-full h-full px-[12px] xssm:px-[18px] ssm:px-[24px] sm:px-[90px]">
            </div>
          </div>
          <div className="w-full flex items-start justify-items-stretch md:items-center md:justify-center">
            <DemographicsForm isLoading={setDemographicsRes.loading} form={demographicsForm} setForm={setDemographicsForm} onSubmit={() => {
              setDemographics({
                variables: {
                  input: {
                    ageRange: demographicsForm.age,
                    sexualOrientation: demographicsForm.sexualOrientation,
                    genderIdentity: demographicsForm.genderIdentity,
                    relationshipStatus: demographicsForm.relationshipStatus
                  }
                },
                refetchQueries: [
                  { query: CURRENT_USER }
                ]
              }).then(_ => {
                navigate('/loveprint')
              })
            }} onSkip={() => {
              skipDemographics().then(_ => {
                navigate('/loveprint')
              })
            }} />
          </div>
          <div className="h-[100px] md:h-0" />
        </div>
      }
    </div>
  )
}