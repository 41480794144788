import React from 'react'
import className from "classnames"
import { primaryBgColor, primaryColor, secondaryBgColor } from '../../api/dataSource'

export default function ResultMapSection({
  result,
  imgUrl,
  title,
  leftText,
  rightText,
  isLeftBold,
  progress,
  quantitative
}: {
  result: string,
  imgUrl: string,
  title: string,
  leftText?: string,
  rightText?: string,
  isLeftBold?: boolean,
  progress?: number,
  quantitative?: number
}) {
  return (
    <div className='flex flex-col gap-[10px]'>
      <div className='flex flex-row gap-[8px]'>
        <img src={imgUrl} height="24px" className='h-[24px] cover' />
        <span className='font-bold text-[20px] text-[#1F1125]'>{title.toUpperCase()}</span>
      </div>
      { quantitative == null &&
        <div className={`w-full h-[20px] rounded-[20px] ` + secondaryBgColor(result)}>
          <div className={`z-[10] w-[6px] h-full ${primaryBgColor(result)}`} style={{marginLeft: (progress * 100) + "%"}} />
        </div>
      }
      { quantitative == null &&
        <div className='flex flex-row justify-between items-end'>
          <span className={className(`text-[20px] ${primaryColor(result)}`, {
            'font-bold opacity-1': isLeftBold,
            'font-normal text-[#756A78]': !isLeftBold
          })}>{leftText}</span>
          <span className={className(`text-[20px] ${primaryColor(result)}`, {
            'font-bold opacity-1': !isLeftBold,
            'font-normal text-[#756A78]': isLeftBold
          })}>{rightText}</span>
        </div>
      }
      { quantitative != null &&
        <div className='flex flex-row items-center justify-between'>
          {
            Array.from(Array(10), (_, idx) => {
              if (idx == quantitative - 1) {
                return <div className={`w-[32px] xssm:w-[36px] sm:w-[42px] aspect-square rounded-full ${primaryBgColor(result)} flex flex-col justify-center items-center`}>
                  <span className='text-[20px] text-[#1F1125] font-normal'>{quantitative}</span>
                </div>
              } else {
                return <div className={`w-[18px] xssm:w-[22px] sm:w-[28px] aspect-square rounded-full ${secondaryBgColor(result)}`} />
              }
            })
          }
        </div>
      }
    </div>
  )
}