import React, { useState } from "react";
import Button from "./Button";
import Input from "./Input";
import { MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom";

export default function Footer({
  hasSubscribed,
  onSubscribeClick
}: {
  hasSubscribed: Boolean,
  onSubscribeClick: (email: string) => void
}) {
  const [email, setEmail] = useState("")

  return (
    <div className="z-[20] relative">
      <div className="z-[20] w-full relative bg-[#1F1125] pt-[42px] pb-[42px] pl-5 pr-5 md:pt-[80px] md:pb-[80px] lg:pl-[90px] lg:pr-[60px]">
        <div className="z-[20] flex flex-col md:flex md:flex-row md:justify-around">
          <div className="z-[20] flex flex-col md:grid md:grid-cols-9 md:grid-rows-2 md:flex-none">
            <div className="z-[20] flex flex-col gap-5 md:gap-3 md:col-span-6 md:row-span-2">
              <span className="z-[20] font-black text-[#F2F2F2] text-xl hidden md:block">{ hasSubscribed ? "thank you for subscribing!" : "hey! let’s be internet friends." }</span>
              { !hasSubscribed &&
                <span className="z-[20] font-black text-[#F2F2F2] text-xl md:hidden">hey! let’s be<br />internet friends.</span>
              }
              { hasSubscribed &&
                <span className="z-[20] font-black text-[#F2F2F2] text-xl md:hidden">thank you for subscribing!</span>
              }
              { !hasSubscribed &&
                <Input value={email} isEmail={true} onInput={(e) => { setEmail(e) }} placeholder="enter your email" useRainbowBorder={true} isDarkMode={false} />
              }
            </div>
            <div className="z-[20] flex flex-col md:flex-col-reverse md:col-span-3 md:row-span-2 md:pl-3">
              { !hasSubscribed &&
                <div className="pt-4 md:pt-0"></div>
              }
              { !hasSubscribed &&
                <Button onClick={() => { onSubscribeClick(email); setEmail("") }} title="subscribe" isDarkMode={false} />
              }
            </div>
          </div>
          <div className="z-[20] flex flex-col gap-2 pt-9 md:pt-0">
            <span className="text-white text-sm z-[20] ">hit me up</span>
            <div className="z-[20] flex gap-3 md:gap-2">
              <div className="z-[20] cursor-pointer" onClick={() => { window.open("https://lovecommanectar.co/3f3OlAk") }}>
                <svg width="34" height="30" viewBox="0 0 45.33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32.4453 7.27049C32.0745 5.88475 30.9822 4.79338 29.5952 4.42301C27.0812 3.75 17.0004 3.75 17.0004 3.75C17.0004 3.75 6.91961 3.75 4.40556 4.42301C3.01858 4.79344 1.92621 5.88475 1.55546 7.27049C0.881836 9.78223 0.881836 15.0227 0.881836 15.0227C0.881836 15.0227 0.881836 20.2632 1.55546 22.775C1.92621 24.1607 3.01858 25.2066 4.40556 25.577C6.91961 26.25 17.0004 26.25 17.0004 26.25C17.0004 26.25 27.0811 26.25 29.5952 25.577C30.9822 25.2066 32.0745 24.1607 32.4453 22.775C33.1189 20.2632 33.1189 15.0227 33.1189 15.0227C33.1189 15.0227 33.1189 9.78223 32.4453 7.27049V7.27049ZM13.7034 19.7807V10.2647L22.1289 15.0229L13.7034 19.7807V19.7807Z" fill="#F2F2F2"/>
                </svg>
              </div>
              <div className="z-[20] cursor-pointer" onClick={() => { window.open("https://lovecommanectar.co/3dlWCiV") }}>
                <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_297_137)">
                    <path d="M15.0068 7.44168C10.7479 7.44168 7.31264 10.877 7.31264 15.1359C7.31264 19.3948 10.7479 22.8301 15.0068 22.8301C19.2658 22.8301 22.701 19.3948 22.701 15.1359C22.701 10.877 19.2658 7.44168 15.0068 7.44168ZM15.0068 20.1381C12.2546 20.1381 10.0046 17.8948 10.0046 15.1359C10.0046 12.377 12.2479 10.1336 15.0068 10.1336C17.7658 10.1336 20.0091 12.377 20.0091 15.1359C20.0091 17.8948 17.7591 20.1381 15.0068 20.1381V20.1381ZM24.8104 7.12695C24.8104 8.12472 24.0068 8.9216 23.0158 8.9216C22.018 8.9216 21.2211 8.11802 21.2211 7.12695C21.2211 6.13588 22.0247 5.33231 23.0158 5.33231C24.0068 5.33231 24.8104 6.13588 24.8104 7.12695ZM29.9064 8.94838C29.7926 6.54436 29.2434 4.4149 27.4823 2.66044C25.7278 0.905971 23.5984 0.356864 21.1943 0.236328C18.7167 0.0957031 11.2903 0.0957031 8.81264 0.236328C6.41532 0.350167 4.28585 0.899274 2.52469 2.65374C0.763532 4.4082 0.221122 6.53767 0.100586 8.94168C-0.0400391 11.4194 -0.0400391 18.8457 0.100586 21.3234C0.214425 23.7274 0.763532 25.8569 2.52469 27.6113C4.28585 29.3658 6.40862 29.9149 8.81264 30.0354C11.2903 30.1761 18.7167 30.1761 21.1943 30.0354C23.5984 29.9216 25.7278 29.3725 27.4823 27.6113C29.2367 25.8569 29.7859 23.7274 29.9064 21.3234C30.047 18.8457 30.047 11.4261 29.9064 8.94838V8.94838ZM26.7055 23.9819C26.1832 25.2944 25.172 26.3055 23.8528 26.8345C21.8774 27.618 17.1899 27.4372 15.0068 27.4372C12.8238 27.4372 8.1296 27.6113 6.16085 26.8345C4.84835 26.3122 3.83719 25.3011 3.30818 23.9819C2.52469 22.0064 2.7055 17.3189 2.7055 15.1359C2.7055 12.9528 2.53139 8.25865 3.30818 6.2899C3.8305 4.9774 4.84166 3.96624 6.16085 3.43722C8.1363 2.65374 12.8238 2.83454 15.0068 2.83454C17.1899 2.83454 21.8841 2.66043 23.8528 3.43722C25.1653 3.95954 26.1765 4.9707 26.7055 6.2899C27.489 8.26535 27.3082 12.9528 27.3082 15.1359C27.3082 17.3189 27.489 22.0131 26.7055 23.9819Z" fill="#F2F2F2"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_297_137">
                    <rect width="30" height="30" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="z-[20] cursor-pointer" onClick={() => { window.open("https://lovecommanectar.co/3UmFZUM") }}>
                <svg width="27" height="30" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_297_139)">
                    <path d="M26.2502 12.2994C23.6706 12.3056 21.1543 11.5011 19.0566 9.99961V20.4715C19.0559 22.411 18.4631 24.3041 17.3575 25.8976C16.2518 27.491 14.686 28.709 12.8695 29.3886C11.0529 30.0682 9.07221 30.177 7.19216 29.7005C5.3121 29.2239 3.62233 28.1848 2.3488 26.722C1.07526 25.2592 0.278658 23.4425 0.0655111 21.5147C-0.147636 19.5869 0.23283 17.6401 1.15603 15.9344C2.07924 14.2287 3.50118 12.8455 5.23172 11.9698C6.96226 11.094 8.91891 10.7675 10.84 11.0338V16.3008C9.96093 16.0243 9.01692 16.0326 8.14282 16.3246C7.26872 16.6166 6.50924 17.1773 5.97283 17.9267C5.43642 18.6761 5.15051 19.5758 5.15594 20.4973C5.16137 21.4189 5.45786 22.3152 6.00306 23.0582C6.54826 23.8012 7.31429 24.3529 8.19177 24.6346C9.06925 24.9163 10.0133 24.9135 10.8891 24.6266C11.7649 24.3398 12.5276 23.7835 13.0684 23.0373C13.6092 22.2911 13.9004 21.3931 13.9004 20.4715V0H19.0566C19.0531 0.435434 19.0895 0.870274 19.1656 1.29902V1.29902C19.3448 2.25614 19.7174 3.16665 20.2605 3.97484C20.8036 4.78304 21.5059 5.47195 22.3244 5.99941C23.4889 6.76939 24.8542 7.17979 26.2502 7.17949V12.2994Z" fill="#F2F2F2"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_297_139">
                      <rect width="26.25" height="30" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="z-[20] cursor-pointer" onClick={() => { window.open("https://lovecommanectar.co/3SdYaKq") }}>
                <svg width="31" height="30" viewBox="0 0 41.33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_297_141)">
                    <path d="M27.1662 8.88969C27.1853 9.15617 27.1853 9.42271 27.1853 9.6892C27.1853 17.8173 20.9988 27.1828 9.69162 27.1828C6.20811 27.1828 2.97209 26.1739 0.25 24.4227C0.744941 24.4798 1.22078 24.4988 1.73477 24.4988C4.60908 24.4988 7.25506 23.528 9.36801 21.872C6.66496 21.8148 4.39973 20.0445 3.61926 17.608C4 17.665 4.38068 17.7031 4.78047 17.7031C5.33248 17.7031 5.88455 17.627 6.39848 17.4938C3.58123 16.9227 1.46822 14.4481 1.46822 11.4595V11.3834C2.28672 11.8402 3.23857 12.1258 4.24738 12.1638C2.59129 11.0597 1.50631 9.17521 1.50631 7.04322C1.50631 5.90111 1.81082 4.85416 2.34385 3.94045C5.37051 7.67141 9.92002 10.1079 15.0215 10.3745C14.9263 9.9176 14.8692 9.44176 14.8692 8.96586C14.8692 5.5775 17.6103 2.81738 21.0177 2.81738C22.788 2.81738 24.3869 3.55977 25.5101 4.759C26.8996 4.49252 28.2321 3.97854 29.4123 3.27424C28.9554 4.70193 27.9846 5.90117 26.7093 6.66254C27.9466 6.52936 29.1459 6.18664 30.2499 5.7108C29.4125 6.92902 28.3655 8.014 27.1662 8.88969V8.88969Z" fill="#F2F2F2"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_297_141">
                    <rect width="30" height="30" fill="white" transform="translate(0.25)"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div className="z-[20] flex flex-col pt-6 md:pt-0 gap-3 md:gap-2 pb-3">
            <span className="text-white text-sm cursor-pointer z-[20]" onClick={() => {
              window.open('/privacy', 'blank')
            }}>privacy policy</span>
            <span className="text-white text-sm cursor-pointer z-[20]" onClick={() => {
              window.open('/terms', 'blank')
            }}>terms of service</span>
          </div>
        </div>
      </div>
    </div>
  );
}
