import BackButton from "./../../assets/images/back-button.svg"
import Logo from "./../../assets/images/quiz-header-logo.png"
import React from 'react'
import QuestionProgressBar from "./QuestionProgressBar"
import { Question, Subscale } from "../../generated/graphql"

export default function QuestionHeader({
  orderedQuestions,
  onBack,
  showBack,
  progress
}: {
  orderedQuestions: Question[],
  onBack: (() => void),
  showBack: boolean,
  progress: number
}) {
  return (
    <div className="flex flex-col gap-[23px] w-full">
      <div className="flex flex-row justify-between items-end">
        <div onClick={onBack} className={showBack ? "cursor-pointer opacity-100": "opacity-0"}>
          <img className="inline cursor-pointer" src={BackButton} />
        </div>
        <img src={Logo} width={"186.5px"} height={"30px"} />
        <span />
      </div>
      <QuestionProgressBar progress={progress} />
    </div>
  )
}