import React, { useEffect, useRef } from 'react'
import CloseButton from "../../../assets/images/CloseButton.svg"
import Logo from "./../../../assets/images/quiz-header-logo.png"

export default function TutorialModal({
  onClose
}: {
  onClose: (() => void)
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className="min-h-[100vh] w-full fixed inset-0 z-[70] overflow-y-auto bg-black/50">
      <div className="px-[22.5px] sm:px-[30px] md:px-0 flex min-h-full justify-center text-center items-center p-0">
        <div className="rounded-[16px] max-w-[730px] bg-[#F2F2F2]" ref={ref}>
          <div className='py-[22.5px] flex flex-row justify-between items-center px-[22.5px] sm:px-[30px]'>
            <span />
            <span className='text-[18px] sm:text-[24px] font-black text-[#1F1125] mt-[5px] ml-[10px]'>what is this?</span>
            <img src={CloseButton} onClick={onClose} className="inline cursor-pointer" />
          </div>
          <div className="px-[37.5px] py-[35px] sm:px-[50px] sm:py-[40px] flex flex-col items-center gap-[24px] border-t-[2.5px] border-t-[#F23900]">
            <img src={Logo} width={"280px"} className="pb-[10px]" />
            <p className='text-[15px] sm:text-[20px] leading-[140%] text-[#1F1125] text-center font-normal'>your loveprint is an approximation of where you are now and your approach to forming relationships. this assessment helps you get an idea of how you love, and what a relationship could look like for you.</p>
            <p className='text-[15px] sm:text-[20px] leading-[140%] text-[#1F1125] text-center font-normal'><span className='font-bold'>directions</span>: please rate the extent to which you agree with the following statements. please use yourself or relationships <span className='underline'>in general</span> as a reference when responding to these statements.</p>
            <span className='font-black text-[#1F1125] text-[27px] sm:text-[36px]'>love, <span className='text-[#F23900]'>nectar</span></span>
          </div>
        </div>
      </div>
    </div>
  )
}