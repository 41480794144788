import React from 'react'
import { image, mapLeftBold, mapLeftText, mapRightText, primaryColor } from '../../api/dataSource'
import HeaderImage from "../../assets/images/loveprint-map-header.svg"
import ResultMapSection from './ResultMapSection'
import { ResultQualitative } from './ResultsDetail'

export default function ResultMap({
  result,
  resultQuantitative,
  progress1,
  progress2,
  progress3,
  progress4
}: {
  result: string,
  resultQuantitative: number,
  progress1: number,
  progress2: number,
  progress3: number,
  progress4: number,
}) {
  return (
    <div className="z-[10] rounded-[16px] border-[#1F1125] border-[2.5px] flex-grow w-full 2xxmd:min-w-[500px] 2xxmd:max-w-[780px]">
      <div className="pt-[18px] pb-[11px] border-b-[2.5px] border-b-[#1F1125]">
        <div className='hidden sm:flex flex-row items-center pl-[24px] gap-[12px]'>
          <img src={image(result)} width="36" height="36" className="-mt-[2px]" />
          <span className={`${primaryColor(result)} font-black text-[38px]`}>{result.toUpperCase()}</span>
          <span className='text-[#1F1125] font-bold text-[36px]'><span style={{fontFamily: "Garamond Premier Pro", fontSize: "43px"}} className="italic">loveprint</span> detail</span>
        </div>
        <div className='flex sm:hidden flex-col pl-[24px] gap-[6px] pb-[6px]'>
          <div className='flex flex-row gap-[12px]'>
            <img src={image(result)} width="36" height="36" className="-mt-[2px]" />
            <span className={`${primaryColor(result)} font-black text-[38px]`}>{result.toUpperCase()}</span>
          </div>
          <span className='text-[#1F1125] font-bold text-[36px]'>your nectar <span style={{fontFamily: "Garamond Premier Pro",  fontSize: "43px"}} className="italic">loveprint</span> is</span>
        </div>
      </div>
      <div className='px-[32px] sm:px-[40px] md:px-[48px] py-[48px] flex flex-col gap-[34px] items-stretch'>
        <ResultMapSection title={"Communication"} result={result} imgUrl='/results/communication-header-title.png' leftText={mapLeftText(0)} rightText={mapRightText(0)} isLeftBold={mapLeftBold(0, result)} progress={progress1} />
        <ResultMapSection title={"Partnership"} result={result} imgUrl='/results/partnership-header-title.png' leftText={mapLeftText(1)} rightText={mapRightText(1)} isLeftBold={mapLeftBold(1, result)} progress={progress2} />
        <ResultMapSection title={"Intimacy"} result={result} imgUrl='/results/intimacy-header-title.png' leftText={mapLeftText(2)} rightText={mapRightText(2)} isLeftBold={mapLeftBold(2, result)} progress={progress3} />
        <ResultMapSection title={"Vulnerability"} result={result} imgUrl='/results/vulnerability-header-title.png' leftText={mapLeftText(3)} rightText={mapRightText(3)} isLeftBold={mapLeftBold(3, result)} progress={progress4} />
        <ResultMapSection title={"Loveprint #"} result={result} imgUrl='/results/loveprint-header-title.png' quantitative={resultQuantitative} />
      </div>
    </div>
  )
}