import React from "react"
import { useNavigate } from "react-router-dom"

export default function LoveprintsHeader() {
  const navigate = useNavigate()

  return (
    <div className="flex-col justify-center items-center bg-cover bg-center bg-no-repeat bg-[url('./assets/images/all-loveprints-header.png')] border-b-[2.5px] border-[#1F1125] py-[97px] md:py-[183px]">
      <div className="flex flex-col justify-center items-center px-[24px] md:px-0">
        <span className="text-black font-bold text-[24px] md:text-[60px]">the <span style={{fontFamily: "Garamond Premier Pro"}} className="text-[29px] md:text-[70px] italic">loveprints</span></span>
        <p className="md:text-center max-w-[761px] text-[18px] md:text-[20px] leading-[140%] text-[#1F1125] font-normal">explore all 16 loveprints. getting familiar with all the loveprints will help you understand how different types of people show up in their relationships. each of the 16 loveprints is as valid (and unique) as the last. <span className="cursor-pointer underline font-bold" onClick={() => {
          navigate('/loveprint')
        }}>take the quiz.</span></p>
      </div>
    </div>
  )
}