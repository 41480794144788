import { gql, useMutation, useQuery } from "@apollo/client";
import { USER_INFO_FRAGMENT } from "./fragments";

export const CURRENT_USER = gql`
  ${USER_INFO_FRAGMENT}
  query CurrentUser($input: CurrentUserInput) {
    currentUser(input: $input) {
      user {
        ...UserInfo
      }
    }
  }
`;

export const SUBSCRIBE = gql`
  ${USER_INFO_FRAGMENT}
  mutation Subscribe($email: String!) {
    subscribe(input: { email: $email }) {
      user {
        ...UserInfo
      }
    }
  }
`

export const ANSWER_QUESTION = gql`
  ${USER_INFO_FRAGMENT}
  mutation AnswerQuestion($response: String!, $questionId: String!) {
    answerQuestion(input: { response: $response, questionId: $questionId }) {
      user {
        ...UserInfo
      }
    }
  }
`

export const SEE_EMAIL_MODAL = gql`
  ${USER_INFO_FRAGMENT}
  mutation SeeEmailModal {
    seeEmailModal {
      user {
        ...UserInfo
      }
    }
  }
`

export const SET_DEMOGRAPHICS = gql`
  ${USER_INFO_FRAGMENT}
  mutation SetDemographics($input: SetDemographicsInput!) {
    setDemographics(input: $input) {
      user {
        ...UserInfo
      }
    }
  }
`

export const SKIP_DEMOGRAPHICS = gql`
  ${USER_INFO_FRAGMENT}
  mutation SkipDemographics {
    skipDemographics {
      user {
        ...UserInfo
      }
    }
  }
`

export const SEE_DEMOGRAPHICS = gql`
  ${USER_INFO_FRAGMENT}
  mutation SeeDemographics {
    seeDemographics {
      user {
        ...UserInfo
      }
    }
  }
`