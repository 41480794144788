import React from "react"

export default function Privacy() {
  return (
    <div className="w-full h-full">
      <h2><b>Jubilee Privacy Policy</b></h2><br/>
Updated as of Feb 26, 2024<br/><br/>

<b>Table of Contents</b><br/>
<ol>
<li><a href="#introduction">Introduction</a></li>
<li><a href="#information">What Information We Collect</a></li>
<li><a href="#why">Why We Collect Information</a></li>
<li><a href="#when">When We Disclose Information</a></li>
<li><a href="#ads">Ads and Information About You</a></li>
<li><a href="#yourchoice">Your Choices About the Information We Collect</a></li>
<li><a href="#childrenprivacy">Children’s Privacy</a></li>
<li><a href="#disclosures">Do Not Track Disclosures</a></li>
<li><a href="#visitors">Visitors to the Services Outside of the United States</a></li>
<li><a href="#updatingpersonalinformation">Updating Personal Information</a></li>
<li><a href="#links">Links</a></li>
<li><a href="#security">Security</a></li>
<li><a href="#internationaldatatransfers">International Data Transfers</a></li>
<li><a href="#generaldataprotectionregulation">Your Rights Under the General Data Protection Regulation</a></li>
<li><a href="#ukgdpr">Your Rights Under the UK GDPR</a></li>
<li><a href="#canadianresidents">Information for Canadian Residents</a></li>
<li><a href="#californiaprivacyrights">Your California Privacy Rights</a></li>
<li><a href="#nevadaprivacyrights">Your Nevada Privacy Rights</a></li>
<li><a href="#virginiaprivacyrights">Your Virginia Privacy Rights</a></li>
<li><a href="#changesinprivacypolicy">Changes in Privacy Policy</a></li>
<li><a href="#contact">Contact Us</a></li>
</ol>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>


<b><h1 id="introduction">1. Introduction</h1></b><br/><br/>
Welcome. You have arrived at a website or an application provided by Jubilee, Inc. and/or its designee(s) (“<b>Company</b>,” or “<b>we</b>,” “<b>our</b>,” or “<b>us”</b>). We respect your privacy and want to protect your personal information. 
This <b>Privacy Policy</b> governs <a href="www.jubileemedia.com"><b>jubileemedia.com</b></a>, <a href="shop.jubileemedia.com"><b>shop.jubileemedia.com</b></a>, <a href="lovecommanectar.com"><b>lovecommanectar.com</b></a>, each of their subdomains, and all other portals and sites owned and operated by Company (collectively, “<b>Site”</b>), as well as any applications, interactive features, products, goods, licenses, events, and/or other services operated by Company that post a link to this Privacy Policy. This Privacy Policy also applies to information (including the “Personal Information” defined below) that we may collect from you in person at our physical locations (“<b>Offices”</b>), via phone calls or other communications with our personnel, or in any other instance when you contact us in connection with the foregoing. We refer to all of the above as our “<b>Services</b>.”<br/><br/>
We are required by law to tell you what information we collect from you, why we collect it, how we use it, under what circumstances we may share it with third parties, how we protect that information, and how you may opt out of the sale of that information. As a preliminary matter, we handle all private information you provide us with the utmost confidentiality. Your personal information will be shared with members of our Company who need to know this information in order for us to provide Services to you. At times, we may need to disclose your personal information to third parties outside the Company who may be assisting us with providing Services to you, as set forth in this Privacy Policy. If you have questions about this Privacy Policy, contact us at  <a href="mailto:hello@jubileemedia.com"><b><u>hello@jubileemedia.com</u></b></a>.<br/><br/>
Please note that if you voluntarily provide your information in the course of interacting with our Site, visiting our Offices, or otherwise using our Services, we will take that as your agreement to our collection, use, and disclosure of your information as set forth in this Privacy Policy. This Privacy Policy is incorporated into our  <a href="https://www.lovecommanectar.com/terms"><b><u>Terms of Use</u></b></a>, which govern your use of this Site and other Services as set forth therein.<br/><br/>
By using our Site or other Services, contacting us to inquire about our Services, or visiting our Offices, you consent to our Privacy Policy and agree to our Terms of Use.<br/><br/> 
The Services may be provided through platforms or utilize features controlled by third parties, or include links to sites operated by third parties. For example, you may access our application(s) through a third party’s mobile device platform, or our application(s) may require the use of a third-party service to display advertisements. This Privacy Policy does not apply to any such third-party platform or service or any other features, platforms, products, goods, licenses, virtual items, services, websites, or content that is provided by any third parties (collectively, “<b>Third-Party Services</b>”). Third-Party Services are governed by their own respective terms of use and privacy policies. Thus, when you visit or interact with our Services and utilize any Third-Party Services, the information we collect is governed by this Privacy Policy and the information such Third-Party Services may collect is subject to the terms of use and privacy policies of the applicable third party(ies), which we encourage you to read carefully. For clarity, any privacy selections you make on Third-Party Services do not apply to our use of information that we have collected directly through the Services.<br/><br/>
<b><h1 id="information">2. What Information We Collect</h1></b><br/><br/>
<b>(a) Information You Provide to Us</b>
In connection with the Services we provide, we may ask you to provide us with certain personal information, including:<br/><br/>
• your first and last name; your contact information, such as email address, home and/or business address, telephone number, or mobile number; your username and/or password; your payment information, such as credit card or debit card number, bank account, or other financial information; your age range or birthdate; and/or your payment and/or service history;<br/><br/>
• occupation information;<br/><br/> 
• information regarding your race/ethnicity, gender, gender identity, preferred gender pronouns, sexual orientation, and/or relationship status;<br/><br/>
• information regarding your religious preference and/or political affiliation;<br/><br/>
• information regarding your preferences, a consumer profile, and/or your nectar loveprint questionnaire results;<br/><br/>
• photo(s) of you and/or responses related to casting topics;<br/><br/>
• information that you may share via a telephone call, message, free text field, chat function, or other unstructured format on the Site or other Services (please note that we do not collect Personal Information via these functions unless you provide it);<br/><br/>
• social media information, such as social media handles, content, and other data shared with us directly or through third-party features that you use on our Site or other services (such as apps, tools, payment services, widgets, and plug-ins offered by social media services like Instagram, TikTok, and X) or posted on social media pages (such as our social media page[s] or other pages accessible to us); and/or<br/><br/>
• other information that could reasonably be used to identify you personally or identify your household or device, including, but not limited to, your location.<br/><br/>
Additionally, we may obtain Personal Information from you where you expressly provide us with the information. Examples of sources from which we collect information include interviews and phone calls with you, letters, e-mails or other communications from you, information provided via web forms or inputs/uploads into our Site or other Services, information provided at events we attend or sponsor, and documents you have provided to us.<br/><br/>
These items are collectively referred to herein as “<b>Personal Information.</b>” Your decision to provide us with information is voluntary, but if you choose not to provide the requested information, you may not be able to take advantage of all of the Site’s features or our other Services.<br/><br/>
<b>(b) Information That Is Automatically Collected</b><br/><br/>
In addition to information that you choose to submit to us, we and/or our service providers may automatically collect and/or store certain information when you visit or interact with the Site or other Services (“<b>Usage Information”</b>). This Usage Information may be stored and/or accessed from your personal computer, laptop, tablet, mobile phone, or other device (each, a “Device”) whenever you visit or interact with our Site or other Services. Usage Information may include:<br/><br/>
• Your IP address, MAC address, IDFA, Android/Google Advertising ID, IMEI, or another unique identifier;<br/><br/>
• Your Device functionality (including browser, browser language, operating system, hardware, mobile network information);<br/><br/>
• Referring and exit web pages and URLs;<br/><br/>
• The areas within our Site or other Services that you visit and your activities there, including remembering you and your preferences;<br/><br/>
• Your Device’s location or other geolocation information, including the zip code, state, or country from which you accessed the Services;<br/><br/>
• Your Device’s characteristics; and<br/><br/>
• Certain other Device data, including the time of day you visit our Site or other Services.<br/><br/>
• For location information, we may use this information to provide customized Services, content, and other information that may be of interest to you.<br/><br/>
• If you no longer wish for us or our service providers to collect and use location information, you may disable the location features on your Device. Consult your Device manufacturer’s settings for instructions. Please note that if you disable such features, your ability to access certain features, Services, content, promotions, or products may be limited or disabled.<br/><br/>
<b>(c) Use of Cookies and other Tracking Technologies</b><br/><br/>
We may use various methods and technologies to store or collect Usage Information (“<b>Tracking Technologies</b>”). Tracking Technologies may set, change, alter, or modify settings or configurations on your Device. A few of the Tracking Technologies used on the Services may include, but will not be limited to, the following (as well as future-developed tracking technology or methods that are not listed here):<br/><br/>
<b> • Cookie.</b> A cookie is a file placed on a Device to uniquely identify your browser or to store information on your Device. Our Site may use HTTP cookies, HTML5 cookies, Flash cookies, and other types of cookie technology to store information on local storage.<br/><br/>
<b> • SDK, or Software Development Kit.</b> An SDK or a software development kit is a software component that can allow us or third parties to show you advertisements, collect Usage Information, and/or perform other functions.<br/><br/>
<b> • Web Beacons.</b> Certain portions of our Services and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity). A web beacon may be invisible to you.<br/><br/>
<b> • Embedded Scripts.</b> An embedded script is programming code that is designed to collect information about your interactions with the Site, such as the links you click on.<br/><br/>
<b> • ETag, or Entity Tag.</b> An ETag or entity tag is a feature of the cache in browsers. It is an opaque identifier assigned by a web server to a specific version of a resource found at a URL.<br/><br/>
<b> • Browser Fingerprinting.</b> Collection and analysis of information from your Device, such as, without limitation, your operating system, plug-ins, system fonts, and other data, for purposes of identification.<br/><br/>
<b> • Recognition Technologies.</b> Technologies, including application of statistical probability to data sets, which attempt to recognize or make assumptions about users and devices (e.g., that a user of multiple devices is the same user).<br/><br/>
We may use Tracking Technologies for a variety of purposes, including:<br/><br/>
• To allow you to use and access the Site or other Services, including for the prevention of fraudulent activity and improved security functionality;<br/><br/>
• To facilitate navigation, display data more effectively, collect statistical data, personalize your experience while using the Site or other Services, and recognize your Device to assist your use of the Site or other Services;<br/><br/>
• To assess the performance of the Site or other Services, including as part of our analytic practices or otherwise to improve the design and functionality, content, products, licenses, or services offered through the Site or other Services;<br/><br/>
• To offer you enhanced functionality when accessing the Site or other Services, including identifying you when you sign into our Site or other Services, keeping track of your specified preferences, or tracking your online activities over time and across third-party sites; and<br/><br/>
• To deliver content relevant to your interests on our Site, other Services, and third-party sites based on how you interact with our content or the Site or other Services.<br/><br/>
You can get more information about Cookies and other Tracking Technologies, and instructions on how to opt out of cookies and certain tracking technologies, in our <b><u>Cookie Policy.</u></b> We may use Google Analytics, which uses cookies and similar technologies to collect and analyze information about the use of the Services and report on activities and trends. This service may also collect information about the use of other websites, apps, and online services. You can learn about Google’s practices by going to <a href="https://policies.google.com/technologies/partner-sites"><u>https://policies.google.com/technologies/partner-sites</u></a>, and opt out of them by downloading the Google Analytics opt-out browser add-on, available at  <a href="https://tools.google.com/dlpage/gaoptout."><u>https://tools.google.com/dlpage/gaoptout.</u></a><br/><br/> 
<b>(d) Information from Third Parties</b><br/><br/>
The Site or other Services may include functionality that allows certain kinds of interactions between the Site or other Services, and your account on a third-party website or application, like Apple, Instagram, TikTok, or X. The use of this functionality may involve the third-party site or application providing information to us. For example, we may provide links on the Site or other Services to facilitate sending a communication from the Site or other Services, or we may use third parties to facilitate emails or postings to social media (like a “Share” or “Forward” button). These third parties may retain any information used or provided in any such communications or activities and these third parties’ practices are not subject to our Privacy Policy. We may not control or have access to your communications through these third parties. Further, when you use third-party sites, applications, or other services, you are using their services and not our services and they, not we, are responsible for their practices. You should read the applicable third-party privacy policies before using such third-party tools on our Site or other Services. Please also see our <a href="https://www.lovecommanectar.com/terms"><b><u>Terms of Use</u></b></a> for further information.<br/><br/>
In addition, we may collect information about you from other sources, including companies that provide services to you and enable features within our application(s) or Site, data analytics providers, advertising networks, consumer credit reporting agencies, background check vendors and/or private intelligence companies, court and other public records, and state, federal, or international agencies. We may combine the information we collect from third parties with information that we have collected from you or through your use of our Services.<br/><br/>
<b><h1 id="why">3. Why We Collect Information</h1></b><br/><br/>
We may use your information for various purposes, including:<br/><br/>
• Responding to your requests for information;<br/><br/>
• Providing Services to you, which may include informational, networking, entertainment, merchandising, and/or other services;<br/><br/>
• Verifying your identity and for fraud prevention;<br/><br/>
• Processing your payments;<br/><br/>
• Providing you with updates and information about products, licenses, and/or other Services we may provide;<br/><br/>
• Sending you marketing information about Company and our affiliated entities;<br/><br/>
• Sending you email communications such as electronic newsletters about our Services and events and promotions which may be of interest to you;<br/><br/>
• Improving the effectiveness of our Site and other Services, our marketing endeavors, and our product, license, and service offerings;<br/><br/>
• Customizing your experience on the Site and other Services;<br/><br/>
• Identifying your product, license, and service elections and preferences, providing personalized content and ads, and informing you of new or additional information, products, content, licenses, and/or other Services that may be of interest to you;<br/><br/>
• Helping us address problems with and improve our Site and our products, licenses, and other Services, including testing and creating new products, features, licenses, and services;<br/><br/>
• Protecting the security and integrity of the Site and other Services, including understanding and resolving any technical and security issues reported on our Site or other Services;<br/><br/>
• Engaging in analysis, research, and reports regarding the use of our Site and other Services;<br/><br/>
• For internal business purposes;<br/><br/>
• Complying with the law and protecting the safety, rights, property, or security of Company, the Services, and the general public; and<br/><br/>
• For other purposes disclosed at the time you provide your information or as otherwise set forth in this Privacy Policy.<br/><br/>
<b><h1 id="when">4. When We Disclose Information</h1></b><br/><br/>
We may aggregate, de-identify, and/or anonymize any information collected through the Site or other Services such that such information is no longer linked to your personally identifiable information. We may use and share this aggregated and anonymized information (non-Personal Information) for any purpose, including, without limitation, for research and marketing purposes, and may also share such data with our affiliates and third parties. To the extent permitted by law, certain nonpublic information about you may be disclosed in the following situations:<br/><br/>
• To comply with a validly issued and enforceable subpoena or summons.<br/><br/>
• As necessary to properly comply with or respond to an inquiry or complaint from a federal or state agency.<br/><br/>
• In conjunction with a prospective purchase, sale, or merger of all or part of our business, provided that we take appropriate precautions (for example, through a written confidentiality agreement) so the prospective purchaser or merger partner does not disclose information obtained in the course of the review.<br/><br/>
• As part of any actual or threatened legal proceedings or alternative dispute resolution proceedings either initiated by or against us, provided we disclose only the information necessary to file, pursue, or defend against the action or claim, and take reasonable precautions to ensure that the information disclosed does not become a matter of public record.<br/><br/>
• To provide information to affiliates of the Company and nonaffiliated third parties who perform services or functions for us in conjunction with our Services to you, but only if we have a contractual agreement with the other party which prohibits them from disclosing or using the information other than for the purposes for which it was disclosed. Examples of such disclosures include using a payment processor, a cloud service provider, an email marketing provider, or entities that handle other business functions for us.<br/><br/>
Finally, we also may share your information, including your Personal Information, with our affiliates and third parties, such as companies with whom we have marketing or other relationships, for direct marketing purposes, such as webinars or other matters of interest to you. (If you are a California resident, you have the right to request additional information regarding the “sale” of your information. Please see the section entitled “Your California Privacy Rights” below about these rights.) In addition, we may share the information we have collected about you, including Personal Information, as disclosed at the time you provide your information and as described in this Privacy Policy.<br/><br/>
<b><h1 id="ads">5. Ads and Information About You</h1></b><br/><br/>
You may see certain ads on our Services because we may participate in advertising networks administered by third parties. These networks track your online activities over time and across third-party websites and online services by collecting information through automated means, including through the use of the Tracking Technologies described above, and they use this information to show you advertisements that are tailored to your individual interests. The information they collect may include information about your interactions with our Services, such as the pages you have viewed. This collection and ad targeting may take place both on our Services and on third-party websites and/or applications that participate in the ad network(s). This process also may help us track the effectiveness of our communications and marketing efforts.<br/><br/>
<b><h1 id="yourchoice">6. Your Choices About the Information We Collect</h1></b><br/><br/>
If you would like to access, correct, update, suppress, restrict, or delete your personal information, object to or opt out of the processing of personal information, or withdraw your consent (which will not affect the lawfulness of processing prior to the withdrawal), you may contact us in accordance with the “Contact Us” section below. We will respond to your request consistent with applicable law.<br/><br/>
In your request, please make clear what personal information you would like to have changed or whether you would like to have your personal information suppressed from our database. For your protection, we may only implement requests with respect to the personal information associated with the particular identifier (e.g., email address, device ID, advertising ID) that you use to send us your request, and we may need to verify your identity before implementing your request. We will try to comply with your request as soon as reasonably practicable.<br/><br/>
If you would prefer that we not share your name and mailing address with third parties (other than with our affiliates) to receive promotional offers, you have the option to opt out of such sharing. To do so, please email us at <a href="mailto:hello@jubileemedia.com">hello@jubileemedia.com.</a> Your choice will not affect our ability to share information in the other ways described in this Privacy Policy.<br/><br/>
If you do not wish to receive emails about special offers, events, and other promotions from us, email us at <a href="mailto:hello@jubileemedia.com">hello@jubileemedia.com</a>  to opt out or use the opt-out mechanism included in each such email.<br/><br/>
If you do not wish to receive other marketing materials by regular mail from us, please write us at:<br/><br/>
Jubilee, Inc<br/>
5200 W. Century Blvd., Ste. 704<br/>
Los Angeles, CA 90045<br/><br/>
Please note that certain of your personal information, such as your name or other identifying information, may remain in our database even after a deletion request in order to maintain the integrity and historical record of our database and systems, or to comply with applicable laws and regulations. We reserve the right to send you certain communications relating to your use of our Services, service announcements, notices of changes to this Privacy Policy, or other terms, or similar administrative or transactional messages, and such messages will be unaffected by any choice to opt out of marketing communications.<br/><br/> 
<b><h1 id="childrenprivacy">7. Children’s Privacy</h1></b><br/><br/>
The Site and other Services are not intended for use by children under the age of 18. We do not request, or knowingly collect, any personally identifiable information from children under the age of 18. If you are the parent or guardian of a child under 18 who you believe has provided her or his information to us, please contact us at <a href="mailto:hello@jubileemedia.com">hello@jubileemedia.com</a> to request the deletion of that information.<br/><br/>
<b><h1 id="disclosures">8. Do Not Track Disclosures</h1></b><br/><br/>
Do Not Track (“<b>DNT</b>”) is a web browser setting that requests that a web application disable its tracking of an individual user. When you choose to turn on the DNT setting in your browser, your browser sends a special signal to websites, analytics companies, ad networks, plug in providers, and other web services you encounter while browsing to stop tracking your activity. Various third parties are developing or have developed signals or other mechanisms for the expression of consumer choice regarding the collection of information about an individual consumer’s online activities over time and across third-party websites or online services (e.g., browser do not track signals), but there is no universally-agreed upon standard for what a company should do when it detects a DNT signal. Currently, we do not monitor or take any action with respect to these signals or other mechanisms. <a href="https://allaboutdnt.com/"><u>You can learn more about Do Not Track here.</u></a><br/><br/> 
<b><h1 id="visitors">9. Visitors to the Services Outside of the United States</h1></b><br/><br/>
If you are visiting the Site or other Services from a location outside of the U.S., your connection will be through and to servers located in the U.S. All information you receive from the Site or other applicable Services will be created on servers located in the U.S., and all information you provide will be maintained on web servers and systems located within the U.S. The data protection laws in the United States may differ from those of the country in which you are located, and your information may be subject to access requests from governments, courts, or law enforcement in the United States according to laws of the United States. By using the Site or other Services or providing us with any information, you consent to the transfer to, and processing, usage, sharing, and storage of your information in the United States and in other countries, as set forth in this Privacy Policy.<br/><br/>
<b><h1 id="updatingpersonalinformation">10. Updating Personal Information</h1></b><br/><br/>
We prefer to keep your Personal Information accurate and up to date. If you would like to change your contact information or other Personal Information, please contact us at <a href="mailto:hello@jubileemedia.com">hello@jubileemedia.com</a> or <a href="https://www.jubileemedia.com/nectar-app-support"><u>here</u></a>, or if you are a registered user of our Services, you may change certain Personal Information through your account with us.<br/><br/>
All registered users of our Services have the right to request the removal of certain content they have publicly posted on the Site or other Services. You may be able to remove the content through your account. Otherwise, you may request that Company remove the content by contacting us at <a href="mailto:hello@jubileemedia.com">hello@jubileemedia.com</a>. In some circumstances, we may maintain the content but remove any reference to you so that the posts are anonymized.<br/><br/>
If you submit a request to us, please be sure to include your full name, email address, and information sufficient to permit us to locate the Personal Information you are requesting us to correct or update, or the content you are requesting us to remove. We will make good faith efforts to make requested changes in our then active databases as soon as reasonably practicable (but we may retain prior information as business records). Note that any removal of public postings in accordance with these procedures does not ensure complete or comprehensive removal of the content or information you posted, which may persist in archival records or may have been copied by third parties while publicly accessible.<br/><br/>
<b><h1 id="links">11. Links</h1></b><br/><br/>
For your convenience, the Site, other Services, and this Privacy Policy may contain links to third-party websites or applications. Company is not responsible for the privacy practices, advertising, products, services, or the content of such third-party websites or applications. None of the links on the Site or other Services should be deemed to imply that Company endorses or has any affiliation with the links. Please see our <a href="https://www.lovecommanectar.com/terms"><u>Terms of Use</u> for more information.</a><br/><br/>
<b><h1 id="security">12. Security</h1></b><br/><br/>
We incorporate commercially reasonable safeguards to help protect and secure your Personal Information. However, no data transmission over the internet, mobile networks, wireless transmission, electronic storage of information, or other system can be guaranteed 100% secure. As a result, we cannot guarantee or warrant the security of any information you transmit to or from our Services, or guarantee that it will be free from unauthorized access by third parties. You provide us with your information at your own initiative and risk. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us in accordance with the “Contact Us” section below.<br/><br/>
<b><h1 id="internationaldatatransfers">13. International Data Transfers</h1></b><br/><br/>
Because Company works with global companies and technologies, we may transfer your Personal Information outside of the country in which it was originally provided. This may include transfers to third parties such as service providers or affiliated entities who are located outside the United States, the United Kingdom, and the European Union, where data protection laws may not offer the same level of protection as those in the U.S., U.K., E.U., or “EEA” (defined below). When we transfer personal data outside of these areas, we take steps to make sure that appropriate safeguards are in place to protect your Personal Information.<br/><br/>
<b><h1 id="generaldataprotectionregulation">14. Your Rights Under the General Data Protection Regulation</h1></b><br/><br/>
This section of our Privacy Policy applies if you are a data subject who resides or is located in the European Economic Area (“<b>EEA</b>”). We adopted this section to comply with European privacy laws, including the General Data Protection Regulation (“<b>GDPR</b>”). Any terms defined in the GDPR have the same meaning when used in this section.<br/><br/>
Under applicable law, we are considered the “data controller” of the personal information we handle under this Notice. In other words, we are responsible for deciding how to collect, use, and disclose this information, subject to applicable law.<br/><br/>
We hope to ensure that the Personal Information we possess is always accurate and therefore we encourage you to contact us at the contact information below to update your information or you may update certain Personal Information in your account in case any changes have occurred. We have listed below the rights that you may be able to exercise in respect of the processing of your Personal Information, subject to applicable law. We take every reasonable step to ensure that the Personal Information that we process is limited to the Personal Information that is reasonably required in connection with the purposes set out in this Privacy Policy.<br/><br/>
If you are a resident of or located within the EEA, you have certain data protection rights. These rights include:<br/><br/>
• The right to access, update, or delete the information we have collected from you. Whenever made possible, you can access, update, or request deletion of your Personal Information by contacting us at the contact information below.<br/><br/>
• The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.<br/><br/>
• The right to object. You have the right to object to our processing of your Personal Information.<br/><br/>
• The right of restriction. You have the right to request that we restrict the processing of your Personal Information.<br/><br/>
• The right to data portability. You have the right to be provided with a copy of the information we have on you in a structured, machine-readable, and commonly used format.<br/><br/>
• The right to withdraw consent. You also have the right to withdraw your consent at any time where Company relied on your consent to process your Personal Information.<br/><br/>
<b>(a) Legal Basis for Processing Personal Information</b><br/><br/>
We rely on the following legal bases for processing your Personal Information:<br/><br/>
• Processing of your Personal Information that you provide to us when you interact with our Services is necessary to respond to or implement your request prior to entering into a contract with us.<br/><br/>
• We use account-related data to set up accounts for users in our platform and to administer and support those accounts (such as usernames, email addresses, and billing information), provide you with access to the Services, contact you regarding your use of the Services, or notify you of important changes to the Services. Such use is necessary for the performance of the contract between you and us.<br/><br/>
• We will send you information by email on our new services or other promotions only with your consent or if you otherwise opt-in to receiving those communications. If you do not provide us with your consent to the processing of your Personal Information for this purpose, we will not send you this information. You have the right to withdraw your consent at any time as described below.<br/><br/>
• Our use of data relating to your use of the Site and/or other Services, described above, is necessary for our legitimate interests in understanding how the Site and other Services are being used by you, and to improve your experience with them and our Services offerings. We have also a legitimate interest in aggregating and/or anonymizing the information that we collect through our Site and/or other Services and using this information for our business purposes, as described above. When we process your Personal Information for our legitimate interests, we make sure to consider and balance any potential impact on you, and your rights under data protection laws. Our legitimate business interests do not automatically override your interests – we will not use your Personal Information for activities where our interests are overridden by the impact on you, unless we have your consent or those activities are otherwise required or permitted by law. You have the right to object to processing that is based on our legitimate interests, as further described below.<br/><br/>
<b>(b) Retention of Personal Information</b><br/><br/>
Company will retain your Personal Information only for as long as is necessary for the purposes set out in this Privacy Notice. We will retain and use your Personal Information to the extent necessary to comply with our legal obligations (for example, if we are required to retain your Personal Information to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.<br/><br/>
All information you provide to us is stored on our secure servers or those of our third-party data storage providers.<br/><br/>
<b>How to Exercise Your Rights Under GDPR</b><br/><br/>
If applicable, you may exercise any of your rights under the GDPR by submitting a verifiable data subject request to us by using the contact details below. You may make a request related to your Personal Information or on behalf of someone for which you have authorization. You must include your full name, email address, and attest to the fact that you are a citizen or resident of the EEA by including your country of citizenship or residence in your request. We may require you to confirm your identity and/or legal standing for the request as well as your residency in the EEA in order to obtain the information. We will respond to your request within 30 days or let you know if we need additional time.<br/><br/>
Please note that we will ask you to verify your identity before responding to any such request, and we may deny your request if we are unable to verify your identity or authority to make the request.<br/><br/>
Should you wish to raise a concern about our use of your data (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority; however, we hope that we can assist with any queries or concerns you may have about our use of your Personal Information first by you contacting us as follows:<br/><br/>
Email Address:<br/>
<a href="mailto:hello@jubileemedia.com">hello@jubileemedia.com</a><br/><br/>
Toll-Free Number:<br/>
1 (888) 671-4568<br/><br/>
Postal Address:<br/>
Jubilee, Inc.<br/>
5200 W. Century Blvd., Ste. 704<br/>
Los Angeles, CA 90045<br/><br/>
<b><h1 id="ukgdpr">15. Your Rights Under the UK GDPR</h1></b><br/><br/>
If you are based in the United Kingdom, the following provisions also apply:<br/><br/>
UK GDPR means the Retained Regulation 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC.<br/><br/>
If we share your personal data within Company or with third parties located outside the United Kingdom, we take steps to ensure that appropriate safeguards are in place to guarantee the continued protection of your personal data, such as by entering into the international data transfer addendum to the European Commission’s Standard Contractual Clauses, adopted by the UK Government under Section 119A of the Data Protection Act 2018.<br/><br/>
You have the same data subject rights as those for the EU listed above, except that references to the "GDPR" should be read as references to the "UK GDPR" and complaints should be filed with the UK supervisory authority, the Information Commissioner’s Office.<br/><br/>
<b><h1 id="canadianresidents">16. Information for Canadian Residents</h1></b><br/><br/>
Canadian residents may have additional rights under Canadian law, including the Personal Information Protection and Electronic Documents Act (PIPEDA) and successor legislation.  Please see the website of the Office of the Privacy Commissioner of Canada <a href="http://www.priv.gc.ca">(www.priv.gc.ca)</a> for further information.<br/><br/>  
You and we confirm that it is our wish that this Privacy Policy, the Terms of Use, and all other related policies be drawn up in English. <i>Vous reconnaissent avoir exigé la rédaction en anglais du présent document ainsi que tous les documents qui s'y rattachent.</i><br/><br/>
<b><h1 id="californiaprivacyrights">17. Your California Privacy Rights</h1></b><br/><br/>
This section of the Privacy Policy applies solely to California residents. We adopt this Policy to comply with the California Consumer Privacy Act of 2018 (“<b>CCPA</b>”), as amended by the California Privacy Rights Act of 2020 (“<b>CPRA</b>”). Any terms defined in the CCPA, as amended by the CPRA, have the same meaning when used in this section.<br/><br/>
<b>(a) California Consumer Rights Pursuant to the CCPA/CPRA</b><br/><br/>
As a California consumer, you have specific rights regarding how your Personal Information is collected and used.<br/><br/> 
• You have the right to request that we disclose to you or your authorized agent acting on your behalf certain information covering the past twelve (12) months; <br/>
• You have the right to request correction of any inaccurate Personal Information that we maintain about you;<br/>
• You have the right to request deletion of your Personal Information (with exceptions noted by law – more on this below);<br/>
• You have the right to request that your Personal Information not be Sold to or Shared with third parties;<br/>
• You have the right not to be discriminated or retaliated against because you exercised these rights; and <br/>
• You have the right to request that we limit our use and disclosure of your Sensitive Personal Information, except for such information that is collected or processed without the purpose of inferring characteristics about you.<br/><br/>
<b>(b) Right to Access</b><br/><br/>
Under California law, if you are a resident of California, under certain circumstances, you have the right to request certain information that we collect about you, including:<br/><br/>
• The categories of Personal Information we have collected from you;<br/><br/>
• The categories of sources from which we collected the Personal Information;<br/><br/>
• The business purpose we have for collecting or selling that Personal Information;<br/><br/>
• The categories of third parties with whom we share such Personal Information;<br/><br/>
• The specific pieces of Personal Information we have collected about you;<br/><br/>
• The categories of Personal Information about you that we sold, and the categories of third parties to whom the Personal Information was sold, by category or categories of Personal Information for each category of third parties to whom the information was sold;<br/><br/>
• The categories of Personal Information that we have disclosed about you for a business purpose;<br/><br/>
• The category or categories of consumers’ Personal Information that we have sold, or if we have not sold consumers’ Personal Information;<br/><br/>
• The category or categories of consumers’ Personal Information that we have disclosed for a business purpose, or if we have not disclosed that information for a business purpose; and<br/><br/>
• The categories of sensitive personal information to be collected and the purposes for which the categories of sensitive personal information are collected or used and whether such information is sold or shared, except for such information that is collected or processed without the purpose of inferring characteristics about you.<br/><br/>
Please note that if we collected information about you for a single one-time transaction and do not keep that information in the ordinary course of business, that information will not be retained for purposes of a request under this section. In addition, if we have de-identified or anonymized data about you, we are not required to re-identify or otherwise link your identity to that data if it is not otherwise maintained that way in our records.<br/><br/>
<b>(c) Right to Delete Personal Information</b><br/><br/>
You have the right to request that we delete Personal Information about you that we have collected from you.<br/><br/>
As permitted by the CCPA/CPRA, if you request deletion of Personal Information that we have collected about you, we, our service providers, and our contractors may be unable to comply with such a request if your Personal Information is necessary to:<br/><br/>
• Complete the transaction for which the Personal Information was collected, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between us and you;<br/><br/>
• Prevent, detect, and investigate security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;<br/><br/>
• Debug to identify and repair errors that impair existing intended functionality;<br/><br/>
• Exercise free speech, ensure the right of another Consumer to exercise his or her right of free speech, or exercise another right provided for by law;<br/><br/>
• Comply with the California Electronic Communications Privacy Act pursuant to Chapter 3.6 (commencing with Section 1546) of Title 12 of Part 2 of the Penal Code;<br/><br/>
• Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of that information is likely to render impossible or seriously impair the achievement to such research, if you have provided informed consent;<br/><br/>
• Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;<br/><br/>
• Comply with a legal obligation; or<br/><br/>
• Otherwise use your Personal Information, internally, in a lawful manner that is compatible with the context in which you provided the information.<br/><br/>
<b>(d) Right to Correct Personal Information</b><br/><br/>
You have the right to request that we correct any inaccurate information that we maintain about you.<br/><br/>
<b>(e) Right to Opt-Out</b><br/><br/>
As a California resident, you also have the right, at any time, to tell us not to sell Personal Information – this is called the “right to opt-out” of the sale of Personal Information. At this time, we do not sell consumers’ personal information to third parties.<br/><br/>
<b>(f) Right to Non-Discrimination</b><br/><br/>
We will not discriminate against you for exercising any of your CCPA/CPRA rights. Unless permitted by the CCPA/CPRA, we will not:<br/><br/>
• Deny you goods or services.<br/>
• Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.<br/>
• Provide you a different level or quality of goods or services.<br/>
• Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.<br/><br/>
However, we may offer you certain financial incentives permitted by the CCPA/CPRA that can result in different prices, rates, or quality levels. Any CCPA/CPRA-permitted financial incentive we offer will reasonably relate to your personal information’s value. Participation in a financial incentive program requires your prior opt in consent, which you may revoke at any time.<br/><br/>
<b>(g) Personal Information We Collect About California Consumers</b><br/><br/>
Company collects certain types of personal information about you during your relationship with us, as stated above.  In particular, our Site and other Services may have collected the following categories of Personal Information from our consumers within the last twelve (12) months:<br/><br/>
<table>
      <thead>
        <tr>
          <th>Category </th>
          <th>Collected </th>
          <th>Sources of Personal Information Collected </th>
          <th>Business or Commercial Purpose for Collection </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>A. Identifiers.</td>
          <td>YES</td>
          <td>You <br/>Automatically<br/> Third Parties<br/></td>
          <td>To provide our Services<br/> To provide information you requested<br/> To improve our Services<br/> To identify potential customers <br/> To market our Services <br/> For verifying identity <br/>For fraud prevention purposes<br/>To process payments<br/> To comply with law<br/><br/></td>
        </tr>
        <tr>
          <td>B. Personal information categories listed in the California Customer Records statute <br/> (Cal. Civ. Code § 1798.80(e)).</td>
          <td>YES</td>
          <td>You <br/>Automatically<br/> Third Parties<br/></td>
          <td>To provide our Services<br/> To provide information you requested<br/> To improve our Services<br/> To identify potential customers <br/> To market our Services <br/> For verifying identity <br/>For fraud prevention purposes<br/>To process payments<br/> To comply with law<br/><br/></td>
        </tr>
        <tr>
          <td>C. Protected classification characteristics under California or federal law.</td>
          <td>YES</td>
          <td>You <br/> Third Parties<br/></td>
          <td>To provide our Services to customers <br/>To provide our Services to customers<br/>To comply with law<br/><br/></td>
        </tr>
        <tr>
          <td>D. Commercial information.</td>
          <td>YES</td>
          <td>You <br/>Automatically<br/> Third Parties<br/></td>
          <td>To provide our Services<br/> To market our Services<br/>To improve our Services <br/> To provide information you requested<br/>To comply with law<br/><br/></td>
        </tr>
        <tr>
          <td>E. Biometric information.</td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A<br/><br/></td>
        </tr>
        <tr>
          <td>F. Internet or other similar network activity.</td>
          <td>YES</td>
          <td>Automatically</td>
          <td>To provide our Services<br/> To market our Services<br/> To improve our Services (e.g., usage pattern data)<br/> For internal reports and information security purposes<br/><br/></td>
        </tr>
        <tr>
          <td>G. Geolocation data.</td>
          <td>YES</td>
          <td>You <br/> Automatically<br/> Third Parties<br/></td>
          <td>To provide our Services <br/> To provide information you requested<br/> To improve our Services<br/> To identify potential customers<br/> To market our Services<br/>For verifying identity<br/>For fraud prevention purposes <br/>To process payments<br/>To comply with law<br/><br/></td>
        </tr>
        <tr>
          <td>H. Sensory data.</td>
          <td>YES</td>
          <td>You <br/> Third Parties<br/></td>
          <td>To provide our Services <br/> To provide information you requested<br/>To improve our Services<br/>To market our Services<br/><br/></td>
        </tr>
        <tr>
          <td>I. Professional or employment-related information.</td>
          <td>YES</td>
          <td>You</td>
          <td>To provide our Services <br/> To provide information you requested<br/><br/></td>
        </tr>
        <tr>
          <td>J. Non-public education information <br/>(per the Family Educational Rights and Privacy Act <br/>(20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>
        <tr>
        <td>K. Inferences drawn from other personal information.</td>
        <td>YES</td>
        <td>Third Parties</td>
        <td>To provide our Services <br/>To market our Services<br/>To improve our Services<br/>For detecting security incidents and protecting our Site and other Services</td>
        </tr>
        <tr>
          <td>L. Sensitive Personal Information.</td>
          <td>YES</td>
          <td>You</td>
          <td>To provide our Services<br/>To provide information you requested<br/> For detecting security incidents and protecting our Site and other Services<br/><br/></td>
        </tr>
      </tbody>
    </table>


<b>(h) How to Exercise Your California Privacy Rights</b><br/><br/>
You may make a request to us at the contact information below. We may require you to confirm your identity and your residency in order to obtain the information, and you are entitled to make this request only twice per year. Please include “California Privacy Rights” as the subject line. You must include your full name and email address, and attest to the fact that you are a California resident by including a California postal address in your request. We will acknowledge your request within 10 days and respond to your request within 45 days or let you know if we need additional time.<br/><br/>
Email Address:<br/>
<a href="mailto:hello@jubileemedia.com">hello@jubileemedia.com</a><br/><br/>
Postal Address:<br/>
Jubilee, Inc.<br/>
5200 W. Century Blvd., Ste. 704<br/>
Los Angeles, CA 90045<br/><br/>
Toll-Free Number:<br/>
1 (888) 671-4568<br/><br/>
<b><h1 id="nevadaprivacyrights">18. Your Nevada Privacy Rights</h1></b><br/><br/>
If you are a Nevada resident, you have the right to request certain information from us regarding the collection and sale of your personal information (as defined in Nevada Revised Statutes 603A.320) during your visit to our websites or when you otherwise interact with us online. At this time, we do not sell consumers’ personal information to third parties.<br/><br/>
<b><h1 id="virginiaprivacyrights">19. Your Virginia Privacy Rights</h1></b><br/><br/>
If you are a resident of Virginia, you have the right under the Virginia Consumer Data Protection Act, upon a verified request, to:<br/><br/>
• Confirm whether or not a controller is processing your personal data and to access such personal data;<br/><br/>
• Correct inaccuracies in your personal data;<br/><br/>
• Delete your personal data;<br/><br/>
• Obtain a copy of your personal data that you previously provided to us in a portable, and if technically feasible, readily usable format, if processing is carried out by automated means;<br/><br/>
• Opt out of the processing of your personal data for purposes of (i) targeted advertising, (ii) the sale of personal data, or (iii) profiling in furtherance of decisions that produce legal or similarly significant effects concerning you as a consumer.<br/><br/>
To exercise these rights, you may make a request to confirm, access, correct, delete, obtain a copy, or opt-out of the processing of your personal data for targeting advertising, sale, or profiling by contacting us using the information in the section entitled “Contact Us” below.<br/><br/>
We may require you to confirm your identity and your residency in order to obtain the information, and you are only entitled to make this request up to twice annually. For emails, please include “Virginia Privacy Rights” as the subject line. You must include your full name, email address, and attest to the fact that you are a Virginia resident. We will process your request within 45 days or let you know if we need additional time or cannot process your request. If you make this request by telephone, we may also ask you to provide the request in writing so that we may verify your identity. If we are unable to honor your request for any reason, we will notify you of the reason within the request time period.<br/><br/>
If we decline to take action on your request, residents of Virginia can appeal our decision by submitting an email to hello@jubileemedia.com entitled “Virginia Privacy Rights Appeal” and we will review your request and respond with a written explanation of the reasons for our decision. If your appeal is denied, you may contact the <a href="https://www.virginia.gov/agencies/office-of-the-attorney-general/"><u>Virginia Attorney General</u></a> to submit a complaint.<br/><br/>
<b><h1 id="changesinprivacypolicy">20. Changes in Privacy Policy</h1></b><br/><br/>
We may elect to change or amend our Privacy Policy; in such event, we will post the policy changes in our Privacy Policy on the Site and/or other Services, and they will become effective on the date posted. If you are concerned about how your personal information is used, please visit our Services often for this and other important announcements and updates.<br/><br/>
<b><h1 id="contact">21. Contact Us</h1></b><br/><br/>
If you have questions or concerns with respect to our Privacy Policy, you may contact us at <br/> <a href="mailto:hello@jubileemedia.com">hello@jubileemedia.com</a> or at: <br/><br/>
Jubilee, Inc.<br/>
5200 W. Century Blvd., Ste. 704<br/>
Los Angeles, CA 90045<br/><br/>
Toll-Free Number:
1 (888) 671-4568<br/><br/>


    </div>
  )
}