import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function ExploreLoveprints() {
  const navigate = useNavigate()
  
  return(
    <div className="z-[5] max-w-[633px] rounded-[16px] grid grid-rows-5 2xxmd:grid-rows-2 border-[2.5px] border-[#1F1125]">
      <div className="z-[5] rounded-t-[13px] row-span-2 2xxmd:row-span-1 bg-cover bg-center bg-no-repeat bg-[url('./assets/images/explore-loveprint-preview-hd.png')]">
      </div>
      <div className='z-[5] row-span-3 2xxmd:row-span-1 border-t-[2.5px] border-t-[#1F1125]'>
        <div className='z-[5] py-[36px] px-[36px] flex flex-col gap-[8px]'>
          <div className='z-[5] font-bold text-[#1F1125] text-[36px]'>explore the others</div>
          <p className='z-[5] font-normal text-[#1F1125] text-[20px] leading-[140%]'>explore all 16 loveprints. getting familiar with all the loveprints will help you understand how different types of people show up in their relationships.</p>
          <div className='z-[5] pt-[16px]'>
            <div className='z-[5] rounded-[30px] bg-[#1F1125] w-[182px] h-[60px] flex flex-col justify-center items-center cursor-pointer' onClick={() => {
                navigate('/loveprints')
              }}>
              <span className='z-[5] font-bold text-[22px] text-white'>explore</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}