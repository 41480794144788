import React from 'react'
import { ClipLoader } from 'react-spinners';
import RadioSelect from './Global/RadioSelect'
import Radio from './Global/RadioSelect/Radio'
import ReactGA from 'react-ga4';

export type DemographicsFormData = {
  age: string | undefined;
  sexualOrientation: string | undefined;
  genderIdentity: string | undefined;
  relationshipStatus: string | undefined;
}

ReactGA.initialize("G-0LZ3Y64TPB");

export default function DemographicsForm({ form, setForm, onSubmit, onSkip, isLoading }: { form: DemographicsFormData, setForm: (form: DemographicsFormData) => void, onSubmit: () => void, onSkip: () => void, isLoading: boolean }) {
  const formComplete = form.age && form.sexualOrientation && form.genderIdentity && form.relationshipStatus

  return (
    <>
      <div className='px-[16px] sm:px-[20px] reg:px-[24px] pb-[12px] md:pb-0 md:pl-0 flex flex-col items-stretch md:items-center gap-[36px]'>
        <span className='text-[#1F1125] font-[800] text-[24px] flex-grow text-center'>can you tell me a little about yourself?</span>
        <div className='flex flex-col md:flex-row gap-[36px] md:gap-[60px] flex-grow'>
          <div className='flex flex-col gap-[36px]'>
            <RadioSelect
              label="age"
              options={["under 20", "21-25", "26-30", "31-35", "36-40", "over 40"]}
              onOptionClick={(age) => { setForm({ ...form, age: age }) }}
              selectedOption={form.age}
            />
            <RadioSelect
              label="sexual orientation"
              options={["straight", "lesbian", "gay", "bisexual", "pansexual", "asexual", "queer", "prefer not to say", "sexual orientation not listed"]}
              onOptionClick={(orientation) => { setForm({ ...form, sexualOrientation: orientation }) }}
              selectedOption={form.sexualOrientation}
            />
          </div>
          <div className='flex flex-col gap-[36px]'>
            <RadioSelect
              label="gender identity"
              options={["female (cis)", "male (cis)", "female (transgender)", "male (transgender)", "gender neutral", "gender fluid", "non-binary", "two spirit", "prefer not to say", "gender identity not listed"]}
              onOptionClick={(identity) => { setForm({ ...form, genderIdentity: identity }) }}
              selectedOption={form.genderIdentity}
            />
            <RadioSelect
              label="relationship status"
              options={["single", "married", "in a relationship"]}
              onOptionClick={(status) => { setForm({ ...form, relationshipStatus: status }) }}
              selectedOption={form.relationshipStatus}
            />
            <div className='md:hidden'></div>
            <div className='md:hidden'></div>
            <div className='md:hidden'></div>
            <div className='md:hidden'></div>
            <div className='md:hidden'></div>
          </div>
        </div>
        <div className='hidden md:block'>
          <div className='flex flex-col gap-[16px] items-center'>
            { /* TODO: use Button class */}
            <button type="button" disabled={!formComplete} onClick={() => {
              ReactGA.event({
                category: 'Demographics Form - Submitted',
                action: 'Demographics Form - Submitted'
              })
              onSubmit()
            }} className={"w-[175px] flex justify-center items-center rounded-100 font-bold text-lg leading-6 h-10 align-middle bg-[#1F1125] text-[#F2F2F2] " + (formComplete ? "" : "opacity-70")}>
              {!isLoading && <span>submit</span>}
              {isLoading && <ClipLoader color='white' size="20" speedMultiplier={0.75} />}
            </button>
            <span className='text-[#1F1125] text-[20px] font-normal underline cursor-pointer' onClick={() => {
              ReactGA.event({
                category: 'Demographics Form - Skipped',
                action: 'Demographics Form - Skipped'
              })
              onSkip()
            }}>skip</span>
          </div>
        </div>
      </div>
      <div className='fixed bottom-0 w-[100vw] h-[80px] bg-[#F2F2F2] md:hidden border border-t-[2px] border-t-[#1F1125] flex flex-col justify-center items-stretch'>
        <div className='flex flex-row justify-around items-center'>
          { /* TODO: use Button class */}
          <span className='text-[#1F1125] text-[20px] font-normal underline cursor-pointer' onClick={onSkip}>skip</span>
          <button type="button" disabled={!formComplete} onClick={onSubmit} className={"w-[175px] flex justify-center items-center rounded-100 font-bold text-lg leading-6 h-10 align-middle bg-[#1F1125] text-[#F2F2F2] " + (formComplete ? "" : "opacity-70")}>
            {!isLoading && <span>submit</span>}
            {isLoading && <ClipLoader color='white' size="20" speedMultiplier={0.75} />}
          </button>
        </div>
      </div>
    </>
  )
}
