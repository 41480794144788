import React from "react"
import { image } from "../../../api/dataSource"
import classNames from "classnames"

export function LoveprintsGridScrollNode({
  result,
  selected,
  onClick
}: {
  result: string,
  selected?: boolean,
  onClick: (() => void)
}) {
  return (
    <img style={{WebkitBorderRadius: "100%"}} width={selected ? "120px" : "90px"} height={selected ? "120px" : "90px"} src={image(result, true)} className={classNames("cursor-pointer rounded-full border-[#1F1125]", {
      "p-[6px] border-[2px]": selected,
      "border-[1px]": !selected
    })} onClick={() => {
      onClick()
    }} />
  )
}

export function LoveprintsGridNode({
  result,
  selected,
  onClick
}: {
  result: string,
  selected?: boolean,
  onClick: (() => void)
}) {
  return (
    <div className="flex flex-col items-center gap-[24px]">
      <div className={classNames("p-[6px] border-[2px] rounded-full", {
          "border-[#1F1125]": selected,
          "border-[transparent]": !selected
        })}>
        <img src={image(result, true)} className={"w-full h-full cursor-pointer"} onClick={() => {
          onClick()
        }} />
      </div>
      <span className="hidden md:block text-[#1F1125] font-bold text-[30px]">{result.toUpperCase()}</span>
    </div>
  )
}

export default function LoveprintsGrid({
  result,
  onClickedResult
}: {
  result: string,
  onClickedResult: ((result: string) => void)
}) {
  return (
    <div className="w-full flex flex-col items-stretch border-l-[2.5px] border-l-[#1F1125]">
      <div className="flex-grow h-[64px] pl-[36px] flex flex-col justify-center text-[20px] text-[#1F1125] border-b-[2.5px] border-b-[#1F1125]">
        <span className="opacity-60">click a loveprint to view details</span>
      </div>
      <div className="grid grid-rows-4 grid-cols-4 gap-[28px] flex-grow px-[60px] py-[60px]">
        <LoveprintsGridNode result="AWPO" selected={result == "AWPO"} onClick={() => {
          onClickedResult("AWPO")
        }} />
        <LoveprintsGridNode result="AWEO" selected={result == "AWEO"} onClick={() => {
          onClickedResult("AWEO")
        }} />
        <LoveprintsGridNode result="RWPO" selected={result == "RWPO"} onClick={() => {
          onClickedResult("RWPO")
        }} />
        <LoveprintsGridNode result="RWEO" selected={result == "RWEO"} onClick={() => {
          onClickedResult("RWEO")
        }} />
        <LoveprintsGridNode result="AWPG" selected={result == "AWPG"} onClick={() => {
          onClickedResult("AWPG")
        }} />
        <LoveprintsGridNode result="AWEG" selected={result == "AWEG"} onClick={() => {
          onClickedResult("AWEG")
        }} />
        <LoveprintsGridNode result="AIPO" selected={result == "AIPO"} onClick={() => {
          onClickedResult("AIPO")
        }} />
        <LoveprintsGridNode result="RWPG" selected={result == "RWPG"} onClick={() => {
          onClickedResult("RWPG")
        }} />
        <LoveprintsGridNode result="AIEO" selected={result == "AIEO"} onClick={() => {
          onClickedResult("AIEO")
        }} />
        <LoveprintsGridNode result="RWEG" selected={result == "RWEG"} onClick={() => {
          onClickedResult("RWEG")
        }} />
        <LoveprintsGridNode result="RIPO" selected={result == "RIPO"} onClick={() => {
          onClickedResult("RIPO")
        }} />
        <LoveprintsGridNode result="RIEO" selected={result == "RIEO"} onClick={() => {
          onClickedResult("RIEO")
        }} />
        <LoveprintsGridNode result="AIPG" selected={result == "AIPG"} onClick={() => {
          onClickedResult("AIPG")
        }} />
        <LoveprintsGridNode result="AIEG" selected={result == "AIEG"} onClick={() => {
          onClickedResult("AIEG")
        }} />
        <LoveprintsGridNode result="RIPG" selected={result == "RIPG"} onClick={() => {
          onClickedResult("RIPG")
        }} />
        <LoveprintsGridNode result="RIEG" selected={result == "RIEG"} onClick={() => {
          onClickedResult("RIEG")
        }} />
      </div>
    </div>
  )
}