import classNames from "classnames";
import { useRef, useState } from "react";
import ChevIcon from "../../../assets/icons/chev-down.svg";
import RadioItem from "./RadioItem";
import RadioText from "./RadioText";
import React from "react";
import ReactGA from 'react-ga4';

ReactGA.initialize("G-0LZ3Y64TPB");

export default function RadioSelect({
  label,
  options,
  onOptionClick,
  selectedOption
}: {
  label: string;
  options: string[];
  onOptionClick: (option: string) => void;
  selectedOption: string
}) {
  return (
    <div className="flex flex-col gap-[13px]">
      <p className="font-bold lowercase text-[20px]">{label}</p>
      <div className="flex flex-col gap-[13px]">
        {options.map(x => {
          return <>
            <RadioItem isSelected={selectedOption == x} option={x} onClick={() => {
              ReactGA.event({
                category: 'Demographics Form - Option Clicked',
                action: 'Demographics Form - Option Clicked',
                label: x
              })
              onOptionClick(x)
            }} />
          </>
        })}
      </div>
    </div>
  );
}
