import React from 'react'
import { primaryColor, tintColor, colorName, forYou, header1, header2, header3, header4, image, loveprintNumberDescription, loveprintNumberShortDescription, paragraph1, paragraph2, paragraph3, paragraph4, youAre, yourMotto } from '../../api/dataSource'
import HeaderImage from "../../assets/images/result-header-text.svg"
import YourLoveprintNumber from "../../assets/images/your-loveprint-number.svg"
import { AssessmentResponse } from '../../generated/graphql'
import ExploreLoveprints from '../Loveprints/ExploreLoveprints'
import ResultMap from './ResultMap'
import ShareResults from './ShareResults'

export function ResultQualitative({
  result
}: {
  result: string
}) {
  return (
    <div className='w-full'>
      <div className="hidden md:flex flex-row gap-[16px] items-center py-4">
        <img src={image(result)} width="64" height="64" className='inline-block' />
        <span className={`${primaryColor(result)} font-black text-[96px] inline-block mt-[6px] leading-[96px]`}>{result.toUpperCase()}</span>
      </div>
      <div className="flex md:hidden flex-row gap-[16px] items-center py-4">
        <img src={image(result)} width="52" height="52" className='inline-block' />
        <span className={`${primaryColor(result)} font-black text-[72px] leading-[72px] mt-[4px] inline-block`} >{result.toUpperCase()}</span>
      </div>
    </div>
  )
}

export default function ResultsDetail({
  response
}: {
  response: AssessmentResponse
}) {
  const result = response.resultQualitative
  const resultQuantitative = response.resultQuantitative

  return (
    <div className='z-[10] w-full 2xxmd:max-w-[631px] flex flex-col items-start text-[#1F1125]'>
      <span className='text-[#1F1125] font-bold text-[36px]'>your nectar <span style={{fontFamily: "Garamond Premier Pro", fontSize: "43px"}} className="italic">loveprint</span> is</span>
      <div className='-mt-[10px] w-full'>
        <ResultQualitative result={result} />
      </div>
      <div className='z-[10] flex flex-col gap-[10px] pt-[8px]'>
        <span className='leading-[140%] text-[20px]'>
          <span className='font-bold'>your color</span>
          <span className='font-normal'>: {colorName(result)}</span>
        </span>
        <span className='z-[10] leading-[140%] text-[20px]'>
          <span className='font-bold'>you are</span>
          <span className='font-normal'>: {youAre(result)}</span>
        </span>
        <span className='z-[10] leading-[140%] text-[20px]'>
          <span className='font-bold'>your motto</span>
          <span className='font-normal'>: {yourMotto(result)}</span>
        </span>
        <p className='z-[10] leading-[140%] text-[20px] pb-[6px]'>
          <span className='font-bold'>your loveprint number</span>
          <span className='font-normal'>: {resultQuantitative}, so {loveprintNumberShortDescription(resultQuantitative)}.</span>
        </p>
      </div>
      <div className='z-[10] py-[36px]'>
        <ShareResults result={result} />
      </div>
      <div className='z-[10]'>
        <p className='z-[10] leading-[140%] text-[20px] font-normal pt-[10px]'>
          <span className='font-bold'>for you</span>
          <span>: {forYou(result)}</span>
        </p>
      </div>
      <div className='z-[10] w-full'>
        <div className='z-[5] pt-[48px] block 2xxmd:hidden'>
          <ResultMap result={result} resultQuantitative={resultQuantitative} progress1={response.subscale1Progress} progress2={response.subscale2Progress} progress3={response.subscale3Progress} progress4={response.subscale4Progress} />
        </div>
        <div className='z-[5] leading-[140%] text-[20px] font-normal pt-[50px]'>
          <p className='z-[5] font-bold text-[24px] pb-[10px]'>{header1(result)}</p>
          <p>{paragraph1(result)}</p>
        </div>
        <div className='leading-[140%] text-[20px] font-normal pt-[50px]'>
          <p className='z-[5] font-bold text-[24px] pb-[10px]'>{header2(result)}</p>
          <p>{paragraph2(result)}</p>
        </div>
        <div className='z-[5] leading-[140%] text-[20px] font-normal pt-[50px]'>
          <p className='z-[5] font-bold text-[24px] pb-[10px]'>{header3(result)}</p>
          <p>{paragraph3(result)}</p>
        </div>
        <div className='z-[5] leading-[140%] text-[20px] font-normal pt-[50px]'>
          <p className='z-[5] font-bold text-[24px] pb-[10px]'>{header4(result)}</p>
          <p>{paragraph4(result)}</p>
        </div>
      </div>
      <div className='z-[5] pt-[80px] flex flex-col'>
        <img src={YourLoveprintNumber} className={'w-[50%]'} />
        <span className='font-black text-[96px] -mt-[12px]'>{resultQuantitative}</span>
        <p className='leading-[140%] text-[20px] font-normal pt-[10px]'>
          <p className='font-bold text-[24px] pb-[10px]'>{loveprintNumberShortDescription(resultQuantitative)}</p>
          <p>{loveprintNumberDescription(resultQuantitative)}</p>
        </p>
      </div>
      <div className='z-[5] pt-[36px] w-[90%]'>
        <ExploreLoveprints />
      </div>
      <span className='pb-[120px]' />
    </div>
  )
}