import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react"
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { CURRENT_USER, SUBSCRIBE } from "../../api/api";
import { loveprintMapLabelIcon, loveprintMapLabelLeft, loveprintMapLabelLeftCopy, loveprintMapLabelRight, loveprintMapLabelRightCopy, loveprintMapSectionDescription, loveprintMapSectionHeader, loveprintMapTitle } from "../../api/dataSource";
import LoveprintSection from "../../components/Loveprints/Section/LoveprintSection";
import LoveprintsGrid from "../../components/Loveprints/Collection/LoveprintsGrid";
import LoveprintsHeader from "../../components/Loveprints/LoveprintsHeader";
import LoveprintViewer from "../../components/Loveprints/LoveprintViewer";
import Footer from "../../components/Global/Footer";
import HamburgerMenu from "../../components/Global/HamburgerMenu";
import Nav from "../../components/Global/Nav";
import ReactGA from 'react-ga4';

ReactGA.initialize("G-0LZ3Y64TPB");

export default function LoveprintsView() {
  const navigate = useNavigate()

  ReactGA.send({ hitType: "pageview", page: (window.location.pathname + window.location.search) });

  const [isShowingMenu, setIsShowingMenu] = useState(false)

  return (
    <div className="bg-[#F2F2F2]">
      <div className="z-20 fixed bg-[#F2F2F2]">
        <Nav inAssessment={true} isHamburgerOpen={isShowingMenu} onHamburgerClick={() => {
          setIsShowingMenu(!isShowingMenu)
        }} onNectarClick={() => {
          navigate('/')
        }} />
      </div>
      {isShowingMenu && <HamburgerMenu inAssessment={true} />}
      <LoveprintsViewContainer />
    </div>
  )
}

function LoveprintsViewContainer() {
  const navigate = useNavigate()

  // State

  const [result, setResult] = useState("AWPO")
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [hasSetInitialState, setHasSetInitialState] = useState(false)

  // API

  const { data, error, loading, called } = useQuery(CURRENT_USER, { fetchPolicy: 'network-only' })
  const [subscribe, _] = useMutation(SUBSCRIBE);
  const currentUser = data?.currentUser.user

  // API <-> State Interaction

  if (!loading && called && currentUser?.subscribedAt != null && !hasSetInitialState) {
    setHasSetInitialState(true)
    setIsSubscribed(true)
  }

  // Render

  return (
    <div className="pt-[83px] mh-[100vh_-_calc(83px)] md:pt-[85px] md:h-[100vh_-_calc(85px)] flex flex-col w-full">
      <LoveprintsHeader />
      <div className="hidden md:flex flex-row w-full border-b-[#1F1125] border-b-[2.5px]">
        <LoveprintViewer onClickedResult={(result) => {
          setResult(result)
        }} result={result} />
        <LoveprintsGrid result={result} onClickedResult={(result) => {
          setResult(result)
        }} />
      </div>
      <div className="flex md:hidden flex-row w-full border-b-[#1F1125] border-b-[2.5px]">
        <LoveprintViewer onClickedResult={(result) => {
          setResult(result)
        }} result={result} />
      </div>
      <div className="pt-[90px] flex flex-row justify-center pb-[90px]">
        <div className="max-w-[761px] flex flex-col items-center gap-[66px] px-[24px] md:px-0">
          <LoveprintSection result={result} index={1} header={loveprintMapSectionHeader(0)} body={loveprintMapSectionDescription(0)} mapIcon={<img src={loveprintMapLabelIcon(0)} />} mapTitle={loveprintMapTitle(0)} mapCopyLeft={loveprintMapLabelLeftCopy(0)} mapCopyRight={loveprintMapLabelRightCopy(0)} mapCutScore={26} mapLabelLeft={loveprintMapLabelLeft(0)} mapLabelRight={loveprintMapLabelRight(0)} mapSubtotal={42} />
          <LoveprintSection result={result} index={2} header={loveprintMapSectionHeader(1)} body={loveprintMapSectionDescription(1)} mapIcon={<img src={loveprintMapLabelIcon(1)} />} mapTitle={loveprintMapTitle(1)} mapCopyLeft={loveprintMapLabelLeftCopy(1)} mapCopyRight={loveprintMapLabelRightCopy(1)} mapCutScore={26} mapLabelLeft={loveprintMapLabelLeft(1)} mapLabelRight={loveprintMapLabelRight(1)} mapSubtotal={42} />
          <LoveprintSection result={result} index={3} header={loveprintMapSectionHeader(2)} body={loveprintMapSectionDescription(2)} mapIcon={<img src={loveprintMapLabelIcon(2)} />} mapTitle={loveprintMapTitle(2)} mapCopyLeft={loveprintMapLabelLeftCopy(2)} mapCopyRight={loveprintMapLabelRightCopy(2)} mapCutScore={26} mapLabelLeft={loveprintMapLabelLeft(2)} mapLabelRight={loveprintMapLabelRight(2)} mapSubtotal={42} />
          <LoveprintSection result={result} index={4} header={loveprintMapSectionHeader(3)} body={loveprintMapSectionDescription(3)} mapIcon={<img src={loveprintMapLabelIcon(3)} />} mapTitle={loveprintMapTitle(3)} mapCopyLeft={loveprintMapLabelLeftCopy(3)} mapCopyRight={loveprintMapLabelRightCopy(3)} mapCutScore={36} mapLabelLeft={loveprintMapLabelLeft(3)} mapLabelRight={loveprintMapLabelRight(3)} mapSubtotal={42} />
          <LoveprintSection result={result} index={5} header={loveprintMapSectionHeader(4)} body={loveprintMapSectionDescription(4)} mapIcon={<img src={loveprintMapLabelIcon(4)} />} mapTitle={loveprintMapTitle(4)} isLoveprintNumber={true} />
          <div className="cursor-pointer rounded-full w-full md:w-[224px] h-[48px] bg-[#1F1125] flex justify-center items-center font-bold text-[20px] text-[#F2F2F2]" onClick={() => {
            navigate('/loveprint')
          }}>take the quiz</div>
        </div>
      </div>
      <Footer hasSubscribed={isSubscribed} onSubscribeClick={(email) => {
        setIsSubscribed(true)
        subscribe({
          variables: {
            email: email,
            fromHome: true
          }
        })
        ReactGA.event({
          category: 'Email Subscription - All Loveprints Footer',
          action: 'Email Subscription  - All Loveprints Footer',
          label: email
        })
      }} />
    </div>
  )
}