import React from "react";
import { useNavigate } from "react-router-dom";
import Splash from "./../../assets/images/splash.png"

export default function HamburgerMenu({
  inAssessment
}: {
  inAssessment: boolean
}) {
  const navigate = useNavigate()

  return (
    <div className="bg-[#F2F2F2] align-middle text-center h-[100vh] w-[100vw]">
      <div className="flex flex-col gap-12">
        <span className="text-[#1F1125] font-black text-lg mt-[25vh] sm:mt-[30vh]">social</span>
        <div className="flex flex-col gap-4 items-center">
          <span className="text-[#1F1125] font-normal text-lg cursor-pointer" onClick={() => { window.open("https://lovecommanectar.co/3BtpLAv") }}>youtube</span>
          <span className="text-[#1F1125] font-normal text-lg cursor-pointer" onClick={() => { window.open("https://lovecommanectar.co/3f22G0l") }}>instagram</span>
          <span className="text-[#1F1125] font-normal text-lg cursor-pointer" onClick={() => { window.open("https://lovecommanectar.co/3qRpr9W") }}>tiktok</span>
          <span className="text-[#1F1125] font-normal text-lg cursor-pointer" onClick={() => { window.open("https://lovecommanectar.co/3do1d47") }}>twitter</span>
          <div className="h-[48px]"></div>
          <span
            className="text-[#1F1125] font-bold text-[20px] cursor-pointer"
            onClick={() => { navigate('/loveprint') }}
          >
            take loveprint
          </span>
          <div
            className="pt-[12px] border-[1.5px] border-black rounded-[48px] px-[21px] py-[8.5px] cursor-pointer flex flex-col justify-center items-center"
            style={{ backgroundImage: `url(${Splash})`, backgroundSize: 'cover', backgroundPosition: 'bottom' }}
            onClick={() => { window.open('https://apps.apple.com/us/app/nectar-love-compatibility/id6476018063', '_blank') }}
          >
            <span className="text-white font-bold text-[20px] cursor-pointer"
              onClick={() => { window.open('https://apps.apple.com/us/app/nectar-love-compatibility/id6476018063', '_blank') }}>
              get the nectar app
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}