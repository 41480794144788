import React, { useEffect, useRef, useState } from "react";
import { MouseEventHandler } from "react"
import Button from "./Button"
import Input from "./Input"
import HeaderImage from "./../../assets/images/email-modal-image.png"
import ReactGA from 'react-ga4';

ReactGA.initialize("G-0LZ3Y64TPB");

export default function EmailCaptureModal({
  isSubscribed,
  onClick,
  onSubscribe,
  setIsShowing,
  wall,
  blur
}: {
  isSubscribed: Boolean,
  onClick: (() => void),
  onSubscribe: (email: string) => void,
  setIsShowing: React.Dispatch<React.SetStateAction<boolean>>,
  wall?: boolean,
  blur?: boolean
}) {
  const ref = useRef<HTMLDivElement>(null);

  ReactGA.send({ hitType: "pageview", page: (window.location.pathname + window.location.search) });

  ReactGA.event({
    category: 'Home Email Capture',
    action: 'Home Email Capture'
  })

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsShowing(false)
        ReactGA.event({
          category: 'Home Email Capture - Closed',
          action: 'Home Email Capture - Closed'
        })
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const [email, setEmail] = useState("")

  return (
    <div className="min-h-[100vh] w-full fixed inset-0 z-30 overflow-y-auto bg-black/50">
      <div className="flex min-h-full justify-center text-center items-center p-0">
        <div className={"flex flex-col bg-cover bg-center bg-no-repeat rounded-2xl fixed ssm:mx-0 max-h-[480px] ssm:max-h-full " + (isSubscribed ? "bg-[url('./assets/images/modal-background-mobile-subbed.png')] ssm:bg-[url('./assets/images/modal-background-subbed.png')] aspect-[327/334] ssm:aspect-[730/400] md:aspect-[730/480] ssm:max-w-[450px] w-[calc(85vw)] max-w-[350px]" : "ssm:bg-[url('./assets/images/modal-background.png')] bg-[url('./assets/images/modal-background-mobile.png')] mx-[24px] aspect-[327/446] ssm:aspect-[730/660] md:aspect-[730/596] ssm:w-[500px] md:w-[600px]")} style={{ backgroundSize: "100% 100%" }} ref={ref}>
          <div className="ml-auto pt-6 pr-5 cursor-pointer" onClick={() => {
            ReactGA.event({
              category: 'Home Email Capture - Closed',
              action: 'Home Email Capture - Closed'
            })
            onClick()
          }}>
            <svg width="22" height="22" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L20.4439 20.4439" stroke="#1F1125" stroke-width="2.5" />
              <path d="M21 1.55615L1.55609 21.0001" stroke="#1F1125" stroke-width="2.5" />
            </svg>
          </div>
          <div className="flex flex-col justify-center w-full h-full ssm:flex-none">
            <div className={"flex flex-col items-center h-full max-h-[400px] ssm:max-h-full ssm:justify-center gap-2 ssm:gap-4 py-0 md:py-[40px] " + (isSubscribed ? "justify-center px-[20px] ssm:px-[75px]" : "justify-around px-[32px] sm:px-[64px] ssm:px-[72px]")}>
              {!isSubscribed &&
                <div className="flex flex-col justify-center items-center mb-3 ssm:mb-4 md:mb-6 max-w-[425px]">
                  <img className="" src={HeaderImage} />
                  <span className="text-[#1F1125] font-bold text-lg lg:text-xl -mt-[4px] sm:-mt-2">a love personality test</span>
                </div>
              }
              {isSubscribed &&
                <span className="text-[#1F1125] font-black text-[24px] ssm:text-xl md:text-2xl mb-3 ssm:mb-4 md:mb-6 text-center">awesome!</span>
              }
              <div className="flex flex-col items-center w-full justify-around sm:gap-4 gap-2">
                {!isSubscribed &&
                  <Input value={email} isEmail={true} onInput={(e) => setEmail(e)} placeholder="enter your email" useRainbowBorder={false} isDarkMode={true} />
                }
                {!isSubscribed &&
                  <Button onClick={() => {
                    onSubscribe(email)
                    ReactGA.event({
                      category: 'Home Email Capture - Subscription',
                      action: 'Home Email Capture - Subscription'
                    })
                  }} title="subscribe" isDarkMode={true} />
                }
              </div>
              {isSubscribed &&
                <span className="text-[#1F1125] font-regular text-base ssm:text-lg">you’re on the list, so I’ll let you know when the test launches (before everyone else)</span>
              }
              <div className={"text-center md:py-[48px] " + (isSubscribed ? "ssm:py-[40px] md:py-[40px] py-[20px]" : "py-4 ssm:py-8 ssm:pb-4 md:py-4 pb-4")}>
                <span className="text-[#1F1125] font-black text-lg ssm:text-xl md:text-2xl">love, <span className="text-[#F23900] font-black text-lg ssm:text-xl md:text-2xl">nectar</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}