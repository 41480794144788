import React, { useState } from "react";
import ResultMap from "../../components/Results/ResultMap";
import ResultsDetail from "../../components/Results/ResultsDetail";
import Nav from "../../components/Global/Nav";
import { useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client";
import { CURRENT_USER, SEE_EMAIL_MODAL, SUBSCRIBE } from "../../api/api";
import { ClipLoader } from "react-spinners"
import { primaryBgColor, secondaryBgColor } from "../../api/dataSource";
import HamburgerMenu from "../../components/Global/HamburgerMenu";
import AssessmentEmailCapture from "../../components/Global/AssessmentEmailCapture";
import ReactGA from 'react-ga4';
import Footer from "../../components/Global/Footer";

ReactGA.initialize("G-0LZ3Y64TPB");

export default function ResultsView() {
  const navigate = useNavigate()

  ReactGA.send({ hitType: "pageview", page: (window.location.pathname + window.location.search) });

  // State

  const [isShowingMenu, setIsShowingMenu] = useState(false)
  const [isShowingEmailCapture, setIsShowingEmailCapture] = useState(false)
  const [hasShownEmailCapture, setHasShownEmailCapture] = useState(false)

  // API

  const { called, loading, data } = useQuery(CURRENT_USER)
  const [seeEmailModal, seeEmailModalRes] = useMutation(SEE_EMAIL_MODAL)
  const [subscribe, subscribeRes] = useMutation(SUBSCRIBE)

  // API <-> State Interaction

  const currentUser = data?.currentUser?.user
  const token = currentUser?.token
  if (token) {
    localStorage.setItem('token', token)
  }

  if (currentUser && !currentUser.skippedDemographics && !currentUser.demographics) {
    navigate('/loveprint')
  }

  const response = currentUser?.assessmentResponse
  let gradientBottom = "transparent"
  if ((response?.resultQualitative?.length ?? 0) > 0) {
    gradientBottom = secondaryBgColor(response.resultQualitative).replace('bg-[', '').replace(']', '')
  }

  const assignedEmailLoc = currentUser?.assignedEmailLoc ?? 'no-loc'
  const [isSubscribed, setIsSubscribed] = useState(false)
  if (!isSubscribed && (currentUser?.subscribedAt ?? false)) {
    setIsSubscribed(true)
  }

  if (
    currentUser &&
    currentUser.subscribedAt == null &&
    assignedEmailLoc == 'loc-2' &&
    !isShowingEmailCapture &&
    !hasShownEmailCapture
  ) {
    setIsShowingEmailCapture(true)
    seeEmailModal({
      refetchQueries: [
        { query: CURRENT_USER }
      ]
    })
    setHasShownEmailCapture(true)
  }

  if (called && !loading && !(response?.isComplete ?? false)) {
    navigate('/loveprint')
  }

  if (response && response.isComplete) {
    ReactGA.event({
      category: 'Results Page',
      action: 'Results Page',
      label: response.resultQualitative + " " + response.resultQuantitative
    })
  }

  // Render

  return (
    <div className="bg-[#F2F2F2]">
      <div className="z-20 fixed bg-[#F2F2F2]">
        <Nav inAssessment={true} isHamburgerOpen={isShowingMenu} onHamburgerClick={() => {
          setIsShowingMenu(!isShowingMenu)
        }} onNectarClick={() => {
          navigate('/')
        }} />
      </div>

      {isShowingEmailCapture &&
        <AssessmentEmailCapture loc={'Results'} copy={<span>sign up to see your <span style={{ fontFamily: "Garamond Premier Pro" }} className="italic">loveprint</span> results</span>} wall={true} blur={true} onClick={() => {
          setIsShowingEmailCapture(false)
        }} onSubscribe={(email) => {
          setIsShowingEmailCapture(false)
          subscribe({
            variables: {
              email: email,
              fromHome: false
            }, refetchQueries: [{ query: CURRENT_USER }]
          })
          ReactGA.event({
            category: 'Email Subscription',
            action: 'Email Subscription',
            label: email
          })
        }} setIsShowing={setIsShowingEmailCapture} />
      }

      {isShowingMenu &&
        <HamburgerMenu inAssessment={true} />
      }

      {!isShowingMenu && !loading && called && (response?.isComplete ?? false) &&
        <div className="z-[10] hidden 2xxmd:flex flex-row gap-[75px] md:gap-[7.5%] lg:gap-[155px] md:px-[90px] pt-[86px] mt-[70px] w-full items-start">
          <ResultsDetail response={response} />
          <ResultMap result={response.resultQualitative} resultQuantitative={response.resultQuantitative} progress1={response.subscale1Progress} progress2={response.subscale2Progress} progress3={response.subscale3Progress} progress4={response.subscale4Progress} />
        </div>
      }

      {!isShowingMenu && !loading && called && (response?.isComplete ?? false) &&
        <div className="w-full hidden 2xxmd:block z-[10]">
          <Footer hasSubscribed={isSubscribed} onSubscribeClick={(email) => {
            setIsSubscribed(true)
            subscribe({
              variables: {
                email: email,
                fromHome: true
              }
            })
            ReactGA.event({
              category: 'Email Subscription - Results Page Footer',
              action: 'Email Subscription  - Results Page Footer',
              label: email
            })
          }} />
        </div>
      }

      {!isShowingMenu && !loading && called && response && (response?.isComplete ?? false) &&
        <div className="z-[10] 2xxmd:hidden pt-[86px] mt-[70px] h-[calc(100vh_-_70px)] w-full">
          <div className="z-[10] px-[18px] sm:px-[25px] md:px-[45px] xmd:px-[55px] xxmd:px-[65px] ">
            <ResultsDetail response={response} />
          </div>
          <Footer hasSubscribed={isSubscribed} onSubscribeClick={(email) => {
            setIsSubscribed(true)
            subscribe({
              variables: {
                email: email,
                fromHome: true
              }
            })
            ReactGA.event({
              category: 'Email Subscription - Results Page Footer',
              action: 'Email Subscription - Results Page Footer',
              label: email
            })
          }} />
        </div>
      }

      {!isShowingMenu && loading &&
        <div className="h-[100vh] w-full flex flex-col justify-center items-center">
          <ClipLoader speedMultiplier={0.75} />
        </div>
      }

      {!isShowingMenu && response &&
        <div className="z-[0] pointer-events-none bottom-0 fixed w-full h-[25%] max-h-[320px]" style={{ background: `linear-gradient(to top, ${gradientBottom + "E6"}, ${gradientBottom + "80"}, transparent)` }} />
      }
    </div>
  )
}