import React from "react";
import Footer from "../components/Global/Footer";
import Nav from "../components/Global/Nav";
import Magnifier from "../lib/react-magnifier";
import EmailCaptureModal from "../components/Global/EmailCaptureModal";
import HamburgerMenu from "../components/Global/HamburgerMenu";
import { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { useQuery, useMutation, gql } from '@apollo/client';
import { CURRENT_USER, SUBSCRIBE } from "../api/api";
import AssessmentEmailCapture from "../components/Global/AssessmentEmailCapture";

ReactGA.initialize("G-0LZ3Y64TPB");

export default function HomeView() {
  ReactGA.send({ hitType: "pageview", page: (window.location.pathname + window.location.search) });

  useEffect(() => {
    setTimeout(() => {
      if (!isSubscribed && currentUser?.subscribedAt == null) {
        setIsShowingEmailCapture(true)
      }
    }, 8000)
  }, [])

  // State

  const [isShowingBehindMobile, setIsShowingBehindMobile] = useState(false)
  const [isShowingMenu, setIsShowingMenu] = useState(false)
  const [isShowingEmailCapture, setIsShowingEmailCapture] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [hasSetInitialState, setHasSetInitialState] = useState(false)

  // API

  const { data, error, loading, called } = useQuery(CURRENT_USER, {
    fetchPolicy: 'network-only'
  });
  const [subscribe, _] = useMutation(SUBSCRIBE);
  const currentUser = data?.currentUser.user

  const token = currentUser?.token
  if (token) {
    localStorage.setItem('token', token)
  }

  // API <-> State Interaction

  if (!loading && called && currentUser?.subscribedAt != null && !hasSetInitialState) {
    setHasSetInitialState(true)
    setIsSubscribed(true)
  }

  // Render

  return (
    <div className="bg-[#F2F2F2]">
      {isShowingEmailCapture && <EmailCaptureModal isSubscribed={false} setIsShowing={setIsShowingEmailCapture} onClick={() => {
        setIsShowingEmailCapture(!isShowingEmailCapture)
      }} onSubscribe={(email) => {
        setIsSubscribed(true)
        setIsShowingEmailCapture(false)
        subscribe({
          variables: {
            email: email,
            fromHome: true
          }, refetchQueries: [{ query: CURRENT_USER }]
        })
        ReactGA.event({
          category: 'Email Subscription - Home Modal',
          action: 'Email Subscription - Home Modal',
          label: email
        })
      }} />}
      <div className="z-20 fixed bg-[#F2F2F2]">
        <Nav isHamburgerOpen={isShowingMenu} onHamburgerClick={() => {
          setIsShowingMenu(!isShowingMenu)
        }} />
      </div>
      {isShowingMenu && <HamburgerMenu inAssessment={false} />}
      {!isShowingMenu &&
        <>
          <div className={"reg:min-h-[100vh] w-full z-50 bg-[#F2F2F2] " + (isShowingBehindMobile ? "block reg:hidden" : "hidden")}>
            <div className="pt-[150px] h-[100%_-_150px] hidden reg:block">
              <div className="hidden lg:block">
                <Magnifier src={require("../assets/images/home-text.png")} zoomImgSrc={require("../assets/images/home-text-behind-transparent.png")} mgWidth={370} mgHeight={370} mgBorderWidth={1} />
              </div>
              <div className="hidden xxmd:block lg:hidden">
                <Magnifier src={require("../assets/images/home-text.png")} zoomImgSrc={require("../assets/images/home-text-behind-transparent.png")} mgWidth={260} mgHeight={260} mgBorderWidth={1} />
              </div>
              <div className="hidden xmd:block xxmd:hidden">
                <Magnifier src={require("../assets/images/home-text.png")} zoomImgSrc={require("../assets/images/home-text-behind-transparent.png")} mgWidth={150} mgHeight={150} mgBorderWidth={1} />
              </div>
              <div className="hidden md:block xmd:hidden">
                <Magnifier src={require("../assets/images/home-text.png")} zoomImgSrc={require("../assets/images/home-text-behind-transparent.png")} mgWidth={70} mgHeight={70} mgBorderWidth={1} />
              </div>
              <div className="hidden reg:block md:hidden">
                <Magnifier src={require("../assets/images/home-text.png")} zoomImgSrc={require("../assets/images/home-text-behind-transparent.png")} mgWidth={30} mgHeight={30} mgBorderWidth={1} />
              </div>
            </div>
            <div className="pl-[30px] pt-[146px] h-[100%_-_146px] display reg:hidden cursor-pointer" onClick={() => {
              setIsShowingBehindMobile(!isShowingBehindMobile)
            }}>
              <span className="text-[#1F1125] opacity-50 font-normal text-base reg:hidden">tap the screen to {isShowingBehindMobile ? "un" : ""}reveal me</span>
              <img className={"reg:hidden mt-4 " + (isShowingBehindMobile ? "hidden" : "block")} src={require("../assets/images/home-text-mobile.png")} />
              <img className={"reg:hidden mt-4 pb-4 " + (isShowingBehindMobile ? "block" : "hidden")} src={require("../assets/images/home-text-behind-transparent-mobile.png")} />
            </div>
          </div>

          <div className={"reg:min-h-[100vh] w-full z-50 bg-[#F2F2F2] animate-nectar-bg " + (isShowingBehindMobile ? "hidden reg:block" : "")} style={{
            background: "radial-gradient(circle at top, #F2F2F2, #F2F2F2, #F2F2F2, #FF936A, #F23900C7, #f23900, #f23900, #FF936A, #E2A8C6, #E2A8C6, #E2A8C66E)",
            backgroundSize: "235% 235%",
            WebkitAnimation: "Gradient 17s ease infinite",
            MozAnimation: "Gradient 17s ease infinite",
            animation: "Gradient 17s ease infinite"
          }}>
            <div className="pt-[150px] h-[100%_-_150px] hidden reg:block">
              <div className="hidden lg:block">
                <Magnifier src={require("../assets/images/home-text.png")} zoomImgSrc={require("../assets/images/home-text-behind-transparent.png")} mgWidth={215} mgHeight={215} mgBorderWidth={1} />
              </div>
              <div className="hidden xxmd:block lg:hidden">
                <Magnifier src={require("../assets/images/home-text.png")} zoomImgSrc={require("../assets/images/home-text-behind-transparent.png")} mgWidth={190} mgHeight={190} mgBorderWidth={1} />
              </div>
              <div className="hidden xmd:block xxmd:hidden">
                <Magnifier src={require("../assets/images/home-text.png")} zoomImgSrc={require("../assets/images/home-text-behind-transparent.png")} mgWidth={170} mgHeight={170} mgBorderWidth={1} />
              </div>
              <div className="hidden md:block xmd:hidden">
                <Magnifier src={require("../assets/images/home-text.png")} zoomImgSrc={require("../assets/images/home-text-behind-transparent.png")} mgWidth={150} mgHeight={150} mgBorderWidth={1} />
              </div>
              <div className="hidden reg:block md:hidden">
                <Magnifier src={require("../assets/images/home-text.png")} zoomImgSrc={require("../assets/images/home-text-behind-transparent.png")} mgWidth={130} mgHeight={130} mgBorderWidth={1} />
              </div>
            </div>
            <div className="pl-[30px] pt-[146px] h-[100%_-_146px] display reg:hidden cursor-pointer" onClick={() => {
              setIsShowingBehindMobile(!isShowingBehindMobile)
            }}>
              <span className="text-[#1F1125] opacity-50 font-normal text-base reg:hidden">tap the screen to {isShowingBehindMobile ? "un" : ""}reveal me</span>
              <img className={"reg:hidden mt-4 " + (isShowingBehindMobile ? "hidden" : "block")} src={require("../assets/images/home-text-mobile.png")} />
              <img className={"reg:hidden mt-4 pb-4 " + (isShowingBehindMobile ? "block" : "hidden")} src={require("../assets/images/home-text-behind-transparent-mobile.png")} />
            </div>
          </div>

          <Footer hasSubscribed={isSubscribed} onSubscribeClick={(email) => {
            setIsSubscribed(true)
            subscribe({
              variables: {
                email: email,
                fromHome: true
              }
            })
            ReactGA.event({
              category: 'Email Subscription - Home Footer',
              action: 'Email Subscription - Home Footer',
              label: email
            })
          }} />
        </>
      }
    </div>
  );
}
