import classNames from 'classnames';
import React, { ReactNode, useState } from 'react'
import { MouseEventHandler, useEffect, useRef } from "react";
import Input from './Input';
import ReactGA from 'react-ga4';

ReactGA.initialize("G-0LZ3Y64TPB");

export default function AssessmentEmailCapture({
  onClick,
  onSubscribe,
  setIsShowing,
  wall,
  blur,
  copy,
  loc
}: {
  onClick: (() => void),
  onSubscribe: (email: string) => void,
  setIsShowing: React.Dispatch<React.SetStateAction<boolean>>,
  wall?: boolean,
  blur?: boolean,
  copy: ReactNode | string,
  loc?: string
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [email, setEmail] = useState("")

  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

  ReactGA.event({
    category: 'Assessment Email Capture',
    action: 'Assessment Email Capture',
    label: loc
  })

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (!wall) {
          setIsShowing(false)
          ReactGA.event({
            category: 'Assesment Email Capture - Closed',
            action: 'Assessment Email Capture - Closed',
            label: loc
          })
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  // Render

  return (
    <div className={classNames(
      "min-h-[100vh] w-full fixed inset-0 z-[70] overflow-y-auto", {
      "bg-black/50": !blur,
      "bg-black/30": blur
    })}
      style={{
        backdropFilter: blur ? "blur(10px)" : undefined,
        WebkitBackdropFilter: blur ? "blur(10px)" : undefined
      }}>
      <div className="z-[80] flex min-h-full justify-center text-center items-center p-0">
        <div className="max-w-[400px] bg-white mx-[15px] sm:mx-0 px-[25px] md:px-[30px] rounded-[12px] border-[#F23900] border-[2.5px]">
          <div className={classNames("ml-auto flex flex-row justify-end pt-7 cursor-pointer", {
            "hidden": wall
          })} onClick={() => {
            ReactGA.event({
              category: 'Assesment Email Capture - Closed',
              action: 'Assessment Email Capture - Closed',
              label: loc
            });
            onClick()
          }}>
            <svg width="22" height="22" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L20.4439 20.4439" stroke="#1F1125" stroke-width="2.5" />
              <path d="M21 1.55615L1.55609 21.0001" stroke="#1F1125" stroke-width="2.5" />
            </svg>
          </div>
          <div className={classNames(
            "flex flex-col justify-center w-full h-full ssm:flex-none", {
            "pt-[20px]": wall
          }
          )}>
            <div className={"flex flex-col items-center h-full max-h-[400px] ssm:max-h-full ssm:justify-center gap-4 py-[80px] md:py-[100px] justify-center"}>
              <span className="text-[#1F1125] font-black leading-[2.4rem] text-[32px] mb-3 ssm:mb-4 md:mb-7 text-center">{copy}</span>
              <Input value={email} isEmail={true} onInput={(e) => setEmail(e)} placeholder="enter your email" useRainbowBorder={false} isDarkMode={true} />
              <button className='h-[42px] w-full rounded-full bg-[#201126] flex justify-center items-center' onClick={() => {
                ReactGA.event({
                  category: 'Assessment Email Capture - Subscription',
                  action: 'Assessment Email Capture - Subscription',
                  label: loc
                })
                onSubscribe(email)
              }}><span className='text-white text-[16px]'>sign up</span></button>
              <span className="text-[#1F1125] pt-[15px] font-black text-2xl">love, <span className="text-[#F23900] font-black text-2xl">nectar</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}