import React from 'react'
import Instagram from "../../assets/images/instagram-icon.svg"
import Twitter from "../../assets/images/twitter-icon.svg"
import SMS from "../../assets/images/sms-icon.svg"
import Download from "../../assets/images/download-icon.svg"
import ReactGA from 'react-ga4';

ReactGA.initialize("G-0LZ3Y64TPB");

export default function ShareResults({
  result
}: {
  result: string
}) {
  const shareCopy = encodeURI(`my nectar loveprint is ${result}. what’s yours? https://lovecommanectar.co/3UfGrDZ!`)
  return (
    <div className={"rounded-[30px] font-medium nectar-results-share-gradient-border px-[26px] xxmd:px-[30px] py-[11px] inline-block"}>
      <div className='flex flex-row  gap-[20px] md:gap-[24px] xxmd:gap-[28px] items-center'>
        <span className='leading-[140%] text-[#1F1125] font-bold text-[18px] xxmd:text-[20px]'>share your result:</span>
        <img src={Twitter} className="cursor-pointer inline" onClick={() => {
          const twitterUrl = `https://twitter.com/intent/tweet?text=${shareCopy}`
          window.open(twitterUrl, '_blank')

          ReactGA.event({
            category: 'Share',
            action: 'Twitter Share',
            label: 'Twitter Share'
          })
        }} />
        <img onClick={() => {
          window.open(`sms://?&body=${shareCopy}`, '_blank')

          ReactGA.event({
            category: 'Share',
            action: 'SMS Share'
          })
        }} src={SMS} className="cursor-pointer inline" />
        <img onClick={() => {
          // Generate an anchor tag with our URL,
          // click it, then remove it immediately
          // from the DOM
          const a = document.createElement("a");
          a.href = "/share/nectar-loveprint-" + result.toUpperCase() + ".png"
          a.download = "";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          ReactGA.event({
            category: 'Share',
            action: 'Image Download'
          })
        }} src={Download} className="cursor-pointer inline" />
      </div>
    </div>
  )
}