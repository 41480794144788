import classNames from "classnames";
import React from "react";
import { MouseEventHandler, ReactNode } from "react";

export default function Button({
  title,
  onClick,
  isDarkMode,
  disabled
}: {
  title: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isDarkMode: Boolean;
  disabled?: boolean
}) {
  return (
    <button type="button" disabled={disabled} onClick={onClick} className={classNames(
      "flex justify-center items-center w-full rounded-100 font-bold text-lg leading-6 h-10 align-middle ", {
      "bg-[#1F1125] text-[#F2F2F2]": isDarkMode,
      "bg-white text-[#1F1125]": !isDarkMode,
      "opacity-70": disabled
    })}>
      <span>{title}</span>
    </button>
  );
}
