import React from "react";
import { ReactNode, MouseEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom"
import Splash from "./../../assets/images/splash.png"

export default function Nav({
  onHamburgerClick,
  isHamburgerOpen,
  onNectarClick,
  inAssessment
}: {
  onHamburgerClick?: MouseEventHandler<HTMLDivElement>,
  isHamburgerOpen: boolean,
  onNectarClick?: MouseEventHandler<HTMLDivElement>,
  inAssessment?: boolean
}) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full items-stretch fixed left-0 top-0">
      {!inAssessment && (
        <div
          className="reg:hidden flex flex-col justify-center items-center bg-[#1F1125] h-[36px] flex-grow"
          onClick={onHamburgerClick}
        >
          <span className="text-white text-[15px] font-normal">
            what’s your loveprint?{" "}
            <span
              className="underline font-bold cursor-pointer"
              onClick={() => {
                navigate("/loveprint");
              }}
            >
              take the quiz.
            </span>
          </span>
        </div>
      )}
      <div className={"bg-[#F2F2F2] px-8 flex-grow border-b-2 border-[#1F1125] py-[20px]"}>
        <div>
          <div className="flex flex-row justify-between items-center">
            <span
              className={
                "mt-[10px] float-left text-[#F23900] font-black text-2xl " +
                (onNectarClick != null ? "cursor-pointer" : "")
              }
              onClick={onNectarClick}
            >
              nectar
            </span>
            <div className="float-right md:flex md:flex-row items-center gap-8 pt-[4.5px]">
              <div className={"float-right md:flex md:flex-row items-center gap-8 pt-[4.5px] " + (inAssessment ? "hidden md:hidden" : "hidden md:display")}>
                <span className="text-[#1F1125] font-normal text-[15px] cursor-pointer" onClick={() => { window.open("https://lovecommanectar.co/3BtpLAv") }}>youtube</span>
                <span className="text-[#1F1125] font-normal text-[15px] cursor-pointer" onClick={() => { window.open("https://lovecommanectar.co/3f22G0l") }}>instagram</span>
                <span className="text-[#1F1125] font-normal text-[15px] cursor-pointer" onClick={() => { window.open("https://lovecommanectar.co/3qRpr9W") }}>tiktok</span>
                <span className="text-[#1F1125] font-normal text-[15px] cursor-pointer" onClick={() => { window.open("https://lovecommanectar.co/3do1d47") }}>twitter</span>
                <span
                  className="text-[#1F1125] font-bold text-[15px] cursor-pointer"
                  onClick={() => { navigate('/loveprint') }}
                >
                  take loveprint
                </span>
                <div
                  className="border-[1.5px] border-black rounded-[21px] px-[21px] py-[8.5px] cursor-pointer flex flex-col justify-center items-center"
                  style={{ backgroundImage: `url(${Splash})`, backgroundSize: 'cover', backgroundPosition: 'bottom' }}
                  onClick={() => { window.open('https://apps.apple.com/us/app/nectar-love-compatibility/id6476018063', '_blank') }}
                >
                  <span className="text-white font-bold text-[15px] cursor-pointer"
                    onClick={() => { window.open('https://apps.apple.com/us/app/nectar-love-compatibility/id6476018063', '_blank') }}>
                    get the nectar app
                  </span>
                </div>
              </div>
            </div>
            <div className="float-right flex flex-row items-center gap-8 pt-[4.5px] md:hidden">
              <div
                className="cursor-pointer border-[1.5px] border-black rounded-[21px] px-[21px] py-[8.5px] cursor-pointer flex flex-col justify-center items-center"
                style={{ backgroundImage: `url(${Splash})`, backgroundSize: 'cover', backgroundPosition: 'bottom' }}
                onClick={() => { window.open('https://apps.apple.com/us/app/nectar-love-compatibility/id6476018063', '_blank') }}
              >
                <span className="text-white font-bold text-[15px] cursor-pointer"
                  onClick={() => { window.open('https://apps.apple.com/us/app/nectar-love-compatibility/id6476018063', '_blank') }}>
                  get the app
                </span>
              </div>
              <div className="cursor-pointer" onClick={onHamburgerClick}>
                {!isHamburgerOpen ? (
                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 1H16" stroke="#1F1125" stroke-width="2" />
                    <path d="M0 7H16" stroke="#1F1125" stroke-width="2" />
                    <path d="M0 13H16" stroke="#1F1125" stroke-width="2" />
                  </svg>
                ) : (
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L12.6664 12.6664" stroke="#1F1125" stroke-width="2" />
                    <path d="M13 1.3335L1.33362 12.9999" stroke="#1F1125" stroke-width="2" />
                  </svg>
                )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
