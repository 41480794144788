import React, { ReactNode, useState } from "react"
import { loveprintNumberBucketDescription, loveprintNumberBucketTitle } from "../../../api/dataSource"
import LoveprintNumberChart from "./LoveprintNumberChart"
import LoveprintSectionBar from "./LoveprintSectionBar"

export default function LoveprintSectionMap({
  result,
  mapIcon,
  mapTitle,
  mapCutScore,
  mapSubtotal,
  mapLabelLeft,
  mapLabelRight,
  mapCopyLeft,
  mapCopyRight,
  isLoveprintNumber
}: {
  result: string,
  mapIcon: ReactNode,
  mapTitle: string,
  mapCutScore?: number,
  mapSubtotal?: number,
  mapLabelLeft?: string,
  mapLabelRight?: string,
  mapCopyLeft?: string,
  mapCopyRight?: string,
  isLoveprintNumber: boolean
}) {
  let isRight = false
  switch (result.toLowerCase()) {
    case 'a': {
      isRight = true
      break
    }
    case 'w': {
      isRight = true
      break
    }
    case 'p': {
      isRight = true
      break
    }
    case 'o': {
      isRight = true
      break
    }
  }

  // TODO: wire this up to the user's initial result
  // and/or at least make sure that the side of the
  // cut we are on corresponds to the result from
  // upstream, and have the interaction affect the result
  const [score, setScore] = useState(5)
  const [percentage, setPercentage] = useState(isRight ? 66 : 33)
  const onRightSide = percentage >= 50

  if (!isLoveprintNumber) {
    // Loveprint # custom viewer
    return (
      <div className="flex-col mt-[17px] rounded-[16px] border-[#1F1125] border-[2.5px]">
        <div className="hidden md:block w-[51%] float-left h-full">
          <div className="flex flex-col h-full justify-between items-stretch pt-[30px] px-[30px]">
            <span className="text-[#1F1125] opacity-60">drag the slider to see different results</span>
              <LoveprintSectionBar percentage={percentage} icon={mapIcon} title={mapTitle} labelLeft={mapLabelLeft} labelRight={mapLabelRight} setPercentage={(percentage) => {
                setPercentage(percentage)
              }} />
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="hidden md:flex w-[49%] float-right border-[#1F1125] border-l-[2.5px] px-[30px] py-[36px] flex-col justify-center">
          <div className="flex flex-col gap-[7px]">
            <span className="text-[20px] font-bold text-[#F23900]">{onRightSide ? mapLabelRight : mapLabelLeft}</span>
            <span className="text-[16px] font-normal text-[#1F1125]">{onRightSide ? mapCopyRight : mapCopyLeft}</span>
          </div>
        </div>

        <div className="w-full md:hidden">
          <div className="flex flex-col h-full gap-[16px] md:gap-0 md:justify-between items-stretch pt-[30px] px-[30px] pb-[30px] md:pb-0">
            <span className="text-[#1F1125] opacity-60">drag the slider to see different results</span>
              <LoveprintSectionBar percentage={percentage} icon={mapIcon} title={mapTitle} labelLeft={mapLabelLeft} labelRight={mapLabelRight} setPercentage={(percentage) => {
                setPercentage(percentage)
              }} />
          </div>
        </div>
        <div className="w-full border-[#1F1125] border-t-[2.5px] border-l-[0px] md:border-l-[2.5px] md:border-t-[0px] px-[30px] py-[36px] flex flex-col justify-center  md:hidden">
          <div className="flex flex-col gap-[7px]">
            <span className="text-[20px] font-bold text-[#F23900]">{onRightSide ? mapLabelRight : mapLabelLeft}</span>
            <span className="text-[16px] font-normal text-[#1F1125]">{onRightSide ? mapCopyRight : mapCopyLeft}</span>
          </div>
        </div>
      </div>
    )  
  }

  return (
    <div className="mt-[17px] rounded-[16px] border-[#1F1125] border-[2.5px]">
      <div className="hidden md:block w-[51%] float-left h-full">
        <div className="flex flex-col h-full justify-between items-stretch pt-[30px] px-[30px]">
          <span className="text-[#1F1125] opacity-60">tap to see different results</span>
            <LoveprintNumberChart score={score} setScore={(score) => {
              setScore(score)
            }} icon={mapIcon} title={mapTitle} />
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="hidden md:flex w-[49%] float-right border-[#1F1125] border-l-[2.5px] px-[30px] py-[36px] flex-col justify-center">
        <div className="flex flex-col gap-[7px]">
          <span className="text-[20px] font-bold text-[#F23900]">{loveprintNumberBucketTitle(score)}</span>
          <span className="text-[16px] font-normal text-[#1F1125]">{loveprintNumberBucketDescription(score)}</span>
        </div>
      </div>

      <div className="float-left w-full md:hidden">
        <div className="flex flex-col h-full gap-[16px] md:gap-0 md:justify-between items-stretch pt-[30px] px-[30px] pb-[30px] md:pb-0">
          <span className="text-[#1F1125] opacity-60">click to see different results</span>
          <LoveprintNumberChart score={score} setScore={(score) => {
            setScore(score)
          }} icon={mapIcon} title={mapTitle} />
        </div>
      </div>
      <div className="w-full float-right border-[#1F1125] border-t-[2.5px] border-l-[0px] md:border-l-[2.5px] md:border-t-[0px] px-[30px] py-[36px] flex flex-col justify-center  md:hidden">
        <div className="flex flex-col gap-[7px]">
          <span className="text-[20px] font-bold text-[#F23900]">{loveprintNumberBucketTitle(score)}</span>
          <span className="text-[16px] font-normal text-[#1F1125]">{loveprintNumberBucketDescription(score)}</span>
        </div>
      </div>
    </div>
  )  
}