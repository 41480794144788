import React, { MouseEventHandler } from 'react'

export default function TutorialButton({
  onClick
}: {
  onClick: MouseEventHandler<HTMLDivElement>
}) {
  return (
    <div className='w-full sm:flex sm:justify-center sm:items-center'>
      <div onClick={onClick} className='hidden max-w-[730px] w-full h-[34px] rounded-[28px] sm:flex flex-col justify-center items-center border border-[#F23900] cursor-pointer'>
        <span className="text-[#1F1125] text-[18px] font-[300]">what is this?</span>
      </div>
      <div onClick={onClick} className='flex float-right sm:hidden flex-col justify-center items-center w-[36px] h-[36px] rounded-full border-[2px] border-[#F23900] cursor-pointer'>
        <span className="text-[#1F1125] text-[16px] font-[300]">?</span>
      </div>
    </div>
  )
}