import React, { MouseEventHandler, useState } from 'react'
import classNames from "classnames"
import HeartIcon from "./../../assets/images/heart-icon.svg"
import HeartIconSM from "./../../assets/images/heart-icon-sm.svg"
import HeartIconXS from "./../../assets/images/heart-icon-xs.svg"

export function QuestionResponseSelectorNode({
  isSelected,
  alwaysShowLabel,
  option,
  onClick,
  canInteract
}: {
  isSelected: boolean,
  alwaysShowLabel?: boolean,
  option: string,
  onClick: (() => void),
  canInteract: boolean
}) {
  const isStronglyDisagree = option === "STRONGLY_DISAGREE"
  const isDisagree = option === "DISAGREE"
  const isSomewhatDisagree = option === "SOMEWHAT_DISAGREE"
  const isSomewhatAgree = option === "SOMEWHAT_AGREE"
  const isAgree = option === "AGREE"
  const isStronglyAgree = option === "STRONGLY_AGREE"

  let primaryColor = ""
  if (isStronglyDisagree) {
    primaryColor = "#F23900"
  } else if (isSomewhatDisagree) {
    primaryColor = "#C83107"
  } else if (isDisagree) {
    primaryColor = "#9E290F"
  } else if (isSomewhatDisagree) {
    primaryColor = "#732116"
  } else if (isAgree) {
    primaryColor = "#49191E"
  } else {
    primaryColor = "#1F1125"
  }

  const optionWords = option.toLowerCase().split('_')

  return (
    <div className="flex flex-col items-center gap-[10px] sm:gap-[13px] md:gap-[17.5px]">
      <div className={classNames(`peer aspect-square`, {
        "w-[54.6px] ssm:w-[63px] sm:w-[84px]": isStronglyDisagree || isStronglyAgree,
        "w-[46.8px] ssm:w-[54px] sm:w-[72px]": isDisagree || isAgree,
        "w-[39px] ssm:w-[45px] sm:w-[60px]": isSomewhatDisagree || isSomewhatAgree,
      })}>
        <div onClick={() => {
          if (canInteract) {
            onClick()
          }
        }} className={classNames(`w-full h-full rounded-full flex flex-col justify-center items-center border-[2px] sm:border-[1.75px]`, {
          "cursor-pointer": canInteract,
          
          "hover:bg-[#F23900]": isStronglyDisagree && canInteract,
          "hover:bg-[#C83107]": isDisagree && canInteract,
          "hover:bg-[#9E290F]": isSomewhatDisagree && canInteract,
          "hover:bg-[#732116]": isSomewhatAgree && canInteract,
          "hover:bg-[#49191E]": isAgree && canInteract,
          "hover:bg-[#1F1125]": isStronglyAgree && canInteract,

          "border-[#F23900]": isStronglyDisagree,
          "border-[#C83107]": isDisagree,
          "border-[#9E290F]": isSomewhatDisagree,
          "border-[#732116]": isSomewhatAgree,
          "border-[#49191E]": isAgree,
          "border-[#1F1125]": isStronglyAgree,

          "bg-[#F23900]": isSelected && isStronglyDisagree,
          "bg-[#C83107]": isSelected && isDisagree,
          "bg-[#9E290F]": isSelected && isSomewhatDisagree,
          "bg-[#732116]": isSelected && isSomewhatAgree,
          "bg-[#49191E]": isSelected && isAgree,
          "bg-[#1F1125]": isSelected && isStronglyAgree,
        })}>
          { (isStronglyDisagree || isStronglyAgree) && isSelected && <img src={HeartIcon} className="w-[19px] h-[16px] md:w-[30.33px] md:h-[26px]" /> }
          { (isDisagree || isAgree) && isSelected && <img src={HeartIconSM} className="w-[16px] h-[13px] md:w-[23.33px] md:h-[20px]" /> }
          { (isSomewhatDisagree || isSomewhatAgree) && isSelected && <img src={HeartIconXS} className="w-[11px] h-[10px] md:w-[18.67px] md:h-[16px]" /> }
        </div>
      </div>
      <div className={classNames('w-[20px] flex flex-col items-center h-[38px]', {
        'opacity-0': !alwaysShowLabel && !isSelected,
        'peer-hover:opacity-100': !alwaysShowLabel && !isSelected && canInteract,
        'opacity-100': alwaysShowLabel || isSelected,
      })}>
        <span className="text-[#1F1125] text-[14px] font-bold text-center">{optionWords[0]}</span>
        { optionWords.length > 1 &&
          <span className="text-[#1F1125] text-[14px] font-bold text-center -my-[6px]">{optionWords[1]}</span>
        }
      </div>
    </div>
  )
}