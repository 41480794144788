import React, { ReactNode, useState } from "react"

export default function LoveprintSectionBar({
  icon,
  title,
  labelLeft,
  labelRight,
  percentage,
  setPercentage
}: {
  icon: ReactNode,
  title: string,
  labelLeft: string,
  labelRight: string,
  percentage: number,
  setPercentage: ((percentage: number) => void)
}) {
 return (
  <div className="w-full flex flex-col gap-[12px]">
    <div className="flex flex-row gap-[8px]">
      {icon}
      <span className="text-[#1F1125] text-[16px] font-bold">{title}</span>
    </div>
    <div>
      <input type="range" min="1" max="100" value={percentage} className={`inline-block relative w-full h-[12px] rounded-[20px] bg-[#1F1125] cursor-pointer`} onChange={(e) => {
        setPercentage(Number(e.target.value))
      }}>
      </input>
    </div>
    <div className="flex flex-row justify-between">
      <span className="text-[#1F1125] text-[16px] font-normal">{labelLeft}</span>
      <span className="text-[#1F1125] text-[16px] font-normal">{labelRight}</span>
    </div>
  </div>
 )
}