import { gql } from "@apollo/client";

// We can grab everything we need
// from our User object
export const USER_INFO_FRAGMENT = gql`
  fragment UserInfo on User {
    subscribedAt
    assignedEmailLoc
    assignedDemographicLoc
    token
    email
    sawEmailModalAt
    skippedDemographics
    demographics {
      ageRange
      sexualOrientation
      genderIdentity
      relationshipStatus      
    }
    assessmentResponse {
      id
      isComplete
      assessment {
        id
        orderedQuestions {
          id
          hasReverseCoding
          index
          title
          response {
            response
          }
        }
      }
      resultQualitative
      resultQuantitative
      subscale1Progress
      subscale2Progress
      subscale3Progress
      subscale4Progress
    }
  }
`;