import React from "react";
import classNames from "classnames";
import { MouseEventHandler } from "react";
import Radio from "./Radio";
import RadioText from "./RadioText";

export default function RadioItem({ isSelected, option, onClick }: { isSelected: boolean, option: string, onClick: MouseEventHandler<HTMLDivElement> }) {
  return (
    <div className="flex flex-row gap-[8px] items-center cursor-pointer" onClick={onClick}>
      <Radio isSelected={isSelected} />
      <RadioText title={option} />
    </div>
  )
}
