import React, { ReactNode } from "react"

export default function LoveprintNumberChart({
  score,
  setScore,
  icon,
  title,
}: {
  score: number,
  setScore: ((score: number) => void),
  icon: ReactNode,
  title: string
}) {
  return (
    <div className="w-full flex flex-col gap-[12px]">
      <div className="flex flex-row gap-[8px]">
        {icon}
        <span className="text-[#1F1125] text-[16px] font-bold">{title}</span>
      </div>
      <div className='flex flex-row items-center justify-between'>
        {
          Array.from(Array(10), (_, idx) => {
            if (idx == score - 1) {
              return <div className={`w-[32px] cursor-pointer aspect-square rounded-full bg-[#F23900] flex flex-col justify-center items-center`} onClick={() => {
              }}>
                <span className='text-[16px] text-[#F2F2F2] font-bold'>{score}</span>
              </div>
            } else {
              return <div className={`w-[14px] cursor-pointer aspect-square rounded-full bg-[#1F1125]`} onClick={() => {
                setScore(idx + 1)
              }} />
            }
          })
        }
      </div>
    </div>
  )
}