import React from 'react'
import classNames from "classnames"

export default function QuestionProgressBar({
  progress
}: {
  progress: number
}) {
  return (
    <div className="rounded-[7px] z-[20] bg-[#E4E4E4] w-full h-[7px]">
      <div className={classNames(`z-[30] h-full rounded-full bg-[#1F1125]`)} style={{width: progress * 100 + "%", transition: 'width 1s'}}>
      </div>
    </div>
  )
}