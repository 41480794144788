
import React from "react";
import { useEffect, useRef } from "react";
import HomeView from "./pages/HomeView";
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context';
import { gql } from "@apollo/client";
import { typeDefs } from './api/schema'
import QuestionsView from "./pages/assessment/AssessmentView";
import { Route, Routes, useNavigate } from "react-router";
import AssessmentView from "./pages/assessment/AssessmentView";
import ResultsView from "./pages/assessment/ResultsView";
import Terms from "./pages/legal/Terms";
import Privacy from "./pages/legal/Privacy";
import LoveprintsView from "./pages/assessment/LoveprintsView";
import ScrollToTop from "./lib/ScrollToTop";
import DemographicsFormView from "./pages/DemographicsFormView";

const API_URL = process.env.REACT_APP_API_URL || ''

const cache = new InMemoryCache();
const httpLink = new HttpLink({ uri: API_URL, useGETForQueries: true });

export default function App() {
  const prevLoc = useRef<string>("");

  useEffect(() => {
    const newLoc = window.location.pathname;
    if (newLoc !== prevLoc.current) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });
      prevLoc.current = newLoc;
    }
  });

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });
  
  const client = new ApolloClient({
    cache: cache,
    uri: API_URL,
    typeDefs,
    link: authLink.concat(httpLink)
  })

  return (
    <ApolloProvider client={client}>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/loveprint" element={<AssessmentView />} />
        <Route path="/demographics" element={<DemographicsFormView />} />
        <Route path="/loveprints" element={<LoveprintsView />} />
        <Route path="/results" element={<ResultsView />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </ApolloProvider>
  )
}
