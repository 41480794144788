// Loveprint assessment content source

import React, { ReactNode } from "react"

import CommunicationLogo from "./../assets/images/communication.svg"
import IntimacyLogo from "./../assets/images/intimacy.svg"
import VulnerabilityLogo from "./../assets/images/vulnerability.svg"
import LoveprintNumLogo from "./../assets/images/loveprintnum.svg"
import PartnershipLogo from "./../assets/images/partnership.svg"

export function image(result: string, border?: boolean): string {
  if (border) {
    return "/loveprints/border/" + result.toLowerCase() + "-border" + ".png"
  }
  return "/loveprints/" + result.toLowerCase() + ".svg"
}

export function colorName(result: string): string {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "plum"
    }
    case "aweo": {
      return "pomegranate"
    }
    case "rwpo": {
      return "watermelon"
    }
    case "rweo": {
      return "tangerine"
    }
    case "awpg": {
      return "peach"
    }
    case "aweg": {
      return "sunflower"
    }
    case "aipo": {
      return "willow"
    }
    case "rwpg": {
      return "pistachio"
    }
    case "aieo": {
      return "shamrock"
    }
    case "rweg": {
      return "juniper"
    }
    case "ripo": {
      return "agave"
    }
    case "rieo": {
      return "mint"
    }
    case "aipg": {
      return "blueberry"
    }
    case "aieg": {
      return "iris"
    }
    case "ripg": {
      return "grape"
    }
    case "rieg": {
      return "lavender"
    }
  }
  return ""
}

export function primaryColor(result: string): string {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "text-[#b0698b]"
    }
    case "aweo": {
      return "text-[#983444]"
    }
    case "rwpo": {
      return "text-[#dd4238]"
    }
    case "rweo": {
      return "text-[#f37129]"
    }
    case "awpg": {
      return "text-[#f39965]"
    }
    case "aweg": {
      return "text-[#f4c53e]"
    }
    case "aipo": {
      return "text-[#b5c984]"
    }
    case "rwpg": {
      return "text-[#779e51]"
    }
    case "aieo": {
      return "text-[#46986b]"
    }
    case "rweg": {
      return "text-[#28726d]"
    }
    case "ripo": {
      return "text-[#5ba7ab]"
    }
    case "rieo": {
      return "text-[#9dcad6]"
    }
    case "aipg": {
      return "text-[#518ac6]"
    }
    case "aieg": {
      return "text-[#233c82]"
    }
    case "ripg": {
      return "text-[#6868ac]"
    }
    case "rieg": {
      return "text-[#afa4cd]"
    }
  }
  return ""
}

export function tintColor(result: string): string {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "text-[#d9bbc8]"
    }
    case "aweo": {
      return "text-[#cc9f9b]"
    }
    case "rwpo": {
      return "text-[#f2b29b]"
    }
    case "rweo": {
      return "text-[#fcc29c]"
    }
    case "awpg": {
      return "text-[#fbd3b7]"
    }
    case "aweg": {
      return "text-[#fbe6b1]"
    }
    case "aipo": {
      return "text-[#dfe9c9]"
    }
    case "rwpg": {
      return "text-[#c3d2af]"
    }
    case "aieo": {
      return "text-[#b2ccb9]"
    }
    case "rweg": {
      return "text-[#a2b7b6]"
    }
    case "ripo": {
      return "text-[#bbd8da]"
    }
    case "rieo": {
      return "text-[#d5eaef]"
    }
    case "aipg": {
      return "text-[#b3c5e4]"
    }
    case "aieg": {
      return "text-[#a6b1cd]"
    }
    case "ripg": {
      return "text-[#b8b5d9]"
    }
    case "rieg": {
      return "text-[#dbd7e7]"
    }
  }
  return ""
}

export function primaryBgColor(result: string): string {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "bg-[#b0698b]"
    }
    case "aweo": {
      return "bg-[#983444]"
    }
    case "rwpo": {
      return "bg-[#dd4238]"
    }
    case "rweo": {
      return "bg-[#f37129]"
    }
    case "awpg": {
      return "bg-[#f39965]"
    }
    case "aweg": {
      return "bg-[#f4c53e]"
    }
    case "aipo": {
      return "bg-[#b5c984]"
    }
    case "rwpg": {
      return "bg-[#779e51]"
    }
    case "aieo": {
      return "bg-[#46986b]"
    }
    case "rweg": {
      return "bg-[#28726d]"
    }
    case "ripo": {
      return "bg-[#5ba7ab]"
    }
    case "rieo": {
      return "bg-[#9dcad6]"
    }
    case "aipg": {
      return "bg-[#518ac6]"
    }
    case "aieg": {
      return "bg-[#233c82]"
    }
    case "ripg": {
      return "bg-[#6868ac]"
    }
    case "rieg": {
      return "bg-[#afa4cd]"
    }
  }
  return ""
}

export function secondaryBgColor(result: string): string {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "bg-[#d9bbc8]"
    }
    case "aweo": {
      return "bg-[#cc9f9b]"
    }
    case "rwpo": {
      return "bg-[#f2b29b]"
    }
    case "rweo": {
      return "bg-[#fcc29c]"
    }
    case "awpg": {
      return "bg-[#fbd3b7]"
    }
    case "aweg": {
      return "bg-[#fbe6b1]"
    }
    case "aipo": {
      return "bg-[#dfe9c9]"
    }
    case "rwpg": {
      return "bg-[#c3d2af]"
    }
    case "aieo": {
      return "bg-[#b2ccb9]"
    }
    case "rweg": {
      return "bg-[#a2b7b6]"
    }
    case "ripo": {
      return "bg-[#bbd8da]"
    }
    case "rieo": {
      return "bg-[#d5eaef]"
    }
    case "aipg": {
      return "bg-[#b3c5e4]"
    }
    case "aieg": {
      return "bg-[#a6b1cd]"
    }
    case "ripg": {
      return "bg-[#b8b5d9]"
    }
    case "rieg": {
      return "bg-[#dbd7e7]"
    }
  }
  return ""
}

export function youAre(result: string) {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "active · a “we” person · physical · open"
    }
    case "aweo": {
      return "active · a “we” person · emotional · open"
    }
    case "rwpo": {
      return "reflective · a “we” person · physical · open"
    }
    case "rweo": {
      return "reflective · a “we” person · emotional · open"
    }
    case "awpg": {
      return "active · a “we” person · physical · guarded"
    }
    case "aweg": {
      return "active · a “we” person · emotional · guarded"
    }
    case "aipo": {
      return "active · an “i” person · physical · open"
    }
    case "rwpg": {
      return "reflective · a “we” person · physical · guarded"
    }
    case "aieo": {
      return "active · an “i” person · emotional · open"
    }
    case "rweg": {
      return "reflective · a “we” person · emotional · guarded"
    }
    case "ripo": {
      return "reflective · an “i” person · physical · open"
    }
    case "rieo": {
      return "reflective · an “i” person · emotional · open"
    }
    case "aipg": {
      return "active · an “i” person · physical · guarded"
    }
    case "aieg": {
      return "active · an “i” person · emotional · guarded"
    }
    case "ripg": {
      return "reflective · an “i” person · physical · guarded"
    }
    case "rieg": {
      return "reflective · an “i” person · emotional · guarded"
    }
  }
  return ""
}

export function yourMotto(result: string) {
  switch (result.toLowerCase()) {
    case "awpo": {
      return '“love has changed the way i think about everything.”'
    }
    case "aweo": {
      return '“we would go great together.”'
    }
    case "rwpo": {
      return '“i’ll pick you up on the way there.”'
    }
    case "rweo": {
      return '“foundation first and foremost.”'
    }
    case "awpg": {
      return '“a little mystery is sexy.”'
    }
    case "aweg": {
      return '“let’s grow together.”'
    }
    case "aipo": {
      return '“i need space but not too much.”'
    }
    case "rwpg": {
      return '“there are no words.”'
    }
    case "aieo": {
      return '“i love spending time with you.”'
    }
    case "rweg": {
      return '“let’s actually connect.”'
    }
    case "ripo": {
      return '“introspective vibes.”'
    }
    case "rieo": {
      return '“an open(ish) book.”'
    }
    case "aipg": {
      return '“i contain multitudes.”'
    }
    case "aieg": {
      return '“trust is earned.”'
    }
    case "ripg": {
      return '“give me a teeeeeeny bit of space.”'
    }
    case "rieg": {
      return '“love with caution.”'
    }
  }
  return ""
}

export function loveprintNumberShortDescription(number: number) {
  switch (number) {
    case 1: {
      return 'you’re not prioritizing a relationship right now'
    }
    case 2: {
      return 'you’re not prioritizing a relationship right now'
    }
    case 3: {
      return 'you’re not prioritizing a relationship right now'
    }
    case 4: {
      return 'you’re prioritizing making connections with people'
    }
    case 5: {
      return 'you’re prioritizing making connections with people'
    }
    case 6: {
      return 'you’re prioritizing making connections with people'
    }
    case 7: {
      return 'you’re prioritizing making connections with people'
    }
    case 8: {
      return 'you’re prioritizing bonding with a partner'
    }
    case 9: {
      return 'you’re prioritizing bonding with a partner'
    }
    case 10: {
      return 'you’re prioritizing bonding with a partner'
    }
  }
  return ""
}

export function loveprintNumberDescription(number: number) {
  switch (number) {
    case 1: {
      return "you’re not really looking for anything in particular. you're seeking to discover whatever life has to give, your priority being the work you’re doing on yourself, or your job, or school, or family, as you figure out what you want. you’re just going with the moment."
    }
    case 2: {
      return "you’re not really looking for anything in particular. you're seeking to discover whatever life has to give, your priority being the work you’re doing on yourself, or your job, or school, or family, as you figure out what you want. you’re just going with the moment."
    }
    case 3: {
      return "you’re not really looking for anything in particular. you're seeking to discover whatever life has to give, your priority being the work you’re doing on yourself, or your job, or school, or family, as you figure out what you want. you’re just going with the moment."
    }
    case 4: {
      return "you’re seeking something with substance but not concerned with any promise of permanence. you want something more meaningful than wild fun, so while you’d like to leave things open, your one condition is that, whatever happens, it should matter."
    }
    case 5: {
      return "you’re seeking something with substance but not concerned with any promise of permanence. you want something more meaningful than wild fun, so while you’d like to leave things open, your one condition is that, whatever happens, it should matter."
    }
    case 6: {
      return "you’re seeking something with substance but not concerned with any promise of permanence. you want something more meaningful than wild fun, so while you’d like to leave things open, your one condition is that, whatever happens, it should matter."
    }
    case 7: {
      return "you’re seeking something with substance but not concerned with any promise of permanence. you want something more meaningful than wild fun, so while you’d like to leave things open, your one condition is that, whatever happens, it should matter."
    }
    case 8: {
      return "you’re ready to put forth the time and effort to build a partnership that works. nurturing the bond with your partner to keep it strong is your priority. you realize that while falling in love is easy, staying in love requires dedication and intention, and you think it’s more than worth it."
    }
    case 9: {
      return "you’re ready to put forth the time and effort to build a partnership that works. nurturing the bond with your partner to keep it strong is your priority. you realize that while falling in love is easy, staying in love requires dedication and intention, and you think it’s more than worth it."
    }
    case 10: {
      return "you’re ready to put forth the time and effort to build a partnership that works. nurturing the bond with your partner to keep it strong is your priority. you realize that while falling in love is easy, staying in love requires dedication and intention, and you think it’s more than worth it."
    }
  }
  return ""
}

export function forYou(result: string) {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "you see love as bringing together two separate worlds. it’s all about self-growth through partnership. your approach entails as much verbal communication as it does physical intimacy. you offer total transparency from the beginning, and nurture it as you go along. you believe that’s what allows you and your partner to embrace each other’s flaws, fears, and aspirations in an ongoing journey of mutual support and growth."
    }
    case "aweo": {
      return "you see free-flowing communication coupled with emotional connection as the twin pillars of a thriving relationship. you want to merge your worlds and plan adventures together. it’s all about building a genuine bond, so that even at a distance, you still feel your partner close by—that, for you, is more powerful and desirable than anything a physical act of love could ever provide."
    }
    case "rwpo": {
      return "you believe in weaving two separate walks of life into one shared path with your partner, while finding time apart for reflection. processing your feelings in a solitary breathing space is what brings you back to your partner. you’re transparent about who you are, but you find that the simple act of holding and making love to your partner can say so much more than words."
    }
    case "rweo": {
      return "you believe quality time and a strong emotional connection lay the foundation of a long-lasting partnership. total voluntary transparency is just the gateway to your relationships. you’re careful with words because feelings are sacred, so you need time alone to process what you’d like to communicate with care, especially when a disagreement arises."
    }
    case "awpg": {
      return "you’re all in for the power combo of ongoing dialogue and physical touch. it’s what compels you to share your world with your partner and work toward shared goals—but that doesn’t happen all at once. a little mystery is sexy. the journey of partnership involves the patient discovery of each other’s secret inner lives, which are given permission to unfold as you go along."
    }
    case "aweg": {
      return "relationships for you are a delicate blend of free-flowing communication, quality time, and emotional bonding, all of which you nurture over time. someone earns your trust through shared experiences as you explore each other’s worlds. the closeness you create should be felt even at a distance, as that holds more weight for you than the more physical acts of love."
    }
    case "aipo": {
      return "a partner is an important part of your community, but you cherish having your own hobbies and circle of friends. keeping them separate is what helps you thrive in a relationship. because when you do spend time with your partner, you show them everything about yourself and express your emotions as deeply through touch as you do through conversation."
    }
    case "rwpg": {
      return "your approach to building a life with another is to take things slowly, revealing aspects of your inner world over time, gradually allowing your social circles and hobbies to converge with your partner’s. you process your feelings and thoughts alone before carefully putting them into words, often finding that physical intimacy can express what words cannot."
    }
    case "aieo": {
      return "even in a relationship, you keep aspects of your life separate, such as your social networks and hobbies, but when you do spend time with your partner, you share everything about yourself, offer loving support, and encourage meaningful conversation, as that creates the emotional closeness you value more than physical acts of love."
    }
    case "rweg": {
      return "you value presence and emotional connection above everything. quality time is what builds loving bonds that don’t depend on physical contact or endless talking. you handle words with care and communicate emotions after you’ve processed them. you are also not in a rush to reveal everything. you let that unfold over time through shared experiences and earned trust."
    }
    case "ripo": {
      return "you value personal introspection and physical intimacy over discussion, even though you’re more than willing to express yourself after some reflection. you want to keep parts of your life separate, as a partner will never replace the community you’ve built, but you also love sharing everything about yourself in tandem with getting swept away in the physical acts of love."
    }
    case "rieo": {
      return "in your approach to building a loving relationship, emotional connection and total transparency take precedence over physical intimacy. you’re as open about your life as you are determined to keep aspects of it not private, but separate. you're reserved with words. you wait to discuss important relationship matters after careful introspection."
    }
    case "aipg": {
      return "your approach to relationships is a delicate balancing of opposites. you value verbal communication and physical intimacy as much as your independence and privacy. you need to build trust, but even as you gradually trust your partner, you will prefer to keep some areas of your life separate, such as your circle of friends or hobbies."
    }
    case "aieg": {
      return "your approach to loving entails a delicate balancing of opposites. you value verbal communication and emotional connection with your partner as much as your independence and privacy. building trust takes time, but even as you gradually share the secrets of your life, you still prioritize spending time outside your relationship with your friends and hobbies."
    }
    case "ripg": {
      return "you approach relationships with caution, protecting your independence and privacy. you’re reserved with words and need time alone to process. in fact, physical intimacy is easier for you, it’s a more direct way of bringing down barriers and building trust. but even as you gradually become closer to your partner, you will continue to keep some things separate."
    }
    case "rieg": {
      return "you approach relationships with caution and cherish your independence and privacy. a partner will likely start off as a friend, as you patiently get to know them and build trust, taking frequent timeouts to reflect on your feelings before communicating them. you value emotional connection over physical contact, and even as you gradually get closer to your partner, you prefer to keep some of your hobbies and friends to yourself."
    }
  }
}

export function header1(result: string) {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "you are an active communicator"
    }
    case "aweo": {
      return "you are an active communicator"
    }
    case "rwpo": {
      return "you are a reflective communicator"
    }
    case "rweo": {
      return "you are a reflective communicator"
    }
    case "awpg": {
      return "you are an active communicator"
    }
    case "aweg": {
      return "you are an active communicator"
    }
    case "aipo": {
      return "you are an active communicator"
    }
    case "rwpg": {
      return "you are a reflective communicator"
    }
    case "aieo": {
      return "you are an active communicator"
    }
    case "rweg": {
      return "you are a reflective communicator"
    }
    case "ripo": {
      return "you are a reflective communicator"
    }
    case "rieo": {
      return "you are a reflective communicator"
    }
    case "aipg": {
      return "you are an active communicator"
    }
    case "aieg": {
      return "you are an active communicator"
    }
    case "ripg": {
      return "you are a reflective communicator"
    }
    case "rieg": {
      return "you are a reflective communicator"
    }
  }
}

export function header2(result: string) {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "you are a “we” person"
    }
    case "aweo": {
      return "you are a “we” person"
    }
    case "rwpo": {
      return "you are a “we” person"
    }
    case "rweo": {
      return "you are a “we” person"
    }
    case "awpg": {
      return "you are a “we” person"
    }
    case "aweg": {
      return "you are a “we” person"
    }
    case "aipo": {
      return "you are an “i” person"
    }
    case "rwpg": {
      return "you are a “we” person"
    }
    case "aieo": {
      return "you are an “i” person"
    }
    case "rweg": {
      return "you are a “we” person"
    }
    case "ripo": {
      return "you are an “i” person"
    }
    case "rieo": {
      return "you are an “i” person"
    }
    case "aipg": {
      return "you are an “i” person"
    }
    case "aieg": {
      return "you are an “i” person"
    }
    case "ripg": {
      return "you are an “i” person"
    }
    case "rieg": {
      return "you are an “i” person"
    }
  }
}

export function header3(result: string) {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "you prefer physical intimacy"
    }
    case "aweo": {
      return "you prefer emotional intimacy"
    }
    case "rwpo": {
      return "you prefer physical intimacy"
    }
    case "rweo": {
      return "you prefer emotional intimacy"
    }
    case "awpg": {
      return "you prefer physical intimacy"
    }
    case "aweg": {
      return "you prefer emotional intimacy"
    }
    case "aipo": {
      return "you prefer physical intimacy"
    }
    case "rwpg": {
      return "you prefer physical intimacy"
    }
    case "aieo": {
      return "you prefer emotional intimacy"
    }
    case "rweg": {
      return "you prefer emotional intimacy"
    }
    case "ripo": {
      return "you prefer physical intimacy"
    }
    case "rieo": {
      return "you prefer emotional intimacy"
    }
    case "aipg": {
      return "you prefer physical intimacy"
    }
    case "aieg": {
      return "you prefer emotional intimacy"
    }
    case "ripg": {
      return "you prefer physical intimacy"
    }
    case "rieg": {
      return "you prefer emotional intimacy"
    }
  }
}

export function header4(result: string) {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "you are open"
    }
    case "aweo": {
      return "you are open"
    }
    case "rwpo": {
      return "you are open"
    }
    case "rweo": {
      return "you are open"
    }
    case "awpg": {
      return "you are guarded"
    }
    case "aweg": {
      return "you are guarded"
    }
    case "aipo": {
      return "you are open"
    }
    case "rwpg": {
      return "you are guarded"
    }
    case "aieo": {
      return "you are open"
    }
    case "rweg": {
      return "you are guarded"
    }
    case "ripo": {
      return "you are open"
    }
    case "rieo": {
      return "you are open"
    }
    case "aipg": {
      return "you are guarded"
    }
    case "aieg": {
      return "you are guarded"
    }
    case "ripg": {
      return "you are guarded"
    }
    case "rieg": {
      return "you are guarded"
    }
  }
}

export function paragraph1(result: string) {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "you’re probably not one to shy away from expressing your feelings, directly. moments when you and your partner don’t see eye-to-eye, for you, are priceless opportunities for deeper understanding and getting closer. you think there's no time like the present to work something out. and it’s better to never let the moment get away."
    }
    case "aweo": {
      return "you’re probably not one to shy away from expressing your feelings, directly. moments when you and your partner don’t see eye-to-eye, for you, are priceless opportunities for deeper understanding and getting closer. you think there's no time like the present to work something out. and it’s better to never let the moment get away."
    }
    case "rwpo": {
      return "you need space and time alone to process your thoughts and feelings when there’s a disagreement. you don’t want to just react, you want to intentionally respond. so you take some time to think, no big deal. you gotta feel everything out before you can figure out what you really mean to say."
    }
    case "rweo": {
      return "you need space and time alone to process your thoughts and feelings when there’s a disagreement. you don’t want to just react, you want to intentionally respond. so you take some time to think, no big deal. you gotta feel everything out before you can figure out what you really mean to say."
    }
    case "awpg": {
      return "you’re probably not one to shy away from expressing your feelings, directly. moments when you and your partner don’t see eye-to-eye, for you, are priceless opportunities for deeper understanding and getting closer. you think there's no time like the present to work something out. and it’s better to never let the moment get away."
    }
    case "aweg": {
      return "you’re probably not one to shy away from expressing your feelings, directly. moments when you and your partner don’t see eye-to-eye, for you, are priceless opportunities for deeper understanding and getting closer. you think there's no time like the present to work something out. and it’s better to never let the moment get away."
    }
    case "aipo": {
      return "you’re probably not one to shy away from expressing your feelings, directly. moments when you and your partner don’t see eye-to-eye, for you, are priceless opportunities for deeper understanding and getting closer. you think there's no time like the present to work something out. and it’s better to never let the moment get away."
    }
    case "rwpg": {
      return "you need space and time alone to process your thoughts and feelings when there’s a disagreement. you don’t want to just react, you want to intentionally respond. so you take some time to think, no big deal. you gotta feel everything out before you can figure out what you really mean to say."
    }
    case "aieo": {
      return "you’re probably not one to shy away from expressing your feelings, directly. moments when you and your partner don’t see eye-to-eye, for you, are priceless opportunities for deeper understanding and getting closer. you think there's no time like the present to work something out. and it’s better to never let the moment get away."
    }
    case "rweg": {
      return "you need space and time alone to process your thoughts and feelings when there’s a disagreement. you don’t want to just react, you want to intentionally respond. so you take some time to think, no big deal. you gotta feel everything out before you can figure out what you really mean to say."
    }
    case "ripo": {
      return "you need space and time alone to process your thoughts and feelings when there’s a disagreement. you don’t want to just react, you want to intentionally respond. so you take some time to think, no big deal. you gotta feel everything out before you can figure out what you really mean to say."
    }
    case "rieo": {
      return "you need space and time alone to process your thoughts and feelings when there’s a disagreement. you don’t want to just react, you want to intentionally respond. so you take some time to think, no big deal. you gotta feel everything out before you can figure out what you really mean to say."
    }
    case "aipg": {
      return "you’re probably not one to shy away from expressing your feelings, directly. moments when you and your partner don’t see eye-to-eye, for you, are priceless opportunities for deeper understanding and getting closer. you think there's no time like the present to work something out. and it’s better to never let the moment get away."
    }
    case "aieg": {
      return "you’re probably not one to shy away from expressing your feelings, directly. moments when you and your partner don’t see eye-to-eye, for you, are priceless opportunities for deeper understanding and getting closer. you think there's no time like the present to work something out. and it’s better to never let the moment get away."
    }
    case "ripg": {
      return "you need space and time alone to process your thoughts and feelings when there’s a disagreement. you don’t want to just react, you want to intentionally respond. so you take some time to think, no big deal. you gotta feel everything out before you can figure out what you really mean to say."
    }
    case "rieg": {
      return "you need space and time alone to process your thoughts and feelings when there’s a disagreement. you don’t want to just react, you want to intentionally respond. so you take some time to think, no big deal. you gotta feel everything out before you can figure out what you really mean to say."
    }
  }
}

export function paragraph2(result: string) {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "you want to create shared memories, be in the moment with your partner. exploring your different worlds is how you become a part of each other’s lives. for you, quality time is top-tier important. it comes before everything else, otherwise you might feel like, what’s the point of a partnership, ya know?"
    }
    case "aweo": {
      return "you want to create shared memories, be in the moment with your partner. exploring your different worlds is how you become a part of each other’s lives. for you, quality time is top-tier important. it comes before everything else, otherwise you might feel like, what’s the point of a partnership, ya know?"
    }
    case "rwpo": {
      return "you want to create shared memories, be in the moment with your partner. exploring your different worlds is how you become a part of each other’s lives. for you, quality time is top-tier important. it comes before everything else, otherwise you might feel like, what’s the point of a partnership, ya know?"
    }
    case "rweo": {
      return "you want to create shared memories, be in the moment with your partner. exploring your different worlds is how you become a part of each other’s lives. for you, quality time is top-tier important. it comes before everything else, otherwise you might feel like, what’s the point of a partnership, ya know?"
    }
    case "awpg": {
      return "you want to create shared memories, be in the moment with your partner. exploring your different worlds is how you become a part of each other’s lives. for you, quality time is top-tier important. it comes before everything else, otherwise you might feel like, what’s the point of a partnership, ya know?"
    }
    case "aweg": {
      return "you want to create shared memories, be in the moment with your partner. exploring your different worlds is how you become a part of each other’s lives. for you, quality time is top-tier important. it comes before everything else, otherwise you might feel like, what’s the point of a partnership, ya know?"
    }
    case "aipo": {
      return "you believe nobody completes anyone. you have your own interests, hobbies, and friends, and you might like to keep all that (or some of that) separate—even when you’re together. being in a partnership doesn’t mean you stop being who you are, self-care and all that. you want to make sure you care for yourselves as much as you care for each other."
    }
    case "rwpg": {
      return "you want to create shared memories, be in the moment with your partner. exploring your different worlds is how you become a part of each other’s lives. for you, quality time is top-tier important. it comes before everything else, otherwise you might feel like, what’s the point of a partnership, ya know?"
    }
    case "aieo": {
      return "you believe nobody completes anyone. you have your own interests, hobbies, and friends, and you might like to keep all that (or some of that) separate—even when you’re together. being in a partnership doesn’t mean you stop being who you are, self-care and all that. you want to make sure you care for yourselves as much as you care for each other."
    }
    case "rweg": {
      return "you want to create shared memories, be in the moment with your partner. exploring your different worlds is how you become a part of each other’s lives. for you, quality time is top-tier important. it comes before everything else, otherwise you might feel like, what’s the point of a partnership, ya know?"
    }
    case "ripo": {
      return "you believe nobody completes anyone. you have your own interests, hobbies, and friends, and you might like to keep all that (or some of that) separate—even when you’re together. being in a partnership doesn’t mean you stop being who you are, self-care and all that. you want to make sure you care for yourselves as much as you care for each other."
    }
    case "rieo": {
      return "you believe nobody completes anyone. you have your own interests, hobbies, and friends, and you might like to keep all that (or some of that) separate—even when you’re together. being in a partnership doesn’t mean you stop being who you are, self-care and all that. you want to make sure you care for yourselves as much as you care for each other."
    }
    case "aipg": {
      return "you believe nobody completes anyone. you have your own interests, hobbies, and friends, and you might like to keep all that (or some of that) separate—even when you’re together. being in a partnership doesn’t mean you stop being who you are, self-care and all that. you want to make sure you care for yourselves as much as you care for each other."
    }
    case "aieg": {
      return "you believe nobody completes anyone. you have your own interests, hobbies, and friends, and you might like to keep all that (or some of that) separate—even when you’re together. being in a partnership doesn’t mean you stop being who you are, self-care and all that. you want to make sure you care for yourselves as much as you care for each other."
    }
    case "ripg": {
      return "you believe nobody completes anyone. you have your own interests, hobbies, and friends, and you might like to keep all that (or some of that) separate—even when you’re together. being in a partnership doesn’t mean you stop being who you are, self-care and all that. you want to make sure you care for yourselves as much as you care for each other."
    }
    case "rieg": {
      return "you believe nobody completes anyone. you have your own interests, hobbies, and friends, and you might like to keep all that (or some of that) separate—even when you’re together. being in a partnership doesn’t mean you stop being who you are, self-care and all that. you want to make sure you care for yourselves as much as you care for each other."
    }
  }
}

export function paragraph3(result: string) {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "you see physical contact, like hand holding, back scratches, cuddling, and sex as the most direct way of creating closeness and connection in a relationship. it's what invites you to share who you are and how you feel about who you're with. everything words fail to say you can feel and express with touch."
    }
    case "aweo": {
      return "for you, emotional closeness comes before physical contact. when you feel emotionally connected and are able to share the most intimate parts of yourself with your partner, your desire for them soars. it's all about feeling emotionally safe with your partner, like you can rely on them to be available for your needs, that bonds you."
    }
    case "rwpo": {
      return "you see physical contact, like hand holding, back scratches, cuddling, and sex as the most direct way of creating closeness and connection in a relationship. it's what invites you to share who you are and how you feel about who you're with. everything words fail to say you can feel and express with touch."
    }
    case "rweo": {
      return "for you, emotional closeness comes before physical contact. when you feel emotionally connected and are able to share the most intimate parts of yourself with your partner, your desire for them soars. it's all about feeling emotionally safe with your partner, like you can rely on them to be available for your needs, that bonds you."
    }
    case "awpg": {
      return "you see physical contact, like hand holding, back scratches, cuddling, and sex as the most direct way of creating closeness and connection in a relationship. it's what invites you to share who you are and how you feel about who you're with. everything words fail to say you can feel and express with touch."
    }
    case "aweg": {
      return "for you, emotional closeness comes before physical contact. when you feel emotionally connected and are able to share the most intimate parts of yourself with your partner, your desire for them soars. it's all about feeling emotionally safe with your partner, like you can rely on them to be available for your needs, that bonds you."
    }
    case "aipo": {
      return "you see physical contact, like hand holding, back scratches, cuddling, and sex as the most direct way of creating closeness and connection in a relationship. it's what invites you to share who you are and how you feel about who you're with. everything words fail to say you can feel and express with touch."
    }
    case "rwpg": {
      return "you see physical contact, like hand holding, back scratches, cuddling, and sex as the most direct way of creating closeness and connection in a relationship. it's what invites you to share who you are and how you feel about who you're with. everything words fail to say you can feel and express with touch."
    }
    case "aieo": {
      return "for you, emotional closeness comes before physical contact. when you feel emotionally connected and are able to share the most intimate parts of yourself with your partner, your desire for them soars. it's all about feeling emotionally safe with your partner, like you can rely on them to be available for your needs, that bonds you."
    }
    case "rweg": {
      return "for you, emotional closeness comes before physical contact. when you feel emotionally connected and are able to share the most intimate parts of yourself with your partner, your desire for them soars. it's all about feeling emotionally safe with your partner, like you can rely on them to be available for your needs, that bonds you."
    }
    case "ripo": {
      return "you see physical contact, like hand holding, back scratches, cuddling, and sex as the most direct way of creating closeness and connection in a relationship. it's what invites you to share who you are and how you feel about who you're with. everything words fail to say you can feel and express with touch."
    }
    case "rieo": {
      return "for you, emotional closeness comes before physical contact. when you feel emotionally connected and are able to share the most intimate parts of yourself with your partner, your desire for them soars. it's all about feeling emotionally safe with your partner, like you can rely on them to be available for your needs, that bonds you."
    }
    case "aipg": {
      return "you see physical contact, like hand holding, back scratches, cuddling, and sex as the most direct way of creating closeness and connection in a relationship. it's what invites you to share who you are and how you feel about who you're with. everything words fail to say you can feel and express with touch."
    }
    case "aieg": {
      return "for you, emotional closeness comes before physical contact. when you feel emotionally connected and are able to share the most intimate parts of yourself with your partner, your desire for them soars. it's all about feeling emotionally safe with your partner, like you can rely on them to be available for your needs, that bonds you."
    }
    case "ripg": {
      return "you see physical contact, like hand holding, back scratches, cuddling, and sex as the most direct way of creating closeness and connection in a relationship. it's what invites you to share who you are and how you feel about who you're with. everything words fail to say you can feel and express with touch."
    }
    case "rieg": {
      return "for you, emotional closeness comes before physical contact. when you feel emotionally connected and are able to share the most intimate parts of yourself with your partner, your desire for them soars. it's all about feeling emotionally safe with your partner, like you can rely on them to be available for your needs, that bonds you."
    }
  }
}

export function paragraph4(result: string) {
  switch (result.toLowerCase()) {
    case "awpo": {
      return "you don’t want to hide. you want you and your partners’ strengths and weaknesses out in the open. the full package. the light and the dark. because it’s the only way you can know who you’re with. you prefer to get things out in the open to know if you and your partner are a good fit. why even hold anything back?"
    }
    case "aweo": {
      return "you don’t want to hide. you want you and your partners’ strengths and weaknesses out in the open. the full package. the light and the dark. because it’s the only way you can know who you’re with. you prefer to get things out in the open to know if you and your partner are a good fit. why even hold anything back?"
    }
    case "rwpo": {
      return "you don’t want to hide. you want you and your partners’ strengths and weaknesses out in the open. the full package. the light and the dark. because it’s the only way you can know who you’re with. you prefer to get things out in the open to know if you and your partner are a good fit. why even hold anything back?"
    }
    case "rweo": {
      return "you don’t want to hide. you want you and your partners’ strengths and weaknesses out in the open. the full package. the light and the dark. because it’s the only way you can know who you’re with. you prefer to get things out in the open to know if you and your partner are a good fit. why even hold anything back?"
    }
    case "awpg": {
      return "getting to know someone doesn’t happen all at once. being guarded doesn't necessarily mean you aren't open, it just means that you prefer things to unfold organically. you and your partner can’t just show each other everything. your inner worlds are sacred. to be gradually explored. entrance needs to be earned with trust. and building trust takes… time."
    }
    case "aweg": {
      return "getting to know someone doesn’t happen all at once. being guarded doesn't necessarily mean you aren't open, it just means that you prefer things to unfold organically. you and your partner can’t just show each other everything. your inner worlds are sacred. to be gradually explored. entrance needs to be earned with trust. and building trust takes… time."
    }
    case "aipo": {
      return "you don’t want to hide. you want you and your partners’ strengths and weaknesses out in the open. the full package. the light and the dark. because it’s the only way you can know who you’re with. you prefer to get things out in the open to know if you and your partner are a good fit. why even hold anything back?"
    }
    case "rwpg": {
      return "getting to know someone doesn’t happen all at once. being guarded doesn't necessarily mean you aren't open, it just means that you prefer things to unfold organically. you and your partner can’t just show each other everything. your inner worlds are sacred. to be gradually explored. entrance needs to be earned with trust. and building trust takes… time."
    }
    case "aieo": {
      return "you don’t want to hide. you want you and your partners’ strengths and weaknesses out in the open. the full package. the light and the dark. because it’s the only way you can know who you’re with. you prefer to get things out in the open to know if you and your partner are a good fit. why even hold anything back?"
    }
    case "rweg": {
      return "getting to know someone doesn’t happen all at once. being guarded doesn't necessarily mean you aren't open, it just means that you prefer things to unfold organically. you and your partner can’t just show each other everything. your inner worlds are sacred. to be gradually explored. entrance needs to be earned with trust. and building trust takes… time."
    }
    case "ripo": {
      return "you don’t want to hide. you want you and your partners’ strengths and weaknesses out in the open. the full package. the light and the dark. because it’s the only way you can know who you’re with. you prefer to get things out in the open to know if you and your partner are a good fit. why even hold anything back?"
    }
    case "rieo": {
      return "you don’t want to hide. you want you and your partners’ strengths and weaknesses out in the open. the full package. the light and the dark. because it’s the only way you can know who you’re with. you prefer to get things out in the open to know if you and your partner are a good fit. why even hold anything back?"
    }
    case "aipg": {
      return "getting to know someone doesn’t happen all at once. being guarded doesn't necessarily mean you aren't open, it just means that you prefer things to unfold organically. you and your partner can’t just show each other everything. your inner worlds are sacred. to be gradually explored. entrance needs to be earned with trust. and building trust takes… time."
    }
    case "aieg": {
      return "getting to know someone doesn’t happen all at once. being guarded doesn't necessarily mean you aren't open, it just means that you prefer things to unfold organically. you and your partner can’t just show each other everything. your inner worlds are sacred. to be gradually explored. entrance needs to be earned with trust. and building trust takes… time."
    }
    case "ripg": {
      return "getting to know someone doesn’t happen all at once. being guarded doesn't necessarily mean you aren't open, it just means that you prefer things to unfold organically. you and your partner can’t just show each other everything. your inner worlds are sacred. to be gradually explored. entrance needs to be earned with trust. and building trust takes… time."
    }
    case "rieg": {
      return "getting to know someone doesn’t happen all at once. being guarded doesn't necessarily mean you aren't open, it just means that you prefer things to unfold organically. you and your partner can’t just show each other everything. your inner worlds are sacred. to be gradually explored. entrance needs to be earned with trust. and building trust takes… time."
    }
  }
}

export function mapLeftText(index: number) {
  switch (index) {
    case 0: {
      return 'reflective'
    }
    case 1: {
      return '"i"'
    }
    case 2: {
      return 'emotional'
    }
    case 3: {
      return 'guarded'
    }
  }
}

export function mapRightText(index: number) {
  switch (index) {
    case 0: {
      return 'active'
    }
    case 1: {
      return '"we"'
    }
    case 2: {
      return 'physical'
    }
    case 3: {
      return 'open'
    }
  }
}

export function mapLeftBold(index: number, result: string) {
  switch (index) {
    case 0: {
      return result.toLowerCase().charAt(index) == 'r'
    }
    case 1: {
      return result.toLowerCase().charAt(index) == 'i'
    }
    case 2: {
      return result.toLowerCase().charAt(index) == 'e'
    }
    case 3: {
      return result.toLowerCase().charAt(index) == 'g'
    }
  }
}

export function mapRightBold(index: number, result: string) {
  switch (index) {
    case 0: {
      return result.toLowerCase().charAt(index) == 'a'
    }
    case 1: {
      return result.toLowerCase().charAt(index) == 'w'
    }
    case 2: {
      return result.toLowerCase().charAt(index) == 'p'
    }
    case 3: {
      return result.toLowerCase().charAt(index) == 'o'
    }
  }
}

// Loveprints

export function loveprintSubtitle(result: string) {
  return youAre(result)
}

export function loveprintMapSectionHeader(index: number) {
  switch (index) {
    case 0: {
      return "communication style"
    }
    case 1: {
      return "partnership style"
    }
    case 2: {
      return "intimacy style"
    }
    case 3: {
      return "vulnerability style"
    }
    case 4: {
      return "loveprint number"
    }
  }
}

export function loveprintMapSectionDescription(index: number) {
  switch (index) {
    case 0: {
      return "we’ve gotta talk to each other to love (at least eventually), but everyone communicates differently. understanding your approach to expressing yourself is as important as understanding your partner’s. knowing what’s important to both parties can help you navigate conversations better and give you the language and tools needed to communicate your needs."
    }
    case 1: {
      return "hey, everyone has a different vision of a loving relationship. it could look like bringing your worlds together. it could look like keeping some aspects of your world separate. but no matter what, taking a closer look at how you envision your ideal relationship can help you be on the lookout for those with the same vibe. or different! if you’re looking for a challenge."
    }
    case 2: {
      return "getting intimate is way more than just your sex life. it’s about coming closer and really getting to know each other. some people lean more toward the physical and some more toward the emotional. what comes first for you? physical or emotional attraction? and which one makes you feel closer to someone?"
    }
    case 3: {
      return "people reveal who they are in their own time, and that is totally. completely. ok. are you an open travel-guide type that maps everything out for everyone or are you more of a mystery novel that takes time to unravel? maybe the answer is neither and depends on the person you’re with. but where do you stand on the spectrum?"
    }
    case 4: {
      return "your loveprint number is an estimate, not a fixed calculation. no number is better than another, we’re not giving out grades here, people. it’s always just an invitation for honest introspection. the goal is self-awareness and reflection, not self-consciousness and judgment. you can count on the fact that the number you get will change with time, shifting like the phases of your life."
    }
  }
}

export function loveprintMapTitle(index: number) {
  switch (index) {
    case 0: {
      return "COMMUNICATION"
    }
    case 1: {
      return "PARTNERSHIP"
    }
    case 2: {
      return "INTIMACY"
    }
    case 3: {
      return "VULNERABILITY"
    }
    case 4: {
      return "loveprint #"
    }
  }
}

export function loveprintMapLabelLeft(index: number) {
  switch (index) {
    case 0: {
      return "reflective"
    }
    case 1: {
      return '"i"'
    }
    case 2: {
      return "emotional"
    }
    case 3: {
      return "guarded"
    }
  }
}

export function loveprintMapLabelRight(index: number) {
  switch (index) {
    case 0: {
      return "active"
    }
    case 1: {
      return '"we"'
    }
    case 2: {
      return "physical"
    }
    case 3: {
      return "open"
    }
  }
}

export function loveprintMapLabelLeftCopy(index: number) {
  switch (index) {
    case 0: {
      return "you need space and time alone to process your thoughts and feelings when there’s a disagreement. you don’t want to just react, you want to intentionally respond. so you take some time to think, no big deal. you gotta feel everything out before you can figure out what you really mean to say."
    }
    case 1: {
      return 'you believe nobody completes anyone. you have your own interests, hobbies, and friends, and you might like to keep all that (or some of that) separate—even when you’re together. being in a partnership doesn’t mean you stop being who you are, self-care and all that. you want to make sure you care for yourselves as much as you care for each other.'
    }
    case 2: {
      return "for you, emotional closeness comes before physical contact. when you feel emotionally connected and are able to share the most intimate parts of yourself with your partner, your desire for them soars. it's all about feeling emotionally safe with your partner, like you can rely on them to be available for your needs, that bonds you."
    }
    case 3: {
      return "getting to know someone doesn’t happen all at once. being guarded doesn't necessarily mean you aren't open, it just means that you prefer things to unfold organically. you and your partner can’t just show each other everything. your inner worlds are sacred. to be gradually explored. entrance needs to be earned with trust. and building trust takes… time."
    }
  }
}

export function loveprintMapLabelRightCopy(index: number) {
  switch (index) {
    case 0: {
      return "you’re probably not one to shy away from expressing your feelings, directly. moments when you and your partner don’t see eye-to-eye, for you, are priceless opportunities for deeper understanding and getting closer. you think there's no time like the present to work something out. and it’s better to never let the moment get away."
    }
    case 1: {
      return "you want to create shared memories, be in the moment with your partner. exploring your different worlds is how you become a part of each other’s lives. for you, quality time is top-tier important. it comes before everything else, otherwise you might feel like, what’s the point of a partnership, ya know?"
    }
    case 2: {
      return "you see physical contact, like hand holding, back scratches, cuddling, and sex as the most direct way of creating closeness and connection in a relationship. it's what invites you to share who you are and how you feel about who you're with. everything words fail to say you can feel and express with touch."
    }
    case 3: {
      return "you don’t want to hide. you want you and your partners’ strengths and weaknesses out in the open. the full package. the light and the dark. because it’s the only way you can know who you’re with. you prefer to get things out in the open to know if you and your partner are a good fit. why even hold anything back?"
    }
  }
}

export function loveprintNumberBucketTitle(number: number) {
  if (number <= 3) {
    return "1-3 connecting"
  }
  if (number <= 7) {
    return "4-7 connecting"
  }
  return "8-10 connecting"
}

export function loveprintNumberBucketDescription(number: number) {
  if (number <= 3) {
    return "you’re not really looking for anything in particular. you're seeking to discover whatever life has to give, your priority being the work you’re doing on yourself, or your job, or school, or family, as you figure out what you want. you’re just going with the moment."
  }
  if (number <= 7) {
    return "you’re seeking something with substance but not concerned with any promise of permanence. you want something more meaningful than wild fun, so while you’d like to leave things open, your one condition is that, whatever happens, it should matter."
  }
  return "you’re ready to put forth the time and effort to build a partnership that works. nurturing the bond with your partner to keep it strong is your priority. you realize that while falling in love is easy, staying in love requires dedication and intention, and you think it’s more than worth it."
}

export function loveprintMapLabelIcon(index: number): string {
  switch (index) {
    case 0: {
      return CommunicationLogo
    }
    case 1: {
      return PartnershipLogo
    }
    case 2: {
      return IntimacyLogo
    }
    case 3: {
      return VulnerabilityLogo
    }
    case 4: {
      return LoveprintNumLogo
    }
  }
}