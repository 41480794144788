import React from "react"
import { LoveprintsGridScrollNode } from "./LoveprintsGrid"

export default function LoveprintScrollView({
  result,
  onClickedResult
}: {
  result: string,
  onClickedResult: ((result: string) => void)
}) {
  return (
    <div style={{scrollSnapAlign: "center", scrollSnapType: "x proximity", scrollbarWidth: "none", msOverflowStyle: "none"}} className="px-[20px] py-[62px] md:hidden flex flex-row items-center gap-[26px] overflow-x-scroll whitespace-nowrap flex-shrink-0 w-[100vw]">
      <LoveprintsGridScrollNode result="AWPO" selected={result == "AWPO"} onClick={() => {
        onClickedResult("AWPO")
      }} />
      <LoveprintsGridScrollNode result="AWEO" selected={result == "AWEO"} onClick={() => {
        onClickedResult("AWEO")
      }} />
      <LoveprintsGridScrollNode result="RWPO" selected={result == "RWPO"} onClick={() => {
        onClickedResult("RWPO")
      }} />
      <LoveprintsGridScrollNode result="RWEO" selected={result == "RWEO"} onClick={() => {
        onClickedResult("RWEO")
      }} />
      <LoveprintsGridScrollNode result="AWPG" selected={result == "AWPG"} onClick={() => {
        onClickedResult("AWPG")
      }} />
      <LoveprintsGridScrollNode result="AWEG" selected={result == "AWEG"} onClick={() => {
        onClickedResult("AWEG")
      }} />
      <LoveprintsGridScrollNode result="AIPO" selected={result == "AIPO"} onClick={() => {
        onClickedResult("AIPO")
      }} />
      <LoveprintsGridScrollNode result="RWPG" selected={result == "RWPG"} onClick={() => {
        onClickedResult("RWPG")
      }} />
      <LoveprintsGridScrollNode result="AIEO" selected={result == "AIEO"} onClick={() => {
        onClickedResult("AIEO")
      }} />
      <LoveprintsGridScrollNode result="RWEG" selected={result == "RWEG"} onClick={() => {
        onClickedResult("RWEG")
      }} />
      <LoveprintsGridScrollNode result="RIPO" selected={result == "RIPO"} onClick={() => {
        onClickedResult("RIPO")
      }} />
      <LoveprintsGridScrollNode result="RIEO" selected={result == "RIEO"} onClick={() => {
        onClickedResult("RIEO")
      }} />
      <LoveprintsGridScrollNode result="AIPG" selected={result == "AIPG"} onClick={() => {
        onClickedResult("AIPG")
      }} />
      <LoveprintsGridScrollNode result="AIEG" selected={result == "AIEG"} onClick={() => {
        onClickedResult("AIEG")
      }} />
      <LoveprintsGridScrollNode result="RIPG" selected={result == "RIPG"} onClick={() => {
        onClickedResult("RIPG")
      }} />
      <LoveprintsGridScrollNode result="RIEG" selected={result == "RIEG"} onClick={() => {
        onClickedResult("RIEG")
      }} />
      <div className="opacity-0">
        <LoveprintsGridScrollNode result="RIEG" selected={result == "RIEG"} onClick={() => {
          onClickedResult("RIEG")
        }} />
      </div>
    </div>
  )
}