import React, { MouseEventHandler, useState } from 'react'
import { Question } from '../../generated/graphql';
import { QuestionResponseSelectorNode } from "./QuestionResponseSelectorNode";

export function QuestionResponseSelector({
  onSelect,
  selectedOption,
  canInteract
}: {
  onSelect: (option: string) => void,
  selectedOption: string,
  canInteract: boolean
}) {
  const STRONGLY_DISAGREE = "STRONGLY_DISAGREE"
  const DISAGREE = "DISAGREE"
  const SOMEWHAT_DISAGREE = "SOMEWHAT_DISAGREE"
  const SOMEWHAT_AGREE = "SOMEWHAT_AGREE"
  const AGREE = "AGREE"
  const STRONGLY_AGREE = "STRONGLY_AGREE"

  return (
    <div className="hidden sm:flex flex-row items-center justify-between w-full max-w-[628px]">
      <QuestionResponseSelectorNode canInteract={canInteract} onClick={() => {
        onSelect(STRONGLY_DISAGREE)
      }} alwaysShowLabel={selectedOption == null || selectedOption == STRONGLY_DISAGREE} isSelected={selectedOption == STRONGLY_DISAGREE} option={STRONGLY_DISAGREE} />
      <QuestionResponseSelectorNode canInteract={canInteract} onClick={() => {
        onSelect(DISAGREE)
      }} isSelected={selectedOption == DISAGREE} option={DISAGREE} />
      <QuestionResponseSelectorNode canInteract={canInteract} onClick={() => {
        onSelect(SOMEWHAT_DISAGREE)
      }} isSelected={selectedOption == SOMEWHAT_DISAGREE} option={SOMEWHAT_DISAGREE} />
      <QuestionResponseSelectorNode canInteract={canInteract} onClick={() => {
        onSelect(SOMEWHAT_AGREE)
      }} isSelected={selectedOption == SOMEWHAT_AGREE} option={SOMEWHAT_AGREE} />
      <QuestionResponseSelectorNode canInteract={canInteract} onClick={() => {
        onSelect(AGREE)
      }} isSelected={selectedOption == AGREE} option={AGREE} />
      <QuestionResponseSelectorNode canInteract={canInteract} onClick={() => {
        onSelect(STRONGLY_AGREE)
      }} alwaysShowLabel={selectedOption == null || selectedOption == STRONGLY_AGREE} isSelected={selectedOption == STRONGLY_AGREE} option={STRONGLY_AGREE} />
    </div>
  )
}

export function MobileQuestionResponseSelector({
  onSelect,
  selectedOption,
  canInteract
}: {
  onSelect: (option: string) => void,
  selectedOption: string,
  canInteract: boolean
}) {
  const STRONGLY_DISAGREE = "STRONGLY_DISAGREE"
  const DISAGREE = "DISAGREE"
  const SOMEWHAT_DISAGREE = "SOMEWHAT_DISAGREE"
  const SOMEWHAT_AGREE = "SOMEWHAT_AGREE"
  const AGREE = "AGREE"
  const STRONGLY_AGREE = "STRONGLY_AGREE"

  return (
    <div className="sm:hidden flex flex-row items-center justify-between w-full max-w-[628px] px-[15px]">
      <QuestionResponseSelectorNode canInteract={canInteract} onClick={() => {
        onSelect(STRONGLY_DISAGREE)
      }} alwaysShowLabel={selectedOption == null || selectedOption == STRONGLY_DISAGREE} isSelected={selectedOption == STRONGLY_DISAGREE} option={STRONGLY_DISAGREE} />
      <QuestionResponseSelectorNode canInteract={canInteract} onClick={() => {
        onSelect(DISAGREE)
      }} isSelected={selectedOption == DISAGREE} option={DISAGREE} />
      <QuestionResponseSelectorNode canInteract={canInteract} onClick={() => {
        onSelect(SOMEWHAT_DISAGREE)
      }} isSelected={selectedOption == SOMEWHAT_DISAGREE} option={SOMEWHAT_DISAGREE} />
      <QuestionResponseSelectorNode canInteract={canInteract} onClick={() => {
        onSelect(SOMEWHAT_AGREE)
      }} isSelected={selectedOption == SOMEWHAT_AGREE} option={SOMEWHAT_AGREE} />
      <QuestionResponseSelectorNode canInteract={canInteract} onClick={() => {
        onSelect(AGREE)
      }} isSelected={selectedOption == AGREE} option={AGREE} />
      <QuestionResponseSelectorNode canInteract={canInteract} onClick={() => {
        onSelect(STRONGLY_AGREE)
      }} alwaysShowLabel={selectedOption == null || selectedOption == STRONGLY_AGREE} isSelected={selectedOption == STRONGLY_AGREE} option={STRONGLY_AGREE} />
    </div>
  )
}