import React from "react"
import { useNavigate } from "react-router-dom"
import { colorName, forYou, image, loveprintSubtitle, yourMotto } from "../../api/dataSource"
import { LoveprintsGridScrollNode } from "./Collection/LoveprintsGrid"
import LoveprintScrollView from "./Collection/LoveprintsScrollView"

export default function LoveprintViewer({
  result,
  onClickedResult
}: {
  result: string,
  onClickedResult: ((result: string) => void)
}) {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col pb-[36px] items-stretch md:items-start md:py-[48px] lg:py-[65px] md:pl-[70px] lg:pl-[90px] md:pr-[59px] md:max-w-[500px] lg:max-w-[600px]">
      <img src={image(result)} className="hidden md:block pb-[48px] -ml-[10px] aspect-square max-w-[50px] ssm:max-w-[100px] sm:max-w-[120px] reg:max-w-[150px] md:max-w-[318px]" />
      <LoveprintScrollView result={result} onClickedResult={onClickedResult} />
      <div className="w-full px-[24px] md:px-0 flex flex-col md:flex-none items-stretch">
        <span className="text-[#1F1125] font-bold text-[60px] flex-grow text-center md:flex-none md:text-start">{result.toUpperCase()}</span>
        <span className="text-[#1F1125] pt-[16px] text-[20px] font-bold">{loveprintSubtitle(result)}</span>
        <span className="text-[#1F1125] pt-[16px] text-[20px] font-bold">color: <span className="font-normal">{colorName(result)}</span></span>
        <span className="text-[#1F1125] pt-[16px] text-[20px] font-bold">motto: <span className="font-normal">{yourMotto(result)}</span></span>
        <span className="text-[#1F1125] pt-[16px] text-[20px] font-normal">{forYou(result)}</span>
        <div className="bg-[#1F1125] cursor-pointer rounded-full w-full md:w-[224px] h-[48px] flex flex-col justify-center items-center font-bold text-[20px] text-[#F2F2F2] mt-[36px]" onClick={() => {
          navigate('/loveprint')
        }}>take the quiz</div>
      </div>
    </div>
  )
}