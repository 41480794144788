import { gql } from "@apollo/client"

export const typeDefs = gql`
type SubscribePayload {
  user: User
}

input SubscribeInput {
  email: String!
  fromHome: Boolean
}

type AnswerQuestionPayload {
  user: User
}

input AnswerQuestionInput {
  questionId: String!
  response: String!
}

type SkipDemographicsPayload {
  user: User
}

type SetDemographicsPayload {
  user: User
}

input SetDemographicsInput {
  ageRange: String!
  sexualOrientation: String!
  genderIdentity: String!
  relationshipStatus: String!
}

type CurrentUserPayload {
  user: User
}

type User {
  token: String!
  email: String
  subscribedAt: DateTime
  createdAt: DateTime!
  updatedAt: DateTime!
  assignedEmailLoc: String
  sawEmailModalAt: DateTime
  klaviyoId: String
  subscribedFromHome: Boolean
  assessmentResponse: AssessmentResponse!
  demographics: Demographics!
}

type Assessment {
  id: String!
  isActive: Boolean!
  name: String!
  orderedQuestions: [Question!]!
}

type Question {
  id: String!
  hasReverseCoding: Boolean!
  index: Int!
  title: String!
  assessment: Assessment!
  subscale: Subscale!
  response: QuestionResponse
}

type QuestionResponse {
  id: String!
  question: Question!
  response: Response!
  user: User!
}

enum Response {
  STRONGLY_DISAGREE
  SOMEWHAT_DISAGREE
  DISAGREE
  SOMEWHAT_AGREE
  AGREE
  STRONGLY_AGREE
}

enum ScoringFunction {
  CUT
  NUMERIC
}

type Subscale {
  id: String!
  name: String!
  aboveCutId: String
  belowCutId: String
  aboveCutTitle: String!
  belowCutTitle: String!
  cutScore: Int
  index: Int!
  scoringFunction: ScoringFunction!
}

type SeeEmailModalPayload {
  user: User
}

scalar DateTime

type AssessmentResponse {
  id: String!
  assessment: Assessment!
  isComplete: Boolean!
  resultQualitative: String
  resultQuantitative: Int
  user: User!
  subscale1Progress: Float
  subscale2Progress: Float
  subscale3Progress: Float
  subscale4Progress: Float
}

type Demographics {
  id: String!
  ageRange: String!
  sexualOrientation: String!
  genderIdentity: String!
  relationshipStatus: String!
}

type Mutation {
  subscribe(input: SubscribeInput!): SubscribePayload
  answerQuestion(input: AnswerQuestionInput!): AnswerQuestionPayload
  seeEmailModal: SeeEmailModalPayload
  setDemographics(input: SetDemographicsInput!): SetDemographicsPayload
}

type Query {
  currentUser: CurrentUserPayload
}
`