import React from "react";
import Heart from "./../../../assets/images/small-heart-icon.svg"

export default function Radio({ isSelected }: { isSelected: boolean }) {
  return (
    <div className={"flex flex-row justify-center items-center rounded-full w-[20px] h-[20px] border-[#F23900] border-[2px] " + (isSelected ? "bg-[#F23900]" : " ")}>
      <img src={Heart} />
    </div>
  );
}
